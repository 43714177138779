import { createStyles } from '@material-ui/core';
import { grid, vw } from '../../../../utils/mixins';

export default theme =>
  createStyles({
    fedexSearchForm:{
      width: grid(5),
        [theme.breakpoints.down('sm')]: {
          width: '100%'
      },
    },
    button2: {
      width: grid(4),
      // width: "unset",
      padding: '1.2rem',
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      }
    },

    textField:{
      width: grid(2),
      [theme.breakpoints.down("sm")]: {
        width: '45%'
      }
    },
    select: {
      width: grid(2),

      marginTop: '1.5rem!important',
      marginLeft: "2rem",
      [theme.breakpoints.down("sm")]: {
        width: '45%'
      }
    },

    disclaimer: {
      width: grid(5),
      color: theme.colors.secondary,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
    },


  });
