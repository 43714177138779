import { createStyles } from '@material-ui/core';
import { grid, vw } from '../../../utils/mixins';

export default theme =>
  createStyles({
    inactive: {
      display: 'none'
    },
    dBlock: {
      display: 'block',
      color: theme.colors.tertiary
    },
    bodyText: {
      '& p': {
        maxWidth: '20em',
        marginBottom: '2.1875em'
      },
      '& $flushBottom': {
        marginBottom: '0.5rem'
      }
    },
    container:{
      padding: `0 ${grid(1, true)} ${vw(80)}`,
      backgroundColor: theme.colors.background.tertiary,
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        padding: `${vw(80)} ${grid(1, true)}`
      },
      '&.is-active': {},
      '&.is-disabled': {
        display: 'none'
      },
      '& + $container': {
        marginTop: '2rem',
        paddingTop: vw(80),
        [theme.breakpoints.down('sm')]: {
          marginTop: '.8rem',
          paddingTop: '5rem',
        }
      },
    },
    buttonContainer: {
      marginTop: vw(30),
      marginBottom: vw(100),
      [theme.breakpoints.down('sm')]: {
        margin: `${vw(30)} auto ${vw(100)} auto`,
        width: '80%',
    },

      '& button': {
        marginBottom: vw(50)
      },

      '&$flushBottom button': {
        marginBottom: 0
      },

      '& p': {
        marginBottom: '2em'
      },

      '& p.copy--large': {
        marginBottom: '0.5em'
      },

      '& p.copy--small': {
        color: '#8A8A8A'
      }
    },
    buttonContainerBadge: {
      display: 'block',
      margin: '2.5rem auto auto',
      maxWidth: '14.4rem'
    },
    section: {
      marginBottom: vw(70),

      '&:last-child': {
        marginBottom: 0
      },

      '& .MuiInput-input': {
        height: 'initial'
      }
    },
    noMargin: {
      marginBottom: 0
    },
    headline: {
      padding: `${vw(100)} 0 ${vw(20)}`,
      margin: `${vw(40)} 0`,
      [theme.breakpoints.down('sm')]: {
        padding: `0 0 ${vw(20)}`,
      },
    },
    button2: {
      width: grid(3),
      // width: "unset",
      padding: '1.2rem',
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        width: grid(3)
      }
    },
    subHeadline: {
      marginBottom: vw(25),
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
        paddingTop: '2rem',
      },

      '& a': {
        fontSize: '0.8888888889em',
        fontFamily: theme.typography.roboto.regular,
        textDecoration: 'underline'
      }
    },
    selectDelivery: {
      width: grid(4),
      marginBottom: vw(40),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: '5rem',
      },
    },
    select: {
      marginTop: '2.5rem!important'
    },
    shippingOption: {
      '&.is-active': {},
      '&.is-disabled': {
        display: 'none'
      },
      '& p:first-child': {
        fontWeight: 500,
        marginBottom: '1rem'
      }
    },
    shippingField: {
      width: '100%',
      marginRight: '2rem',
      marginTop: '2rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '& + $shippingField': {
        marginRight: 0
      },
      '& .MuiInput-input': {
        height: '1.8em'
      },
      '& + p': {
        marginTop: '-2rem',
        color: theme.colors.tertiary
      }
    },
    extra: {
      margin: '2rem 0'
    },
    extra2: {
      margin: '4rem 0'
    },
    pickupAddress: {
      width: grid(3, true),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    pickupPhone: {
      width: grid(2)
    },
    formControl: {
      marginBottom: vw(40)
    },
    formControlFullWidth: {
      width: '100%'
    },
    disclaimer: {
      maxWidth: '25em',
      color: theme.colors.secondary,

      '& button': {
        color: theme.colors.primary,
        textDecoration: 'underline',
        fontWeight: 500
      }
    },
    instructions: {
      width: '100%',

      '& .MuiInput-root': {
        padding: 0
      },
      '& .MuiInput-input': {
        height: 'initial'
      },

      '& textarea': {
        border: '0.1rem solid #9E9E9E',
        padding: '1.5rem'
      }
    },
    giftOptions: {
      display: 'none',
      paddingTop: vw(40),
      marginBottom: 0,

      '&.is-active': {
        display: 'block'
      }
    },
    giftInfo: {
      width: '100%'
    },
    email: {},
    flushBottom: {
      marginBottom: 0
    },
    ccIcons: {
      display: 'block',
      width: '14.1rem'
    },
    birthContainer: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      },
    },
    ageMonth: {
      width: '15rem',
      paddingRight: '2rem',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        width: '100%',
        marginBottom: '2.5rem',
        marginTop: '1rem',
      },
    },
    ageDate: {
      width: '15rem',
      paddingRight: '2rem',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        width: '100%',
        marginBottom: '2.5rem'
      },
    },
    ageYear: {
      marginTop: 0,
      width: '15rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: '2.5rem'
      },
    },
    payMonth: {
      width: grid(1, true),
      paddingRight: '2rem'
    },
    payYear: {
      width: grid(2, true),
      paddingRight: '2rem'
    },
    payCode: {
      width: grid(2),

      '& .MuiInputBase-input': {
        height: '1.8em'
      }
    },
    eyebrow: {
      marginBottom: '1.5rem'
    },
    paymentMethod: {
      display: 'flex',
      alignItems: 'center',

      '& img': {
        maxWidth: '5rem',
        marginRight: '1.8rem',
        display: 'block'
      }
    },
    textArea: {
      border: '0.1rem solid #9e9e9e',
      width: '100%',
      padding: '2rem 1.5rem',
      marginTop: '0.5rem'
    },
    textField: {
      width: 'calc(50% - 1rem)',
      marginBottom: vw(40),

      '& + $textField': {
        marginLeft: '2rem',
        [theme.breakpoints.down('sm')]: {
          marginLeft: 0,
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },
    savedAddresses: {
      marginBottom: vw(30),

      '& $subHeadline': {
        paddingBottom: '2rem'
      },

      '& $address': {
        marginTop: 0,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          marginRight: 0,
        },
      },

      '& button': {
        marginTop: '1rem',
        textDecoration: 'underline',
        [theme.breakpoints.down('sm')]: {
          margin: '3rem 0',
        }
      }
    },
    addressesContainer: {
      // overflow: 'hidden',
      paddingLeft: vw(1),
      position: 'relative',
    },
    showMore: {
      // position: 'absolute',
      // bottom: 0,
      // left: 0,
      // width: '100%',
      // height: vw(100),
      // backgroundImage: `linear-gradient(rgba(237, 234, 230, 0), ${theme.colors.background.tertiary}, ${theme.colors.background.tertiary})`,
      // maxHeight: 'initial',
      marginRight: vw(20),
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0 !important',
        marginRight: 0,
      },
    },
    center: {
      paddingBottom: vw(20),
      cursor: 'pointer',
      textDecoration: 'underline',
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    contracted: {
      // maxHeight: vw(300),
      // [theme.breakpoints.down('sm')]: {
      //   maxHeight: '22rem',
      // },
    },
    savedCards: {
      '& .MuiFormControlLabel-root': {
        display: 'flex',
        width: '100%'
      },

      '& button': {
        marginTop: '1rem',
        textDecoration: 'underline',
      }
    },
    savedCardLabel: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',

      '& span': {
        display: 'block',
        width: '100%'
      },

      '& img': {
        maxWidth: '4.7rem',
        marginRight: '1.5rem'
      }
    },
    radioMiddle: {
      '& .MuiFormControlLabel-root': {
        alignItems: 'center',
      }
    },
    checkbox: {
      marginTop: vw(20)
    },
    address: {
      marginTop: vw(40)
    },
    clear: {
      width: '100%'
    },
    button: {
      backgroundColor: 'transparent',
      outline: 'none',
    },
    mobileSpacer: {
      display: 'none',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        margin: '3rem 0',
        height: '20px',
      },
      '&:after': {
        backgroundColor: 'white',
        content: '""',
        position: 'absolute',
        height: '10px',
        width: '100vw',
        left: '-20%',
      }
    },
    mobileMargin: {
      [theme.breakpoints.down('sm')]: {
        margin: '2rem 0',
      },
    },
    black: {
      color: theme.colors.black
    },
    rowContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: vw(30),
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start'
      }
    },
    colContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    single: {
      width: '100%',
      marginTop: vw(15),
      marginBottom: vw(30)
    },
    double: {
      width: '49%',
      marginTop: vw(15)
    },
    triple: {
      width: '30%',
      marginTop: vw(15)
    },
    quadruple: {
      width: '15%',
      marginTop: vw(15)
    },
    textAlert: {
      color: `${theme.colors.alert} !important`,
    },
    bulkOrderTableResponsive: {
      '& .h6': {
        display: 'none',
      },
    },
    bulkOrderTableTotal: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    bulkOrderTableTotalAmount: {
      fontSize: '2rem',
      color: 'rgba(0, 0, 0, 0.87)',
      textAlign: 'right',
      fontWeight: 'bold',
    },
  });
