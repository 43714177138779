import React from "react";
import ccIconAE from "../assets/images/cc-icons-ae.png";
import ccIconMC from "../assets/images/cc-icons-mc.png";
import ccIconVA from "../assets/images/cc-icons-va.png";

export const getCardName = (classes, card) => {
  const {
    CardTypeId,
    ExpirationMonth,
    ExpirationYear,
    LastFour
  } = card || {};

  switch (CardTypeId) {
    case 1:
      return (
        <div className={classes.savedCardLabel}>
          <img src={ccIconMC} alt="Master Card" />
          <div>
            <span>Master Card</span>
            <span>XXXX {LastFour}, {ExpirationMonth}/{ExpirationYear}</span>
          </div>
        </div>
      );
    case 2:
      return (
        <div className={classes.savedCardLabel}>
          <img src={ccIconVA} alt="Master Card" />
          <div>
            <span>Visa </span>
            <span>XXXX {LastFour}, {ExpirationMonth}/{ExpirationYear}</span>
          </div>
        </div>
      );
    case 3:
      return (
        <div className={classes.savedCardLabel}>
          <img src={ccIconAE} alt="American Express" />
          <div>
            <span>American Express </span>
            <span>XXXX {LastFour}, {ExpirationMonth}/{ExpirationYear}</span>
          </div>
        </div>
      );
    case 8:
      return (
        <div className={classes.savedCardLabel}>
          <div>
            <span>Discover Card </span>
            <span>XXXX {LastFour}, {ExpirationMonth}/{ExpirationYear}</span>
          </div>
        </div>
      );
    default:
      return (
        <div className={classes.savedCardLabel}>
          <div>
            <span>Unknown Card </span>
            <span>XXXX {LastFour}, {ExpirationMonth}/{ExpirationYear}</span>
          </div>
        </div>
      );
  }
};

export const detectCardName = (classes, paymentFormValues) => {

  const{
    cardNumber,
    expirationMonth:ExpirationMonth,
    expirationYear:ExpirationYear,
  } = paymentFormValues || {}

  const number = ( cardNumber || '').trim()
  const lastFour = number.substr(number.length - 4);

  var re = {
    mastercard: /^5[1-5][0-9]{14}$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    amex: /^3[47][0-9]{13}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/
  };
  if (re.mastercard.test(number)) {
    return (
      <div className={classes.savedCardLabel}>
        <img src={ccIconMC} alt="Master Card" />
        <div>
          <span>Master Card</span>
          <span>XXXX {lastFour}, {ExpirationMonth}/{ExpirationYear}</span>
        </div>
      </div>
    );
  } else if (re.visa.test(number)) {
    return (
      <div className={classes.savedCardLabel}>
        <img src={ccIconVA} alt="Master Card" />
        <div>
          <span>Visa </span>
          <span>XXXX {lastFour}, {ExpirationMonth}/{ExpirationYear}</span>
        </div>
      </div>
    );
  } else if (re.amex.test(number)) {
    return (
      <div className={classes.savedCardLabel}>
        <img src={ccIconAE} alt="American Express" />
        <div>
          <span>American Express </span>
          <span>XXXX {lastFour}, {ExpirationMonth}/{ExpirationYear}</span>
        </div>
      </div>
    );
  } else if (re.discover.test(number)) {
    return (
      <div className={classes.savedCardLabel}>
        <div>
          <span>Discover Card </span>
          <span>XXXX {lastFour}, {ExpirationMonth}/{ExpirationYear}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.savedCardLabel}>
        <div>
          <span>Unknown Card </span>
          <span>XXXX {lastFour}, {ExpirationMonth}/{ExpirationYear}</span>
        </div>
      </div>
    );
  }
};