import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/styles';

import Select from '../../../bits/Shared/Select';
import CheckedIcon from '../../../bits/Shared/Icons/Checked';
import UncheckedIcon from '../../../bits/Shared/Icons/Unchecked';
import { joinClasses } from '../../../../utils';
import { Field, FieldError, validate } from '../../../../utils/forms';
import styles from './styles';

const normalizePhone = require('../../../../utils/normalizePhone').handler;

const validate30 = validate.characters(30)
const validate50 = validate.characters(50)
const validate75 = validate.characters(75)
const validate100 = validate.characters(100)
const validate255 = validate.characters(255)

const AddressForm = ({
  classes,
  heading,
  saveAsDefaultAllowed = false,
  className,
  countryField = false,
  stateOptions = [],
  countryOptions = [],
  selectedCountry,
  company = null,
  address2 = null,
  isBilling = null,
  validState,
  clear,
  onMakeDefaultAddress,
  isMarkedSaveDefault,
  changeState,
  states,
  city,
  zip,
  stateId
}) => {

  const {
    countryName,
    countryId
  } = selectedCountry || {};

  const [showCompany, setShowCompany] = React.useState(!!company);
  const [showAddressLine2, setShowAddressLine2] = React.useState(!!address2);

  return (
    <div className={joinClasses('address-form', className)}>
      {heading && (
        <p className={joinClasses('h6', classes.heading)}>{heading}</p>
      )}
      <Field
        name="name_first"
        validate={[validate.required, validate50]}
      >
        <TextField
          required
          label="First name"
          className={joinClasses(classes.textField, classes.firstName)}
          sdwinput
        />
        {/* <FieldError sdwerror /> */}
      </Field>
      <div
        className={joinClasses(classes.lastNameContainer, classes.textField)}
      >
        <Field name="name_last" validate={[validate.required, validate50]}>
          <TextField
            required
            label="Last name"
            className={classes.lastName}
            sdwinput
          />
          {/* <FieldError sdwerror /> */}
        </Field>
        <FormControlLabel
          className={joinClasses((showCompany?classes.toggleActive:classes.toggle), classes.hideMobile)}
          control={<Checkbox color="secondary" icon={<CheckedIcon />} checkedIcon={<UncheckedIcon />} checked={showCompany} />}
          label="Company Name"
          onChange={event => setShowCompany(event.target.checked)}
        />
      </div>

      <Field name="company" validate={[validate100]}>
        <TextField
          label="Company name"
          className={joinClasses(
            classes.textField,
            classes.companyName,
            showCompany ? classes.isActive : ''
          )}
          sdwinput
        />
        {/* <FieldError sdwerror /> */}
      </Field>
      <div className={joinClasses(classes.addressContainer, classes.textField)}>
        <Field name="address1" validate={[validate.required, validate255]}>
          <TextField
            label="Address Line 1"
            className={classes.addressLine1}
            required
            sdwinput
          />
        </Field>
        <FormControlLabel
          className={joinClasses((showAddressLine2?classes.toggleActive:classes.toggle), classes.hideMobile)}
          control={<Checkbox color="secondary" icon={<CheckedIcon />} checkedIcon={<UncheckedIcon />} checked={showAddressLine2} />}
          label="Address Line 2"
          onChange={event => setShowAddressLine2(event.target.checked)}
        />
      </div>
      <Field name="address2" validate={[validate75]}>
        <TextField
          label="Address Line 2"
          className={joinClasses(
            classes.textField,
            classes.addressLine2,
            showAddressLine2 ? classes.isActive : ''
          )}
          sdwinput
        />
        <FieldError sdwerror />
      </Field>
      <Field name="city" validate={[validate.required, validate75]}>
        <TextField
          label="City"
          className={joinClasses(classes.textField, classes.city)}
          required
          sdwinput
        />
      </Field>
      <FormControl className={joinClasses(classes.textField, classes.state)}>
        <Field key={countryId || 0} name="stateId" validate={validState ? [validState, validate.required ] : [validate.required]} errorOverride={{status: (!!zip && !!city && !stateId), message:'Required'}}>
          <Select
            required
            sdwinput
            autocomplete
            autoposition
            options={(stateOptions || []).length > 0 ? stateOptions : [{value:-1, label:countryName}]}
            label="State"
          />
          <FieldError sdwerror />
        </Field>
      </FormControl>
      <Field name="zip" validate={[validate.required, validate30]}>
        <TextField
          label="ZIP Code"
          className={joinClasses(classes.textField, classes.zip)}
          required
          sdwinput
        />
      </Field>
      <Field name="phone" validate={[validate.requiredPhoneNum, validate50]} normalizeOnBlur={normalizePhone(countryId)} > 
        <TextField
          label="Phone Number"
          className={joinClasses(classes.textField, classes.zip, classes.phoneNumber)}
          required
          sdwinput
        />
        <FieldError sdwerror />
      </Field>
      {countryField && (
        <FormControl className={joinClasses(classes.textField, classes.state)}>
          <Field name="countryId" validate={[validate.required]} 
            onChange={(e)=>{
              const{
                target
              } = e || {};
              const{
                value
              } = target || {};
              const nextStateOptions = (states || [])
              .filter(({ countryId:itemCountryId }) => itemCountryId === value)
              .map(({ stateId, stateName }) => ({
                value: stateId,
                label: stateName
              }));
              /*Checks if the new country has any state options, if only 1 or no options then preset value to only option or -1  - AWS 10/18/21*/ 
              changeState((nextStateOptions || []).length > 1 ? null : ((nextStateOptions || []).length === 1 ? (nextStateOptions || [])[0].value : -1))}
            }
          >
            <InputLabel required sdwlabel>Country</InputLabel>
            <Select sdwinput autoposition>
              {(countryOptions || []).map(({ value, label }, i) => (
                <MenuItem key={i} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            <FieldError sdwerror />
          </Field>
        </FormControl>
      ) }
      {saveAsDefaultAllowed && (
        <div className={classes.textField}>
          <FormControlLabel
            control={(
              <Field name={isBilling ? 'makeDefaultBilling' : 'makeDefaultShipping'} checkbox>
                <Checkbox
                  color="secondary"
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                />
              </Field>
            )}
            label={isBilling ? 'Make default billing address' : 'Make Default Shipping Address'}
            onChange={onMakeDefaultAddress ? event => onMakeDefaultAddress(event.target.checked) : ''}
          />
          {onMakeDefaultAddress && 
            <FormControlLabel
              control={(
                <Field name={'saveAddress'} checkbox >
                  <Checkbox
                    color="secondary"
                    icon={<UncheckedIcon />}
                    checkedIcon={<CheckedIcon />}
                    disabled={isMarkedSaveDefault}
                  />
                </Field>
              )}
              label={'Save Address'}
              disabled={isMarkedSaveDefault}
            />
          }
        </div>
      )}
      <div className={classes.mobilePlusContainer}>
        <FormControlLabel
          className={joinClasses((showCompany?classes.toggleActive:classes.toggle), 'col-lg-hidden col-md-hidden')}
          control={<Checkbox color="secondary" icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} checked={showCompany} />}
          label="Company Name"
          onChange={event => setShowCompany(event.target.checked)}
        />
        <FormControlLabel
          className={joinClasses((showAddressLine2?classes.toggleActive:classes.toggle), 'col-lg-hidden col-md-hidden')}
          control={<Checkbox color="secondary" icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} checked={showAddressLine2} />}
          label="Address Line 2"
          onChange={event => setShowAddressLine2(event.target.checked)}
        />
      </div>
    </div>
  );
};

AddressForm.propTypes = {
  heading: PropTypes.string,
  saveAsDefaultAllowed: PropTypes.bool,
  countryField: PropTypes.bool,
  classes: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    textField: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastNameContainer: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    addressContainer: PropTypes.string.isRequired,
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    makeDefault: PropTypes.string.isRequired,
    toggle: PropTypes.string.isRequired,
    isActive: PropTypes.string.isRequired
  }).isRequired
};

AddressForm.defaultProps = {
  heading: '',
  saveAsDefaultAllowed: false,
  countryField: false,
};

const StyledApp = withStyles(styles)(AddressForm);

export default StyledApp;
