
import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SEO } from '../../../bits';

import Button from '../../../bits/Shared/Button';
import CheckedIcon from '../../../bits/Shared/Icons/Checked';
import UncheckedIcon from '../../../bits/Shared/Icons/Unchecked';

import ccIconAE from '../../../../assets/images/cc-icons-ae.png';
import ccIconMC from '../../../../assets/images/cc-icons-mc.png';
import ccIconVA from '../../../../assets/images/cc-icons-va.png';
import ccIconDC from '../../../../assets/images/cc-icons-dc.png';
import badgeSSL from '../../../../assets/images/badge-ssl.png';

import { joinClasses, formatPhoneNumber } from '../../../../utils';
import CheckoutLayout from '../../../../layouts/checkout'
import fetchDux from '../../../../store/reducers/fetch-dux'
import { actionCreators } from '../../../../store/reducers/billingReducer';
import { actionCreators as accountActionCreators } from '../../../../store/reducers/accountReducer';
import { Form, Field, validate } from '../../../../utils/forms'
import styles from '../styles';
import queryString from 'query-string';
import { isInvalid, hasSubmitFailed } from 'redux-form';
import MembershipTerms from '../../Modals/CheckOut/MembershipTerms';
const FORM_NAME = 'review-email-prefs'

const getCardName = cardTypeId => {
  switch (cardTypeId) {
    case 1:
      return <img src={ccIconMC} alt="Master Card" />;
    case 2:
      return <img src={ccIconVA} alt="Visa" />;
    case 3:
      return <img src={ccIconAE} alt="American Express" />;
    case 8:
      return <img src={ccIconDC} alt="Discover" />;
    default:
      return <span>Unknown -&nbsp;</span>;
  }
};

const detectCardName = number => {
  const re = {
    mastercard: /^5[1-5][0-9]{14}$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    amex: /^3[47][0-9]{13}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/
  };
  if (re.mastercard.test(number)) {
    return <img src={ccIconMC} alt="Mastercard" />;
  } if (re.visa.test(number)) {
    return <img src={ccIconVA} alt="Visa" />;
  } if (re.amex.test(number)) {
    return <img src={ccIconAE} alt="American Express" />;
  } if (re.discover.test(number)) {
    return <img src={ccIconDC} alt="Discover" />;
  }
  return <span>Unknown Card -&nbsp;</span>;

};

const ReviewAddress = (
  {
    name_first,
    name_last,
    address1,
    address2,
    city,
    stateShort,
    zip,
    zip_4,
    company
  },
  classes
) => (

  <div className={classes.bodyText}>
    <p className={classes.flushBottom}>{name_first} {name_last}</p>
    <p className="compact">
      {(company || '').length > 0 &&
      <span className={classes.dBlock}>{company} <br /></span>}
      <span className={classes.dBlock}>{address1} <br /></span>
      {address2 && <span className={classes.dBlock}>{address2} <br /></span>}
      <span className={classes.dBlock}>{city}, {stateShort} {zip}{zip_4 && `-${zip_4}`}</span>
    </p>
    <br />
  </div>
);

const validatePayment = (
  {
    cardId,
    cardNumber,
    expirationMonth,
    expirationYear,
    cvv
  }
) => {
  if (cardId && cardId !== 'new' && cardId > 0) return true;
  const invalid = !cardNumber ||
    (!expirationMonth && expirationMonth !== 0) ||
    !expirationYear ||
    !cvv;
  return !invalid;
};

const getCreditCardLine = (user, payment, classes) => {
  const {
    cardId,
    cardNumber
  } = payment || {};
  if (!cardId || cardId === 'new') {
    return (
      <div className={classes.bodyText}>
        <p className={classes.paymentMethod}>
          <span> {detectCardName(cardNumber)} </span>
          <span>**** {(cardNumber || '').substr((cardNumber || '').length - 4, 4)}</span>
        </p>
      </div>
    );
  }
  const { CreditCards } = user || {};
  const { LastFour, CardTypeId } = (CreditCards || [])
    .find(({ CardId }) => CardId === cardId) || {};
  return (
    <div className={classes.bodyText}>
      <p className={classes.paymentMethod}>
        <span> {getCardName(CardTypeId)} </span>
        <span> **** {LastFour} </span>
      </p>
    </div>
  );
};


const ShippingSummary = ({ shipping, cart, requestedShipDate, daysToShip, classes }) => {
  const {
    phone,
    isHoldAtFedex
  } = shipping || {};
  const {
    giftSenderName,
    giftName,
    giftRecipientEmail,
    giftNote,
    ageVerifyFailed,
    nonCompliantCart
  } = cart || {};
  return (
    <>
      <div>
        <p className={joinClasses('h6', classes.subHeadline)}>
          Shipping Summary <Link to="/shipping/">Edit</Link>
        </p>
        <p
          className={joinClasses(
            'eyebrow',
            'color--light-gray',
            classes.eyebrow
          )}
        >
        {isHoldAtFedex ? `Pickup at Fedex location` : `Ship Address`}  
        </p>
        <ReviewAddress
          {...shipping || {}}
          classes={classes}
        />
        <p
          className={joinClasses(
            'eyebrow',
            'color--light-gray',
            classes.eyebrow
          )}
        >
          Requested Delivery Date
        </p>
        <p>
          {moment(requestedShipDate)
            .add(daysToShip || 0, 'days')
            .format('MMMM D, YYYY')}
        </p>
        <br />
        <div>
          <p
            className={joinClasses(
              'eyebrow',
              'color--light-gray',
              classes.eyebrow
            )}
          >
            Phone
          </p>
          <p>{formatPhoneNumber(phone) || '-'}</p>
          <br />
          {ageVerifyFailed && !nonCompliantCart &&
            <p className={classes.textAlert}>
              Customer care may call to verify the recipient's age.
            </p>
          }
          
          {nonCompliantCart && !ageVerifyFailed &&
            <p className={classes.textAlert}>
              Customer care may call to verify shipment compliance.
            </p>
          }

          {nonCompliantCart && ageVerifyFailed &&
            <p className={classes.textAlert}>
              Customer care may call to verify the recipient's age and the shipment compliance.
            </p>
          }
        </div>
        {giftSenderName && (
          <div>
            <p
              className={joinClasses(
                'eyebrow',
                'color--light-gray',
                classes.eyebrow
              )}
            >
              Gift Sender's Name
            </p>
            <p>{giftSenderName}</p>
            <br />
          </div>
        )}
        {giftName && (
          <div>
            <p
              className={joinClasses(
                'eyebrow',
                'color--light-gray',
                classes.eyebrow
              )}
            >
              Gift Recipient's Name
            </p>
            <p>{giftName}</p>
            <br />
          </div>
        )}
        {giftRecipientEmail && (
          <div>
            <p
              className={joinClasses(
                'eyebrow',
                'color--light-gray',
                classes.eyebrow
              )}
            >
              Gift Recipient's Email
            </p>
            <p>{giftRecipientEmail}</p>
            <br />
          </div>
        )}
        {giftNote && (
          <div>
            <p
              className={joinClasses(
                'eyebrow',
                'color--light-gray',
                classes.eyebrow
              )}
            >
              Gift Message
            </p>
            <p>{giftNote}</p>
            <br />
          </div>
        )}
      </div>
    </>
  );
};

const PickupSummary = ({ shipping, shipper, requestedShipDate, classes }) => {
  const {
    name_first,
    name_last,
    ...rest
  } = shipping || {};
  const { ShipTrackingName } = shipper || {};
  return (
    <>
      <div>
        <p className={joinClasses('h6', classes.subHeadline)}>
          Pick Up Summary <Link to="/shipping/">Edit</Link>
        </p>
        <ReviewAddress
          {...rest || {}}
          name_first={ShipTrackingName}
          classes={classes}
        />
        <p
          className={joinClasses(
            'eyebrow',
            'color--light-gray',
            classes.eyebrow
          )}
        >
          Requested Pickup Date
        </p>
        <p>
          {moment(requestedShipDate).format('MMMM D, YYYY')}
        </p>
        <br />
        <div>
          <p
            className={joinClasses(
              'eyebrow',
              'color--light-gray',
              classes.eyebrow
            )}
          >
            Pickup Agent
          </p>
          <p>
            {name_first}
            {' '}
            {name_last}
          </p>
          <br />
        </div>
      </div>
    </>
  );
};

const { location } = window;

class Review extends Component {
  constructor(props) {
    super(props);
    props.clearGetCart();
    props.clearGetCartBilling();
    props.clearGetCartShipping();
    props.clearPostCompleteOrder();
    const { location: { search } } = this.props;
    const searchQuery = queryString.parse(search);
    const { clubJoin } = searchQuery || {};
    const isClubJoin = clubJoin === 'true';
    this.state = { isClubJoin };
  }

  componentDidMount() {
    const { isClubJoin } = this.state;
    window.scrollTo(0, 0);
    this.props.getCart({ clubJoin: isClubJoin });
    this.props.getCartShipping({ clubJoin: isClubJoin });
    this.props.getCartBilling({ clubJoin: isClubJoin });
  }
  closeModal = () => {
    this.setState({ modalActive: false });
  };

  render() {

    const {
      classes,
      inProgress,
      postCompleteOrder,
      submitting,
      clearBilling,
      clearAccount,
      shipping,
      billing,
      cart,
      user,
      redirect,
      order,
      payment,
      formData,
      paymentError,
      isFormInvalid,
      hasSubmitFailedValue
    } = this.props;

    const { modalActive } = this.state;

    const scrollToTop = () => window.scrollTo(0, 0);

    const { isClubJoin } = this.state;

    if (redirect) return <Redirect to={`/shipping?clubJoin=${isClubJoin}`} />;

    const hasAccount = user && user.userId;

    const { email } = user || {};
    const { email: shippingEmail } = shipping || {};

    const {
      shipper,
      requestedShipDate,
      shipViaId
    } = cart || {};

    const daysToShip = shipViaId === 9 ? 1 : 2;

    const {
      IsWillCall
    } = shipper || {};

    const {
      orderComplete,
      purchaseOrderId,
      resetCart
    } = order || {};

    if (orderComplete) {
      clearBilling();
      clearAccount();
      return <Redirect to={`/confirmation?clubJoin=${isClubJoin}`} />;
    }

    if (resetCart) {
      location.reload();
    }

    const invalidShipDate = moment(requestedShipDate).isBefore(moment());

    const validPayment = validatePayment(payment || {});

    const paymentFailed = (!orderComplete && !!purchaseOrderId) || paymentError;

    if (!validPayment) return <Redirect to={`/billing?clubJoin=${isClubJoin}`} />;

    const handleCompleteOrder = (values) => {
      scrollToTop();
      postCompleteOrder(
        {
          ...payment,
          ...values,
          clubJoin: isClubJoin,
          formData: !hasAccount && isClubJoin ? { ...formData, saveInfo: true } : formData
        });
    }

    const initialValues = {
      IsDiscoveringSilveroak: true,
      IsWineEducation: false,
      IsDiscoveringTwomey: true,
      IsDiscoveringTimeless: false
    }

    return (
      <>
        <SEO
          title='Review Order'
          meta={null}
        />
        <div>
          <Form form={FORM_NAME} initialValues={initialValues} onSubmit={handleCompleteOrder} enableReinitialize>
            <CheckoutLayout id="review" inProgress={inProgress || submitting} isClubJoin={isClubJoin}>
              <div className={joinClasses('review')}>
                <div className={classes.container}>
                  <div className={classes.section}>
                    <h2 className={joinClasses('h4', classes.headline)}>
                      Review Your Order
                    </h2>
                    {!IsWillCall && (
                      <ShippingSummary
                        daysToShip={daysToShip}
                        requestedShipDate={requestedShipDate}
                        shipping={shipping}
                        cart={cart}
                        classes={classes}
                      />
                    )}
                    {IsWillCall && (
                      <PickupSummary
                        daysToShip={daysToShip}
                        requestedShipDate={requestedShipDate}
                        shipping={shipping}
                        shipper={shipper}
                        classes={classes}
                      />
                    )}
                    {(cart || {}).specialInstructions && (
                      <div>
                        <p
                          className={joinClasses(
                            'eyebrow',
                            'color--light-gray',
                            classes.eyebrow
                          )}
                        >
                          Special Instructions
                        </p>
                        <p>
                          {(cart || {}).specialInstructions}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={classes.section}>
                    <p className={joinClasses('h6', classes.subHeadline)}>
                      Payment Information<Link to={`/billing?clubJoin=${isClubJoin}`}>Edit</Link>
                    </p>
                    {paymentFailed && (
                      <p
                        style={{
                          color: '#dc3545',
                          padding: '0px 0px 20px 0px',
                          'font-weight': 'bold'
                        }}
                      >
                        {
                          paymentError && paymentError.message ? paymentError.message
                            :
                            `Failed to apply payment. Please review billing information and try again`
                        }
                      </p>
                    )}
                    <p
                      className={joinClasses(
                        'eyebrow',
                        'color--light-gray',
                        classes.eyebrow
                      )}
                    >
                      Card Number
                    </p>
                    {getCreditCardLine(user, payment, classes)}
                    <p
                      className={joinClasses(
                        'eyebrow',
                        'color--light-gray',
                        classes.eyebrow
                      )}
                    >
                      Billing Address
                    </p>
                    <ReviewAddress {...billing || {}} />
                    <p
                      className={joinClasses(
                        'eyebrow',
                        'color--light-gray',
                        classes.eyebrow
                      )}
                    >
                      Email
                    </p>
                    {hasAccount && (
                      <>
                        <p>{email || '-'}</p>
                        <br />
                      </>
                    )}
                    {!hasAccount && (
                      <>
                        <p>{shippingEmail || '-'}</p>
                        <br />
                      </>
                    )}
                    <p
                      className={joinClasses(
                        'eyebrow',
                        'color--light-gray',
                        classes.eyebrow
                      )}
                    >
                      Phone Number
                    </p>
                    <div className={classes.bodyText}>
                      <p>{formatPhoneNumber((billing || {}).phone) || '-'}</p>
                    </div>
                    {isClubJoin &&
                      <div style={{ display: 'flex' }}>
                        <FormControlLabel
                          style={{ marginRight: 0 }}
                          control={(
                            <Field
                              name="terms"
                              checkbox
                              validate={[validate.required]}
                            >
                              <Checkbox
                                color="secondary"
                                icon={<UncheckedIcon />}
                                checkedIcon={<CheckedIcon />}
                                required
                              />
                            </Field>
                          )}
                          label="I agree with the "
                        />
                        <a href='#'
                          style={{
                            marginTop: '0.9rem',
                            marginLeft: '0.5rem',
                            textDecoration: 'underline'
                          }}
                          onClick={(e) => { e.preventDefault(); this.setState({ modalActive: true }) }}
                        >
                          member policy
                        </a>
                      </div>
                    }

                  </div>
                </div>
                {!hasAccount && !isClubJoin && (
                  <div className={classes.container}>
                    <p className={joinClasses('h6', classes.subHeadline)}>
                      Subscribe to Our Emails
                    </p>
                    <FormControlLabel
                      className={joinClasses(classes.formControlFullWidth)}
                      control={(
                        <Field name="IsDiscoveringSilveroak" checkbox>
                          <Checkbox
                            color="secondary"
                            icon={<UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                          />
                        </Field>
                      )}
                      label="Silver Oak – Exclusives & Announcements"
                    />
                    <FormControlLabel
                      className={joinClasses(classes.formControlFullWidth)}
                      control={(
                        <Field name="IsDiscoveringTwomey" checkbox>
                          <Checkbox
                            color="secondary"
                            icon={<UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                          />
                        </Field>
                      )}
                      label="Twomey – All Things Pinot"
                    />
                    <FormControlLabel
                      className={joinClasses(classes.formControlFullWidth)}
                      control={(
                        <Field name="IsWineEducation" checkbox>
                          <Checkbox
                            color="secondary"
                            icon={<UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                          />
                        </Field>
                      )}
                      label="Cabernet Life – Wine Education & Recipes"
                    />
                    <FormControlLabel
                      className={joinClasses(classes.formControlFullWidth)}
                      control={(
                        <Field name="IsDiscoveringTimeless" checkbox>
                          <Checkbox
                            color="secondary"
                            icon={<UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                          />
                        </Field>
                      )}
                      label="Timeless – From the Family of Silver Oak"
                    />
                  </div>
                )}
                {paymentFailed && (
                  <section className="form-bit">
                    <div className="form-row justify-content-end">
                      <div className="col-md-12">
                        <p
                          style={{
                            color: '#dc3545',
                            padding: '10px 0',
                            textAlign: 'center'
                          }}
                        >
                          {
                            paymentError && paymentError.message ? paymentError.message
                              :
                              `Failed to apply payment. Please review billing information and try again`
                          }

                        </p>
                      </div>
                    </div>
                  </section>
                )}
                {!invalidShipDate && (
                  <div
                    className={joinClasses(classes.buttonContainer, classes.flushBottom)}
                  >
                    {isFormInvalid && hasSubmitFailedValue && (
                      <p style={{ color: '#dc3545', marginTop: '1em', marginBottom: 0 }}>
                        Please agree to membership policy.
                      </p>
                    )}


                    <Button
                      type="submit"
                      id="review-submit" // DO NOT DELETE - GTM tracking
                      disabled={submitting}
                      title={isClubJoin ? 'Confirm Membership' : 'Place Order'}
                    />
                    <img
                      src={badgeSSL}
                      alt="Trustwave Secure Transactions"
                      className={classes.buttonContainerBadge}
                    />
                  </div>
                )}
                {invalidShipDate && (
                  <p style={{ color: 'red' }}>
                    <br />
                    Sorry, your requested {!IsWillCall ? 'delivery' : 'pickup'} date is not valid.
                    Please <Link style={{ color: 'red' }} to="/shipping/"><u>update the {!IsWillCall ? 'delivery' : 'pickup'} date</u></Link> before completing your order.
                  </p>
                )}

              </div>
              <MembershipTerms isOpen={modalActive} onClose={this.closeModal} />
            </CheckoutLayout>
          </Form>
        </div>
      </>
    );
  }
};

const isInvalidSelector = isInvalid(FORM_NAME);
const hasSubmitFailedSelector = hasSubmitFailed(FORM_NAME);

const mapState = state => {

  const {
    error: getCartError,
    inProgress: cartInProgress,
    result: cart
  } = state.fetchReducer.getCart.toJS();

  const {
    error: getCartShippingError,
    inProgress: shippingInProgress,
    result: shipping
  } = state.fetchReducer.getCartShipping.toJS();

  const {
    error: getCartBillingError,
    inProgress: billingInProgress,
    result: billing
  } = state.fetchReducer.getCartBilling.toJS();

  const {
    result: user,
    inProgress: userInProgress
  } = state.fetchReducer.postLogin.toJS();

  const {
    inProgress: submitting,
    result: order,
    error: paymentError
  } = state.fetchReducer.postCompleteOrder.toJS();

  const payment = state.billing || {};

  const formData = state.account || {};

  const inProgress = cartInProgress || shippingInProgress || billingInProgress || userInProgress;
  const redirect = getCartError || getCartShippingError || getCartBillingError;
  const isFormInvalid = isInvalidSelector(state);
  const hasSubmitFailedValue = hasSubmitFailedSelector(state);

  return {
    inProgress,
    submitting,
    user,
    cart,
    shipping,
    billing,
    formData,
    payment,
    redirect,
    order,
    paymentError,
    isFormInvalid,
    hasSubmitFailedValue
  }
};

const mapDispatch = dispatch => bindActionCreators({
  ...actionCreators,
  ...accountActionCreators,
  getCart: fetchDux.getCart.createAction,
  getCartShipping: fetchDux.getCartShipping.createAction,
  getCartBilling: fetchDux.getCartBilling.createAction,
  postCompleteOrder: fetchDux.postCompleteOrder.createAction,
  clearGetCart: fetchDux.getCart.clearAction,
  clearGetCartBilling: fetchDux.getCartBilling.clearAction,
  clearGetCartShipping: fetchDux.getCartShipping.clearAction,
  clearPostCompleteOrder: fetchDux.postCompleteOrder.clearAction
}, dispatch)

const StyledReview = withStyles(styles)(Review);

export default connect(mapState, mapDispatch)(StyledReview);