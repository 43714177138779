import React from 'react';
import { withStyles } from '@material-ui/styles';
// import SilverOakLogo from '../../../../assets/vectors/SilverOak_Logo_Inverse.svg';
// import TwomeyLogo from '../../../../assets/vectors/twomey_white.svg';
import SilverOakLogoWhite from '../../../../assets/images/so-logo-white.png';
import TwomeyLogoWhite from '../../../../assets/images/twomey-logo-white.png';
import { joinClasses } from '../../../../utils';
import styles from './styles';
import SilveroakLink from '../../../../utils/SilveroakLink';
import TwomeyLink from '../../../../utils/TwomeyLink';

const checkoutFooter = ({ classes, style }) => {
  const footerText =
    'Drinking distilled spirits, beer, coolers, wine and other alcoholic beverages may increase cancer risk, and, during pregnancy, can cause birth defects. For more information go to  www.P65Warnings.ca.gov/alcohol.';
  const footerText2 =
    'Many food and beverage cans have linings containing bisphenol A (BPA), a chemical known to the State of California to cause harm to the female reproductive system. Jar lids and bottle caps may also contain BPA. You can be exposed to BPA when you consume foods or beverages packaged in these containers. For more information go to  www.P65Warnings.ca.gov. For a list of products go to:  www.prop65bpa.org.';
  return (
    <div className={joinClasses(classes.root)} style={style || {}}>
      <div className={classes.firstRow}>
        <div className="container container--wide">
          <span>Warning</span>
          <br />
          <br />
          <p>{footerText}</p>
          <br />
          <br />
          <p>{footerText2}</p>
        </div>
      </div>
      <div className={classes.secondRow}>
        <div className={joinClasses('container container--wide', classes.secondRowWrapper)}>
          <SilveroakLink>
            <img className={joinClasses(classes.silveroakImg, classes.hideMobile)} src={SilverOakLogoWhite} alt="Silver Oak Logo" />
          </SilveroakLink>
          <div className={joinClasses(classes.logoDivider, classes.hideMobile)} />
          <TwomeyLink>
            <img className={joinClasses(classes.twImg, classes.hideMobile)} src={TwomeyLogoWhite} alt="Twomey Logo" />
          </TwomeyLink>
          <div style={{ flex: '1' }}></div>
            <p className="small">
              Copyright © {new Date().getFullYear()} Silver Oak Cellars. All Rights Reserved
            </p>
          </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(checkoutFooter);
