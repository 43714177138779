import * as React from 'react';
import Map, { Marker, Popup } from 'react-map-gl';
import { useEffect } from 'react';
const token = `${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;

const LocationMap = ({
  findFedexLocationResult,
  onClickMarker,
  classes,
  longLat,
  selectedMarker,
  clickMarkerShowPopup,
  clickStoreShowPopup,
  setClickMarkerShowPopup,
  selectedLocationId,
  setSelectedLocationId,
  setSelectedMarker
}) => {
  const icon = "M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z";
  const mapRef = React.useRef();
  //when click on a store in the side container, center of map will be set to the store's longLat
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: longLat,
        duration: 1000,
        zoom: 12,
      })
    }
  }, [longLat]);

  const applyToArray = (func, array) => func.apply(Math, array);

  const pointsLong = [];
  const pointsLat = [];
  (findFedexLocationResult || []).map((location) => {
    const { geoPositionalCoordinates } = location || {};
    const { longitude, latitude } = geoPositionalCoordinates || {};
    pointsLong.push(longitude);
    pointsLat.push(latitude);
  })

  const getCenter = (pointsLong, pointsLat) => {
    const centerLong = (applyToArray(Math.max, pointsLong) + applyToArray(Math.min, pointsLong)) / 2;
    const centerLat = (applyToArray(Math.max, pointsLat) + applyToArray(Math.min, pointsLat)) / 2;
    return { centerLong, centerLat };
  }
  const { centerLong, centerLat } = getCenter(pointsLong, pointsLat);

  const onMapLoad = React.useCallback(() => {
    mapRef.current.flyTo({ center: [centerLong, centerLat], duration: 1000 });
  }, []);


  return <Map
    ref={mapRef}
    onLoad={onMapLoad}
    initialViewState={{
      zoom: 10,
    }}
    mapStyle="mapbox://styles/mapbox/streets-v12"
    mapboxAccessToken={token}
  >

    {(findFedexLocationResult || []).map((location, index) => {
      const {
        contactAndAddress,
        locationId,
        geoPositionalCoordinates
      } = location || {};
      const { address, contact } = contactAndAddress || {};
      const { companyName } = contact || {};
      const { city, streetLines, postalCode } = address || {};
      const { longitude, latitude } = geoPositionalCoordinates || {};

      return (
        <div>
          <Marker
            longitude={longitude}
            latitude={latitude}
            anchor="top"
            style={{
              zIndex: selectedMarker === locationId ? 1 : "unset"
            }}
            key={locationId}
            offsetLeft={-10}
            offsetTop={-10}
            onClick={() => {
              setSelectedMarker(locationId);
              onClickMarker(locationId);
              mapRef.current.flyTo({
                center: [longitude, latitude],
                duration: 1000,
                zoom: 12
              })
            }}
          >
            {selectedMarker === locationId ?
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="10" r="6.2" /><path d={icon} />
              </svg>
              :
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#978A5E" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="10" r="6.2" /><path d={icon} />
              </svg>
            }
          </Marker>

          {/* click a marker or click a location in the side container will both open the popup, but we cannot have 2 popups at the same time */}
          {/* when click a marker, the popup opend by clicking a location must be closed, when click a location, we also need to close the popup opened by a marker first.  */}
          {/* clickMarkerShowPopup and clickStoreShowPopup, only one of them can be true at the same time*/}
          {((clickMarkerShowPopup && selectedLocationId === locationId) || (clickStoreShowPopup && selectedMarker === locationId)) && (
            <Popup
              key={index}
              latitude={latitude}
              longitude={longitude}
              anchor="bottom"
              onClose={() => {
                setClickMarkerShowPopup(false)
                setSelectedLocationId(null)
              }}
              closeButton={false}
            >
              <div className={classes.popup}>
                <p className={classes.companyName}>
                  {companyName}
                </p>
                <div className={classes.companyAddress}>
                  {streetLines}
                </div>
                <div className={classes.companyAddress}>
                  {city} {postalCode}
                </div>
              </div>
            </Popup>
          )}
        </div>
      )
    })
    }
  </Map>
}

export default LocationMap;
