import { createStyles } from '@material-ui/core';

import { grid, vw } from '../../../../utils/mixins';

export default (theme) => createStyles({
	container: {
		position: 'relative',
		padding: `${vw(80)} ${vw(100)}`,
		backgroundColor: theme.colors.background.zero,
		width: grid(8, false),
		margin: 'auto',
		[theme.breakpoints.down("sm")]: {
			margin: "0 10px"
		},
	},
	heading: {
		marginBottom: vw(20)
	},
	close: {
		display: 'block',
		position: 'absolute',
		top: vw(80),
		right: vw(100),
		width: '3.8rem',
		height: '3.8rem',
		marginTop: '-0.6rem',
	},
	row: {
		width: '100%',
		margin: `${vw(20)} 0`,
		'& :lastChild': {
			border: '3px solid red'
		}
	},
	button: {
		marginTop: vw(40),
	}
});