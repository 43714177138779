import React, { cloneElement } from 'react';

const SO_COOKIES = [
  // 'campaign',
  'cart',
  'auth'
];

const twomeyBase = `${process.env.REACT_APP_TWOMEY_LINK}`; // would like to pass this through proxy to fix CORS errors `SIL-382`

const getQueryString = () => {
  return (document.cookie || '')
    .split(';')
    .map(a => {
      const [name, value] = (a || '').split('=');
      return {
        name: (name || '').trim(),
        value: (value || '').trim()
      };
    })
    .filter(({ name }) => SO_COOKIES.some(s => s === name))
    .map(({ name, value }) => `${name}=${value}`)
    .join('&');
};

const formatHref = h => {
  const href = h || '';
  if (href[0] === '/') return href.substring(1);
  return href;
}

const getOnClick = hr => e => {
  e.preventDefault();
  const qs = getQueryString();

  const { target } = e || {};
  const { href: h } = target || {};
  const href = formatHref(hr || h)
  if (href) return window.location = `${twomeyBase}/${href}?${qs}`;
  window.location = `${twomeyBase}?${qs}`;
};

const TwomeyLink = (
  {
    href,
    render,
    label,
    children,
    ...rest
  }
) => {
  const onClick = getOnClick(href);
  if (render) return render({ onClick });
  if (children) {
    const props = href ? { onClick, href } : { onClick };
    return cloneElement(children, props)
  }

  return (
    <a href={href} onClick={onClick} {...rest}>
      {label}
    </a>
  );
};

export default TwomeyLink;

export const handleCheckoutNav = href => {
  const qs = getQueryString();
  window.location = `${href}?${qs}`;
};
