import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import CheckoutNav from "../components/bits/Checkout/CheckoutNav";
import CheckoutFooter from "../components/bits/Checkout/CheckoutFooter";
import CartSummary from "../components/bits/Checkout/CartSummary";
import { joinClasses } from "../utils";
import Loader from '../components/bits/Shared/Loader';
import styles from "./styles";

const CheckoutLayout = ({ children, classes, inProgress, id, isClubJoin, isBulk }) => (
  <div>
    <CheckoutNav id={id} isClubJoin={isClubJoin} isBulk={isBulk}/>
    <div className={joinClasses("container container--wide", classes.checkoutContainer)}>
      <div className={classes.checkoutForm}>
        {!inProgress && children}
        {inProgress && <Loader />}
      </div>
      {!isBulk && <CartSummary className={classes.hideMobile} id={id} />}
    </div>
    <CheckoutFooter />
  </div>
);

CheckoutLayout.propTypes = {
  classes: PropTypes.shape({
    checkoutContainer: PropTypes.string.isRequired,
    checkoutForm: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(styles)(CheckoutLayout);
