const cache = {};

class DataController {
  // private methods
  async _fetch(slug) {
    let url = process.env.REACT_APP_URL_BASE;

    url = `${url}${slug}`;
    const res = await fetch(url, {
      credentials: "include",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json"
      }
    }).catch(console.error);

    return res?.json();
  }

  // public methods
  async load(slug) {
    if (cache[slug]) {
      return cache[slug];
    }

    cache[slug] = await this._fetch(slug);
    return cache[slug];
  }
}

export default new DataController();
