import { createStyles } from '@material-ui/core';
import { grid, vw } from '../../../../utils/mixins';

export default theme => createStyles({
	container: {
		width: grid(5),
		position: 'sticky',
		top: '0',
		paddingTop: vw(70),
		[theme.breakpoints.down("sm")]: {
			position: "relative",
			paddingTop: '3rem'
		}
	},
	headline:  {
		paddingLeft: vw(60),
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 0,
		}
	},
	table: {
		textAlign: 'left',
		marginTop: vw(30),
		tableLayout: 'fixed',
		display: 'block',
		width: '100%',

		'& th': {
			paddingBottom: '2.4rem'
		},
		'& th:nth-child(1)': {
			paddingLeft: vw(60),
			[theme.breakpoints.down("sm")]: {
				paddingLeft: 0,
			}
		},
		'& th:last-child': {
			textAlign: 'right'
		},
		'& td': {
			paddingBottom: '3.2rem',
			[theme.breakpoints.down("sm")]: {
				paddingBottom: '1rem'
			},
			'&:last-child': {
				textAlign: 'right'
			}
		},

		[theme.breakpoints.down("sm")]: {
			'& thead': {
				display: 'none'
			},

			'& tbody': {
				display: 'flex',
				flexWrap: 'wrap',
				paddingTop: '2rem'
			},

			'& tr': {
				display: 'flex',
				flexWrap: 'wrap',
				width: '100%',
				justifyContent: 'space-between',
				marginBottom: '3rem'
			}
		}
	},
	productTitle: {
		width: grid(3, true),
		paddingLeft: vw(60),
		position: 'relative',
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 0,
			width: '100%'
		},
		'& p': {
			maxWidth: '14em',
			[theme.breakpoints.up("md")]: {
				marginBottom: '1rem',
			}
		},
		'& span': {
			opacity: '0.3',
			cursor: 'pointer',
			[theme.breakpoints.down("sm")]: {
				position: 'absolute',
				top: '0.1rem',
				right: 0
			}
		}
	},
	productQty: {
		paddingLeft: '2rem',
		[theme.breakpoints.up("sm")]: {
			minWidth: grid(1, true)
		}
	},
	productPrice: {
		[theme.breakpoints.up("sm")]: {
			minWidth: grid(1)
		},
		[theme.breakpoints.down("sm")]: {
			paddingTop: '1rem'
		}
	},
	cartTotal: {
		marginTop: '0.8rem',
		paddingLeft: vw(60),
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 0,
		},

		'& li': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '1rem 0',
			borderTop: `0.1rem solid #EBEBEB`,
			[theme.breakpoints.up("sm")]: {
				padding: '1.5rem 0',
			},
			'&:last-child': {
				fontWeight: '500',
				[theme.breakpoints.up("sm")]: {
					fontSize: '2.2rem'
				},
				[theme.breakpoints.up("sm")]: {
					borderTop: '0.1rem solid'
				}
			}
		}
	},
	promoCode: {
		display: 'flex',
		width: '100%',
		"& .MuiSvgIcon-root": {
			color: 'transparent',
		},
		"&:hover": {
			cursor: 'pointer',
			"& .MuiSvgIcon-root": {
				transition: '.5s linear all',
				color: theme.colors.primary,
			},
			"& .MuiSvgIcon-root": {
				transition: '.5s linear all',
				color: theme.colors.primary,
			},
		},
		"& p": {
			marginLeft: '33px',
			marginRight: vw(30)
		}
	},
	promoName: {
		display: 'flex',
		width: '100%',
		"& .MuiSvgIcon-root": {
			color: 'transparent',
		},
		"&:hover": {
			cursor: 'pointer',
			"& .MuiSvgIcon-root": {
				transition: '.5s linear all',
				color: theme.colors.primary,
			},
			"& .MuiSvgIcon-root": {
				transition: '.5s linear all',
				color: theme.colors.primary,
			},
		},
		"& p": {
			marginLeft: '26px',
		}
	},
	showForReview: {
		[theme.breakpoints.down("sm")]: {
			display: 'flex !important',
			flexDirection: 'column',
			width: '100%',
			order: '-1',
			paddingLeft: grid(1, true),
			paddingRight: grid(1, true),
			paddingBottom: '3rem',
			backgroundColor: theme.colors.background.tertiary,
			marginBottom: '0.8rem',
		}
	},
});