import React from "react";
import PropTypes from "prop-types";
import closeIcon from "../../../../assets/vectors/closeModal.svg";
import useLocalStorage from "../../../../utils/hooks/useLocalStorage"
import { withStyles } from "@material-ui/styles";
import { joinClasses } from "../../../../utils";
import styles from "./styles";

const CookieNotification = ({ classes }) => {
  const [open, setOpen] = useLocalStorage("aceptCookies", true);
  return false;
  if (process.env.NODE_ENV === 'development' || !open) return false;
  return (
    <div className={joinClasses(classes.container)}>
      <div className="container">
        <p>
          This site uses cookies to provide you with a better user experience.
          By using this site, you accept our terms of use.
        </p>
        <img onClick={() => setOpen(false)} src={closeIcon} />
      </div>
    </div>
  );
};

CookieNotification.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired
};

const StyledApp = withStyles(styles)(CookieNotification);

export default StyledApp;
