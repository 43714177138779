import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TableFooter, TableContainer } from '@material-ui/core';
import numeral from 'numeral';

const formatMoney = p => numeral(p || 0).format('$0,0.00');
const Carts = ({ items, classes }) => {
  const totalPayment = items.reduce((acc, item) => acc + item.totals.totalAmountDue, 0);

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <p className="eyebrow color--gray">Ship To</p>
              </TableCell>
              <TableCell>
                <p className="eyebrow color--gray">Address</p>
              </TableCell>
              <TableCell>
                <p className="eyebrow color--gray">City</p>
              </TableCell>
              <TableCell>
                <p className="eyebrow color--gray">State</p>
              </TableCell>
              <TableCell>
                <p className="eyebrow color--gray">Zip</p>
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                <p className="eyebrow color--gray">Discount</p>
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                <p className="eyebrow color--gray">Freight</p>
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                <p className="eyebrow color--gray">Tax</p>
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                <p className="eyebrow color--gray">Total</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(({ shippingAddress, totals, shoppingCartId }, index) => (
              <TableRow key={index}>
                <TableCell>
                  <p className="copy--body">{shippingAddress.name_first} {shippingAddress.name_last}</p>
                </TableCell>
                <TableCell>
                  <p className="copy--body">{shippingAddress.address1} {shippingAddress.address2}</p>
                </TableCell>
                <TableCell>
                  <p className="copy--body">{shippingAddress.city}</p>
                </TableCell>
                <TableCell>
                  <p className="copy--body">{shippingAddress.stateShort}</p>
                </TableCell>
                <TableCell>
                  <p className="copy--body">{shippingAddress.zip}</p>
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <p className="copy--body">{formatMoney(totals.totalMarkdown)}</p>
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <p className="copy--body">{formatMoney(totals.freight)}</p>
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <p className="copy--body">{formatMoney(totals.tax)}</p>
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <p className="copy--body">{formatMoney(totals.totalAmountDue)}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Table className={classes.bulkOrderTable}>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={5} className={classes.bulkOrderTableTotal}>
              Total Payment
            </TableCell>
            <TableCell colSpan={5} className={classes.bulkOrderTableTotalAmount}>
              {formatMoney(totalPayment)}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default Carts;
