import React, { Component } from 'react';
import { SEO } from '../../../bits';
import { withStyles } from '@material-ui/styles';
import { InputLabel, FormControlLabel, FormControl, Checkbox, MenuItem, TextField } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { formValueSelector, change, isInvalid, hasSubmitFailed, getFormSyncErrors, getFormAsyncErrors } from 'redux-form';

import { joinClasses, days, months } from '../../../../utils';
import { Form, Field, validate, FieldError } from '../../../../utils/forms'
import {
  DELIVER,
  PICKUP_OAKVILLE,
  PICKUP_HEALDSBURG,
  PICKUP_TW_CALISTOGA,
  PICKUP_TW_HEALDSBURG,
  PICKUP_TW_PHILO,
  PICKUP_TW_DUNDEE,
  getInitAddressId
} from '../../../../utils/checkout'
import AddressForm from '../../Forms/Address';
import PaymentMethodForm from '../../Forms/PaymentMethod';

import Select from '../../../bits/Shared/Select';
import Button from '../../../bits/Shared/Button';
import SavedAddresses from '../Shipping/SavedAddresses'
import AccountModal from '../../Modals/Account';
import CheckedIcon from '../../../bits/Shared/Icons/Checked'
import UncheckedIcon from '../../../bits/Shared/Icons/Unchecked'
import CheckoutLayout from '../../../../layouts/checkout'
import fetchDux from '../../../../store/reducers/fetch-dux';
import { actionCreators } from '../../../../store/reducers/billingReducer';
import { actionCreators as accountActionCreators } from '../../../../store/reducers/accountReducer';
import styles from '../styles';
import SavedCards from './SavedCards';
import queryString from 'query-string';

const FORM_NAME = 'billing-form';

const getMethod = ({ IsWillCall, shipViaId }) => {
  if (!IsWillCall) return DELIVER;
  if (shipViaId === PICKUP_OAKVILLE) return PICKUP_OAKVILLE;
  if (shipViaId === PICKUP_HEALDSBURG) return PICKUP_HEALDSBURG;
  if (shipViaId === PICKUP_TW_CALISTOGA) return PICKUP_TW_CALISTOGA;
  if (shipViaId === PICKUP_TW_HEALDSBURG) return PICKUP_TW_HEALDSBURG;
  if (shipViaId === PICKUP_TW_PHILO) return PICKUP_TW_PHILO;
  if (shipViaId === PICKUP_TW_DUNDEE) return PICKUP_TW_DUNDEE;
  return null;
}

const passwordMatch = (...props) => {
  const { password, confirmPassword } = props[1] || {};

  return password !== confirmPassword ? 'Passwords do not match.' : undefined;
}

const passwordLength = (...props) => {
  const { password } = props[1] || {};
  return password.length < 8 ? 'Password must be at least 8 characters long.' : undefined;
}

const validate21 = (value, form) => {
  const {
    dob_month,
    dob_day,
    dob_year
  } = form || {};
  if ((!dob_month && dob_month !== 0) || !dob_day || !dob_year) return undefined;


  const limit = moment().subtract(21, 'years');
  const dob = moment({ y: dob_year, M: dob_month, d: dob_day });
  if (!dob.isValid()) return 'Invalid Date'

  if (dob.isAfter(limit, 'day')) return 'Must be 21 or older'
}


const getInitialAddressValues = ({ billing, loggedIn, AddressBook }) => {
  if (!loggedIn || !!billing) {
    const addressBookId = getInitAddressId({ loggedIn, AddressBook, address: billing })

    const {
      name_first,
      name_last,
      email,
      company,
      address1,
      address2,
      city,
      zip,
      phone,
      stateId,
      countryId
    } = billing || {};
    return {
      addressBookId,
      name_first,
      name_last,
      email,
      company,
      address1,
      address2,
      city,
      zip,
      phone,
      stateId,
      countryId
    };
  }

  const {
    AddressBookID,
    FirstName: name_first,
    LastName: name_last,
    Email: email,
    Company: company,
    Address1: address1,
    Address2: address2,
    City: city,
    Zip: zip,
    Phone: phone,
    StateID: stateId,
    CountryID: countryId
  } = (AddressBook || []).find(({ IsDefaultMailing }) => IsDefaultMailing) || {};

  return {
    addressBookId: AddressBookID || '',
    name_first,
    name_last,
    email,
    company,
    address1,
    address2,
    city,
    zip,
    phone,
    stateId,
    countryId
  };
};

const getInitialCardId = ({ loggedIn, CreditCards }) => {
  if (!loggedIn) return null;

  const defaultCard = (CreditCards || []).find(({ IsDefault }) => IsDefault);

  const { CardId } = defaultCard || {};

  return CardId || null;
};

const getDOB = dob => {
  if (!dob || !moment(dob).isValid()) return null;
  const m = moment(dob);

  return {
    dob_month: m.month(),
    dob_day: m.date(),
    dob_year: m.year()
  };
};

const getInitialValues = (
  { billing, payment, loggedIn, AddressBook, CreditCards, userEmail, userDOB, shipping }
) => {
  const {
    name_first: shippingFirstName,
    name_last: shippingLastName
  } = shipping || {};
  const {
    addressBookId,
    name_first = shippingFirstName,
    name_last = shippingLastName,
    email,
    company,
    address1,
    address2,
    city,
    zip,
    phone,
    stateId,
    countryId
  } = getInitialAddressValues({ loggedIn, billing, AddressBook });

  const cardId = getInitialCardId({ loggedIn, CreditCards });

  const {
    cardId: storedCardId,
    cardNumber,
    expirationMonth,
    expirationYear,
    cvv,
    saveCard
  } = payment || {};

  const { dob } = billing || {};

  const {
    dob_month,
    dob_day,
    dob_year
  } = getDOB(dob || userDOB) || {};

  return {
    addressBookId,
    name_first,
    name_last,
    email: email || userEmail,
    company,
    address1,
    address2,
    city,
    zip,
    phone,
    stateId,
    countryId: countryId || 37,
    dob_month,
    dob_day,
    dob_year,
    cardId: storedCardId || cardId || '',
    cardNumber: cardNumber || null,
    expirationMonth: expirationMonth || null,
    expirationYear: expirationYear || null,
    cvv: cvv || null,
    saveCard
  };
};

const getShippingEmail = ({ email }) => email || '';

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noAddress: false
    };
    props.clearGetCart();
    props.clearGetCartBilling();
    props.clearGetCartShipping();
  }

  componentDidMount() {
    const { location: { search } } = this.props;
    const searchQuery = queryString.parse(search);
    const { clubJoin } = searchQuery || {};
    const isClubJoin = clubJoin === 'true';
    this.setState({ isClubJoin });
    window.scrollTo(0, 0);
    this.props.validateLogin();
    this.props.getCart({ clubJoin: isClubJoin });
    this.props.getCartBilling({ clubJoin: isClubJoin });
    this.props.getCartShipping({ clubJoin: isClubJoin });
    this.props.getLookups();
    this.setState({
      noAddress: false
    })
  }

  componentWillUnmount() {
    this.setState({
      noAddress: false
    })
  }

  componentWillReceiveProps(nextProps) {

    const emailA = getShippingEmail(this.props.shipping || {});
    const emailB = getShippingEmail(nextProps.shipping || {});

    if (emailA === emailB) return;

    this.props.getCheckEmail({ email: emailB });
  }

  render() {
    const {
      classes,
      results,
      setBilling,
      setAccount,
      postCartBilling,
      history,
      changeFormValue,
      user,
      cart,
      redirect,
      billing,
      shipping,
      payment,
      formData,
      lookups,
      inProgress,
      addressBookId,
      formValues,
      company,
      address2,
      paymentFormValues,
      sameAsShipping,
      saveInfo,
      checkedEmail,
      isFormInvalid,
      hasSubmitFailedValue,
      makeDefaultShipping: isMarkedSaveDefault,
      saveAddress,
      formErrors,
      formAsyncErrors,
    } = this.props;

    const {
      noAddress
    } = this.state;

    const { isClubJoin } = this.state;
    const { isHoldAtFedex } = shipping || {};
    const countryId = 37;

    const {
      // countryId,
      city,
      stateId,
      zip
    } = formValues || {};

    const scrollToTop = () => window.scrollTo(0, 0);

    const saveInformation = false;
    const setSaveInformation = () => { };

    if (redirect) return <Redirect to={`/shipping?clubJoin=${isClubJoin}`} />;
    const {
      shipper,
      promoCode,
      shipViaId
    } = cart || {};

    const { IsWillCall } = shipper || {};

    const method = getMethod({ IsWillCall, shipViaId });

    const hasAccount = user && user.userId;
    const { exists: emailExists } = checkedEmail || {};

    const { AddressBook, email, CreditCards: cc, DOB } = user || {};

    const CreditCards = (cc || []).filter(({ isAuthorized }) => isAuthorized);

    const initialValues = getInitialValues({
      billing,
      payment,
      loggedIn: hasAccount,
      AddressBook,
      CreditCards,
      userEmail: email,
      userDOB: DOB,
      shipping
    });

    initialValues.makeDefaultBilling = false;
    initialValues.saveInfo = formData.saveInfo || false;
    initialValues.password = formData.password || '';
    initialValues.confirmPassword = formData.confirmPassword || '';

    const handlePostCartBilling = values => {

      const {
        cardNumber,
        expirationMonth,
        expirationYear,
        cvv,
        cardId,
        saveCard,
        saveInfo,
        password,
        confirmPassword,
        ...payload
      } = values || {};

      const {
        address1,
        name_first,
      } = payload || {};

      if (!address1 && !name_first) {
        this.setState({ noAddress: true });
        return
      }

      this.setState({ noAddress: false });

      scrollToTop();

      addTracking(cart);

      postCartBilling({ payload: { ...payload, countryId: 37, clubJoin: isClubJoin } }, FORM_NAME, null, () => {
        scrollToTop();
        history.push(`/review?clubJoin=${isClubJoin}`);
      })

      setBilling({
        cardNumber,
        expirationMonth,
        expirationYear,
        cvv,
        cardId,
        saveCard: cardId !== 'new' || !hasAccount ? false : !!saveCard
      });

      setAccount({
        saveInfo,
        password,
        confirmPassword
      });
    };

    const { states, countries } = lookups || {};
    const stateOptions = (states || [])
      .filter(({ countryId: itemCountryId }) => itemCountryId === 37)
      .map(({ stateId, stateName }) => ({
        value: stateId,
        label: stateName
      }));
    const countryOptions = (countries || [])
      .map(({ countryId, countryName }) => ({
        value: countryId,
        label: countryName
      }));
    const selectedCountry = (countries || [])
      .find(({ countryId: itemCountryId }) => itemCountryId === countryId);

    const setNewAddress = () => {
      const addressBookIdMatch = getInitAddressId(AddressBook, formValues);
      changeFormValue(FORM_NAME, 'addressBookId', addressBookIdMatch)
    }

    const setSameAsShipping = (value) => {

      if (!value) {
        return
      }

      const {
        name_first,
        name_last,
        company,
        address1,
        address2,
        city,
        zip,
        phone,
        stateId
      } = shipping || {};

      changeFormValue(FORM_NAME, 'name_first', name_first);
      changeFormValue(FORM_NAME, 'name_last', name_last);
      changeFormValue(FORM_NAME, 'company', company);
      changeFormValue(FORM_NAME, 'address1', address1);
      changeFormValue(FORM_NAME, 'address2', address2);
      changeFormValue(FORM_NAME, 'city', city);
      changeFormValue(FORM_NAME, 'zip', zip);
      changeFormValue(FORM_NAME, 'phone', phone);
      changeFormValue(FORM_NAME, 'stateId', stateId);
      changeFormValue(FORM_NAME, 'countryId', 37);
    };

    const clearAddress = () => {
      changeFormValue(FORM_NAME, 'name_first', '');
      changeFormValue(FORM_NAME, 'name_last', '');
      changeFormValue(FORM_NAME, 'company', '');
      changeFormValue(FORM_NAME, 'address1', '');
      changeFormValue(FORM_NAME, 'address2', '');
      changeFormValue(FORM_NAME, 'city', '');
      changeFormValue(FORM_NAME, 'zip', '');
      changeFormValue(FORM_NAME, 'phone', '');
      changeFormValue(FORM_NAME, 'stateId', null);
      changeFormValue(FORM_NAME, 'countryId', 37);
      changeFormValue(FORM_NAME, 'makeDefaultBilling', false);
      changeFormValue(FORM_NAME, 'saveAddress', false);
    };

    const onMakeDefaultAddress = (value) => {
      changeFormValue(FORM_NAME, 'saveAddress', value);
    }

    const handleAddressBookChange = addressId => {

      const address = (AddressBook || [])
        .find(({ AddressBookID }) => AddressBookID === parseInt(addressId)) || {};

      const {
        FirstName: name_first,
        LastName: name_last,
        Company: company,
        Address1: address1,
        Address2: address2,
        City: city,
        Zip: zip,
        Phone: phone,
        StateID: stateId
      } = address || {};

      changeFormValue(FORM_NAME, 'addressBookId', parseInt(addressId));
      changeFormValue(FORM_NAME, 'name_first', name_first);
      changeFormValue(FORM_NAME, 'name_last', name_last);
      changeFormValue(FORM_NAME, 'company', company);
      changeFormValue(FORM_NAME, 'address1', address1);
      changeFormValue(FORM_NAME, 'address2', address2);
      changeFormValue(FORM_NAME, 'city', city);
      changeFormValue(FORM_NAME, 'zip', zip);
      changeFormValue(FORM_NAME, 'phone', phone);
      changeFormValue(FORM_NAME, 'stateId', stateId);
    };

    const handleCardChange = selectedCardId => {

      const selectedCard = (CreditCards || [])
        .find(({ CardId }) => CardId === parseInt(selectedCardId)) || {};

      const {
        CardId: cardId,
      } = selectedCard || {};

      changeFormValue(FORM_NAME, 'cardId', parseInt(cardId));
    };

    const setNewCard = () => {
      changeFormValue(FORM_NAME, 'cardId', 'new');
    }

    const years = Array.from(Array(90), (_, i) => new Date().getFullYear() - 21 - i)

    const addressInvalid =
      Object.keys(formErrors || {}).some(e => ['address1', 'city', 'name_first', 'name_last', 'phone', 'stateId', 'zip'].some(x => x === e))
      || Object.keys(formAsyncErrors || {}).some(e => ['address1', 'city', 'name_first', 'name_last', 'phone', 'stateId', 'zip'].some(x => x === e))

    return (
      <>
        <SEO
          title='Payment'
          meta={null}
        />
        <CheckoutLayout id='payment' inProgress={inProgress} isClubJoin={isClubJoin}>
          <div className={joinClasses('payment')}>
            <Form form={FORM_NAME} initialValues={initialValues} onSubmit={handlePostCartBilling} enableReinitialize>
              <div className={classes.container}>
                <h2 className={joinClasses('h4', classes.headline)}>
                  Payment Information
                </h2>
                {hasAccount && (
                  <div className={classes.section}>
                    <SavedAddresses
                      context='Billing'
                      method={method}
                      user={user}
                      stateOptions={stateOptions}
                      countryOptions={countryOptions}
                      setNewAddress={setNewAddress}
                      addressBookId={addressBookId}
                      handleAddressBookChange={handleAddressBookChange}
                      formValues={formValues}
                      address2={address2}
                      company={company}
                      clearAddress={clearAddress}
                      addressInvalid={addressInvalid}
                      saveAsDefaultAllowed
                      onMakeDefaultAddress={onMakeDefaultAddress}
                      isMarkedSaveDefault={isMarkedSaveDefault}
                      changeState={(input) => changeFormValue(FORM_NAME, 'stateId', input)}
                      states={states}
                      selectedCountry={selectedCountry}
                    />
                  </div>
                )}
                {!hasAccount && (
                  <>
                    <div className={classes.section}>
                      <FormControl
                        className={joinClasses(
                          classes.formControlFullWidth,
                          classes.single
                        )}
                      >
                        {(!IsWillCall && !isHoldAtFedex) && (
                          <FormControlLabel
                            control={(
                              <Field name='sameAsShipping' checkbox>
                                <Checkbox
                                  color='secondary'
                                  icon={<UncheckedIcon />}
                                  checkedIcon={<CheckedIcon />}
                                  onClick={e => {
                                    setSameAsShipping(e.target.checked)
                                  }}
                                />
                              </Field>
                            )}
                            label='Same as shipping address'
                          />
                        )}
                      </FormControl>
                      {!sameAsShipping && (
                        <AddressForm
                          context='Billing'
                          heading='Billing Address'
                          className={classes.address}
                          stateOptions={stateOptions}
                          countryOptions={countryOptions}
                          selectedCountry={selectedCountry}
                          countryField={false}
                          setNewAddress={setNewAddress}
                          company={company}
                          address2={address2}
                          city={city}
                          zip={zip}
                          stateId={stateId}
                          changeState={(input) => changeFormValue(FORM_NAME, 'stateId', input)}
                          states={states}
                        />
                      )}
                    </div>
                  </>
                )}

                <div className={classes.section}>
                  <p className={joinClasses('h6', classes.subHeadline)}>
                    Birth Date
                  </p>
                  <div className={classes.birthContainer}>
                    <FormControl className={classes.ageMonth}>
                      <Field name='dob_month' validate={[validate.requiredNumber, validate21]}>
                        <InputLabel required sdwlabel>Month</InputLabel>
                        <Select sdwinput autoposition>
                          {months.map((item, idx) => (
                            <MenuItem key={item} value={idx}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        <FieldError sdwerror />
                      </Field>
                    </FormControl>
                    <FormControl className={classes.ageDate}>
                      <Field name='dob_day' validate={[validate.requiredNumber]}>
                        <InputLabel required sdwlabel>Day</InputLabel>
                        <Select id='age-date' sdwinput autoposition>
                          {days.map(d => (
                            <MenuItem key={d} value={d}>
                              {d}
                            </MenuItem>
                          ))}
                        </Select>
                        <FieldError sdwerror />
                      </Field>
                    </FormControl>
                    <FormControl className={joinClasses(classes.colContainer, classes.ageYear)}>
                      <Field name='dob_year' validate={[validate.requiredNumber]}>
                        <InputLabel required sdwlabel>Year</InputLabel>
                        <Select id='age-year' sdwinput autoposition>
                          {years.map(year => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                        <FieldError sdwerror />
                      </Field>
                    </FormControl>
                  </div>
                </div>
                <div>
                  {hasAccount && (
                    <SavedCards
                      heading='Payment Method'
                      user={user}
                      handleCardChange={handleCardChange}
                      setNewCard={setNewCard}
                      paymentFormValues={paymentFormValues}
                    />
                  )}
                  {!hasAccount && (
                    <>
                      <p className={joinClasses('h6', classes.subHeadline)}>
                        Payment Method
                      </p>
                      <PaymentMethodForm />
                    </>
                  )}
                </div>
              </div>
              {(!hasAccount &&
                !emailExists &&
                !(emailExists == null)) || (!hasAccount && isClubJoin) && (
                  <div className={classes.container}>
                    {isClubJoin &&
                      <span aria-hidden="true" class="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>
                    }
                    <h5>
                      {isClubJoin ? 'Create Account Password' : 'Save Account Information?'}
                    </h5>
                    {!isClubJoin &&
                      <FormControlLabel
                        className={classes.checkbox}
                        control={(
                          <Field
                            name='saveInfo'
                            checkbox
                            validate={isClubJoin ? [validate.required] : []}
                          >
                            <Checkbox
                              color='secondary'
                              icon={<UncheckedIcon />}
                              checkedIcon={<CheckedIcon />}
                              required={isClubJoin}
                            />
                          </Field>
                        )}
                        label='Yes, save my information and create an account'
                      />
                    }

                    {(saveInfo || isClubJoin) && (
                      <div>
                        <Field
                          name='password'
                          type='password'
                          validate={[
                            validate.required,
                            passwordLength
                          ]}
                        >
                          <TextField
                            label='Password'
                            className={joinClasses(classes.textField)}
                            type='password'
                          />
                        </Field>
                        <Field
                          name='confirmPassword'
                          validate={[
                            validate.required,
                            passwordMatch,
                            passwordLength
                          ]}
                        >
                          <TextField
                            label='Confirm Password'
                            className={joinClasses(classes.textField)}
                            type='password'
                          />
                        </Field>
                      </div>
                    )}
                  </div>
                )}
              <div className={classes.buttonContainer}>
                <Button
                  type='submit'
                  title={isClubJoin ? 'Review Membership' : 'Review Order'}
                  id="billing-submit" // DO NOT DELETE - GTM tracking
                />
                {noAddress && (
                  <p style={{ color: 'red' }}>
                    {user ? 'Please select or enter a new address before continuing.' : 'Please enter an address before continuing.'}
                  </p>
                )}
                {isFormInvalid && hasSubmitFailedValue && (
                  <p style={{ color: 'red' }}>
                    Missing required information. Please check form and resubmit.
                  </p>
                )}
              </div>
              <AccountModal isOpen={saveInformation} onClose={() => setSaveInformation(false)} />
            </Form>
          </div>
        </CheckoutLayout>
      </>
    )
  }
}

const selector = formValueSelector(FORM_NAME);
const isInvalidSelector = isInvalid(FORM_NAME);
const hasSubmitFailedSelector = hasSubmitFailed(FORM_NAME);
const getFormSyncErrorsSelector = getFormSyncErrors(FORM_NAME);
const getFormAsyncSyncErrorsSelector = getFormAsyncErrors(FORM_NAME);

const mapState = state => {

  const addressBookId = selector(state, 'addressBookId');
  const address1 = selector(state, 'address1');
  const address2 = selector(state, 'address2');
  const name_first = selector(state, 'name_first');
  const name_last = selector(state, 'name_last');
  const city = selector(state, 'city');
  const zip = selector(state, 'zip');
  const stateId = selector(state, 'stateId');
  const countryId = selector(state, 'countryId');
  const company = selector(state, 'company');
  const phone = selector(state, 'phone');

  const makeDefaultShipping = selector(state, 'makeDefaultBilling');
  const saveAddress = selector(state, 'saveAddress');

  const cardId = selector(state, 'cardId');
  const expirationMonth = selector(state, 'expirationMonth');
  const expirationYear = selector(state, 'expirationYear');
  const cardNumber = selector(state, 'cardNumber');
  const cvv = selector(state, 'cvv');
  const saveCard = selector(state, 'saveCard');

  const sameAsShipping = selector(state, 'sameAsShipping');

  const saveInfo = selector(state, 'saveInfo') || null;

  const formErrors = getFormSyncErrorsSelector(state);
  const formAsyncErrors = getFormAsyncSyncErrorsSelector(state);


  const formValues = {
    address1,
    address2,
    name_first,
    name_last,
    city,
    zip,
    stateId,
    company,
    phone,
    countryId
  };

  const paymentFormValues = {
    cardId,
    expirationMonth,
    expirationYear,
    cardNumber,
    cvv,
    saveCard
  };

  const {
    error: getCartError,
    inProgress,
    result: cart
  } = state.fetchReducer.getCart.toJS();

  const {
    // error,
    inProgress: billingInProgress,
    result: billing
  } = state.fetchReducer.getCartBilling.toJS();

  const {
    error: getCartShippingError,
    result: shipping
  } = state.fetchReducer.getCartShipping.toJS();

  const {
    // error,
    inProgress: lookupsInProgress,
    result: lookups
  } = state.fetchReducer.getLookups.toJS();

  const {
    result: user
  } = state.fetchReducer.postLogin.toJS();

  const {
    inProgress: userInProgress
  } = state.fetchReducer.validateLogin.toJS();

  const {
    result: checkedEmail
  } = state.fetchReducer.getCheckEmail.toJS();

  const payment = state.billing || {};
  const formData = state.account || {};

  const redirect = getCartError || getCartShippingError;

  // const promoCodeResult = state.fetchReducer.postPromoCode.toJS();
  // const delPromoCodeResult = state.fetchReducer.delPromoCode.toJS();

  const isFormInvalid = isInvalidSelector(state);
  const hasSubmitFailedValue = hasSubmitFailedSelector(state);

  return {
    redirect,
    user,
    lookups,
    cart,
    billing,
    shipping,
    payment,
    formData,
    addressBookId,
    formValues,
    paymentFormValues,
    sameAsShipping,
    company: !!company,
    address2: !!address2,
    saveInfo,
    checkedEmail,
    isFormInvalid,
    formErrors,
    formAsyncErrors,
    hasSubmitFailedValue,
    makeDefaultShipping,
    saveAddress,
    // promoCodeResult,
    // delPromoCodeResult,
    inProgress: inProgress ||
      billingInProgress ||
      lookupsInProgress ||
      userInProgress
  };
};


const mapDispatch = dispatch => bindActionCreators({
  ...actionCreators,
  ...accountActionCreators,
  changeFormValue: change,
  getCart: fetchDux.getCart.createAction,
  getCartBilling: fetchDux.getCartBilling.createAction,
  getCartShipping: fetchDux.getCartShipping.createAction,
  clearGetCart: fetchDux.getCart.clearAction,
  clearGetCartBilling: fetchDux.getCartBilling.clearAction,
  clearGetCartShipping: fetchDux.getCartShipping.clearAction,
  getLookups: fetchDux.getLookups.createAction,
  postCartBilling: fetchDux.postCartBilling.createAction,
  getCheckEmail: fetchDux.getCheckEmail.createAction,
  validateLogin: fetchDux.validateLogin.createAction
  // postPromoCode: fetchDux.postPromoCode.createAction,
  // delPromoCode: fetchDux.delPromoCode.createAction
}, dispatch);

const StyledPayment = withStyles(styles)(Payment);

export default connect(mapState, mapDispatch)(StyledPayment);

const addTracking = (cart) => {

  const { brandingId, totals, shoppingCartItems } = cart || {};
  const getAffiliation = (id) => {
    if (id === 1) {
      return "Silver Oak";
    } else if (id === 2) {
      return "Twomey";
    }
  }

  if (window && window.dataLayer) {
    const transactionProducts = (shoppingCartItems || []).map(({
      qty,
      kitId,
      descriptionShort,
      unitPrice,
      brandingName,
      brandingId,
      categories,
      discount,
    }) => {

      const itemCategories = (categories || []).map(({ productCategoryName }, index) => ({
        [`item_category${index + 1}`]: productCategoryName
      }));

      return ({
        item_id: kitId,
        item_name: descriptionShort,
        affiliation: getAffiliation(brandingId),
        currency: "USD",
        item_brand: brandingName,
        ...(itemCategories.length > 0 && itemCategories.reduce((acc, item) => ({ ...acc, ...item }))),
        discount: discount ?? 0,
        price: unitPrice,
        quantity: qty
      });
    })

    const gaEcomm = {
      event: 'add_payment_info',
      ecommerce: {
        affiliation: getAffiliation(brandingId),
        payment_type: 'Credit Card',
        currency: 'USD',
        value: totals?.subTotal,
        items: transactionProducts
      }
    }
    window.dataLayer.push(gaEcomm);
  }
}

