import React from 'react';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '../../../../bits/Shared/Select'

import { joinClasses, dateUtils } from '../../../../../utils';
import { Field, FieldError, validate } from '../../../../../utils/forms';
import styles from '../../styles';


const validDate = value =>
  value &&
    moment(value).isValid() &&
    moment(value).isAfter(moment().startOf('day'))
    ? undefined
    : 'Invalid';

const validate50 = validate.characters(50)
const validate300 = validate.characters(300)
const validate500 = validate.characters(500)

const maxLines8 = validate.maxLines(8);

const PickupOakville = ({
  classes,
  isActive,
  requiresShipDate,
  formValues,
  lookups,
  brandingId,
  minPickupDate,
  isClubJoin
}) => {

  if (!isActive) return false;

  const { shipDates, blackoutDates } = lookups || {};
  const { shipViaId } = formValues || {};
  const shouldDisableDate = (blackoutDates) => d => {
    if (!d) return true;

    const isBlackoutDate = (blackoutDates || []).some(blackoutDate =>
      new Date(blackoutDate.blackoutDate).toDateString() === d.toDateString()
    );
    if (isBlackoutDate) return true;
  }

  const shipdateOptions = (shipDates || [])
    .filter(({ requestedShipDate }) => moment(requestedShipDate, 'MM/DD/YYYY').isAfter(moment().startOf('day')))
    .filter(({ brandingId: b }) => b === brandingId)
    .filter(({ shippingServiceId }) => shippingServiceId === shipViaId)
    .map(({ requestedShipDate }) => ({
      value: requestedShipDate,
      label: requestedShipDate
    }))

  const showShipDateDropdown = requiresShipDate && (shipdateOptions || []).length > 0;


  return (
    <div
      className={joinClasses(classes.shippingOption, 'is-active')}
    >
      <div className={joinClasses('grid', classes.section, classes.noMargin, 'copy--large')}>
        <div className={classes.pickupAddress}>
          <p className={classes.mobileMargin}>Pick Up Address</p>
          <p>
            <span className={classes.dBlock}>Silver Oak – Oakville</span>
            <span className={classes.dBlock}>915 Oakville Cross Road</span>
            <span className={classes.dBlock}>Oakville, CA 94562</span>
          </p>
        </div>
        <div>
          <p className={classes.mobileMargin}>Phone Number</p>
          <p>
            <a className={classes.dBlock} href='tel:+1-707-942-7022'>(707) 942-7022</a>
          </p>
        </div>
      </div>
      {
        !isClubJoin && (
          <>
            <div className={classes.section}>
              <FormControl className={joinClasses(classes.shippingField)}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className={classes.extra2}>
                    <p className={joinClasses('h6', classes.subHeadline)}>Pick Up Date</p>
                    {!showShipDateDropdown && (
                      <Field name='requestedShipDate' validate={[validDate]}>
                        <DatePicker
                          sdwinput
                          minDate={minPickupDate}
                          views={['date']}
                          autoOk
                          disableToolbar
                          variant='inline'
                          shouldDisableDate={shouldDisableDate(blackoutDates)}
                          labelFunc={date =>
                            `${dateUtils.getMonthText(date)} ${dateUtils.getDayText(
                              date
                            )}, ${dateUtils.getYear(date)}`}
                        />
                      </Field>
                    )}
                    {showShipDateDropdown && (
                      <Field name='requestedShipDate' validate={[validDate]}>
                        <Select sdwinput required>
                          {(shipdateOptions || []).map(({ label, value }) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Field>
                    )}
                  </div>
                </MuiPickersUtilsProvider>
              </FormControl>
              <p className='copy--small'>
                You can pick up your order on or after the chosen date.
              </p>
            </div>

            <div className={classes.section}>
              <p className={joinClasses('h6', classes.subHeadline)}>
                Pick Up Information
              </p>
              <Field name='name_first' validate={[validate.required, validate50]}>
                <TextField
                  label='First Name'
                  className={classes.textField}
                  required
                />
              </Field>
              <Field name='name_last' validate={[validate.required, validate50]}>
                <TextField
                  label='Last Name'
                  className={classes.textField}
                  required
                />
              </Field>
              <Field name='giftNote' validate={[validate300, maxLines8]}>
                <Input
                  type='text'
                  multiline
                  rows={4}
                  disableUnderline
                  className={classes.textArea}
                  placeholder='Include a message for recipient. (8 line limit)'
                />
                <FieldError />
              </Field>
            </div>
          </>
        )
      }

      <div className={classes.section} style={{ marginTop: isClubJoin ? '3rem' : '0' }}>
        <p className={joinClasses('h6', classes.subHeadline)}>
          Special Instructions
        </p>
        <Field name='specialInstructions' validate={[validate500]}>
          <Input
            type='text'
            multiline
            rows={4}
            disableUnderline
            className={classes.textArea}
            placeholder='Business instructions, hold for weather, etc.'
          />
        </Field>
      </div>
    </div >
  );
};

export default withStyles(styles)(PickupOakville);
