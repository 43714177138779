import { createStyles } from "@material-ui/core";

export default theme =>
  createStyles({
    container: {
      position: "fixed",
      bottom: 0,
      paddingTop: "2rem",
      paddingBottom: "2rem",
      width: "100%",
      background: theme.colors.white,
      zIndex: 1000,
      opacity: 0,
      'body.is-ready &, body.is-loaded &': {
        animation: 'fade-in 500ms var(--ease-out-jim) forwards 2000ms',
      },
      "& .container": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      },
      "& img": {
        cursor: "pointer"
      }
    },
  });
