import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';

import Select from '../../../../bits/Shared/Select';
import { joinClasses, dateUtils } from '../../../../../utils';
import SavedAddresses from '../SavedAddresses';
import AddressForm from '../../../Forms/Address';
import FedexPickUpLocationSearchForm from '../../../Forms/FedexPickUpLocationSearchForm';
import SelectedFedexLocation from '../Pickup/SelectedFedexLocation';
import { Field, FieldError, validate } from '../../../../../utils/forms';
import { DELIVER, PICKUP_FEDEX } from '../../../../../utils/checkout';

const validDateShip = ({ defaultShipDate, requiresShipDate, shipdateOptions, blackoutDates }) => (value, allValues) => {
  if (!value) return 'Required';
  const {
    shipViaId,
    deliveryMethod
  } = allValues || {};
  const daysToShip = (deliveryMethod === DELIVER || deliveryMethod === PICKUP_FEDEX) ? (shipViaId === 9 ? 1 : 2) : 0;

  if (!moment(value).isValid()) return 'Invalid';
  if (requiresShipDate && (shipdateOptions || []).length > 0) {
    const validR = (shipdateOptions || []).some(({ value: v }) => moment(v).format('MM/DD/YYYY') === moment(value).format('MM/DD/YYYY'))
    if (!validR) return 'Invalid';
    return undefined;
  }
  const limit = defaultShipDate && moment(defaultShipDate, 'MM/DD/YYYY').isValid() ? moment(defaultShipDate, 'MM/DD/YYYY') : moment();
  const compare = moment(value).subtract(daysToShip, 'days');
  if (compare.isBefore(limit, 'day')) return 'Invalid';
  const invalidDay = shouldDisableDate(shipViaId, blackoutDates)(moment(value).toDate())
  if (invalidDay) return 'Invalid';
  return undefined;
}

const getMinDate = ({ defaultShipDate, daysToShip, blackoutDates, shipViaId }) => {
  const limit = defaultShipDate && moment(defaultShipDate, 'MM/DD/YYYY').isValid() ? moment(defaultShipDate, 'MM/DD/YYYY') : moment();

  let minDate = limit.add(daysToShip, 'days').toDate();
  let invalidDay = shouldDisableDate(shipViaId, blackoutDates)(minDate);

  while (invalidDay) {
    minDate = moment(minDate).add(1, 'day').toDate();
    invalidDay = shouldDisableDate(shipViaId, blackoutDates)(minDate);
  }
  return minDate;
}

const shouldDisableDate = (shipViaId, blackoutDates) => d => {
  if (!d) return true;

  const isBlackoutDate = (blackoutDates || []).some(blackoutDate =>
    new Date(blackoutDate.blackoutDate).toDateString() === d.toDateString()
  );
  if (isBlackoutDate) return true;

  const day = d.getDay();
  if (shipViaId === 9) return ![2, 3, 4, 5].some(x => x === day);
  return ![3, 4, 5].some(x => x === day);
}

const validate500 = validate.characters(500)

const Delivery = ({
  classes,
  change,
  method,
  lookups,
  hasAccount,
  user,
  company,
  address2,
  addressBookId,
  setNewAddress,
  clearAddress,
  handleAddressBookChange,
  formValues,
  daysToShip,
  defaultShipDate,
  openWhereWeShip,
  validState,
  addressInvalid,
  brandingId,
  requiresShipDate,
  onMakeDefaultAddress,
  isMarkedSaveDefault,
  isClubJoin,
  findFedexLocation,
  findFedexLocationResult,
  findFedexLocationError,
  findFedexLocationResultInProgress,
  finalPickUpInfo,
  onChangeSelectedFDXLocation,
  initialValues,
  changeLocation,
  hasAlcohol,
  onSearchZip,
  onChangeDistance
}) => {
  if (method !== DELIVER && method !== PICKUP_FEDEX) return false; // SDW - Necessary for conditional validation
  const { isHoldAtFedex } = initialValues || {};
  const { errors } = findFedexLocationResult || {};
  const { pickUpPerson, selectedFedexLocation } = finalPickUpInfo || {};
  const { states, shippers, shipDates, blackoutDates } = lookups || {};
  const stateOptions = (states || [])
    .filter(({ countryId }) => countryId === 37)
    .map(({ stateId, stateName }) => ({
      value: stateId,
      label: stateName
    }));

  const shipperOptions = (shippers || [])
    .filter(({ brandingId }) => brandingId !== 3)
    .map(({ shipTrackingTypeId, shipTrackingName }) => ({
      value: shipTrackingTypeId,
      label: shipTrackingName
    }));

  const isWeekend = (date) => {
    const momentDate = moment(date);
    const dayOfWeek = momentDate.day();
    return dayOfWeek === 6 || dayOfWeek === 0;
  };

  const onShipViaIdChange = (e, value, prevValue) => {
    const days = (value === 9 ? 1 : 2);
    const limit = defaultShipDate && moment(defaultShipDate, 'MM/DD/YYYY').isValid() ? moment(defaultShipDate, 'MM/DD/YYYY') : moment();
    change('shipViaId', value)

    const s = (shipDates || [])
      .filter(({ requestedShipDate }) => moment(requestedShipDate, 'MM/DD/YYYY').isAfter(moment().startOf('day')))
      .filter(({ brandingId: b }) => b === brandingId)
      .filter(({ shippingServiceId }) => parseInt(shippingServiceId) === parseInt(value))
      .map(({ requestedShipDate }) => ({
        value: requestedShipDate,
        label: moment(requestedShipDate).add(days, 'days').format('MM/DD/YYYY')
      }))

    if (requiresShipDate && (s || []).length > 0) {
      change('requestedShipDate', s[0].value)
      return;
    }

    let firstAvailableDate = limit.add(days || 0, 'days').toDate();
    while (true) {
      const isInBlackoutDates = (blackoutDates || []).some(
        blackoutDate =>
          moment(blackoutDate.blackoutDate).startOf('day').isSame(firstAvailableDate, 'day')
      );
      const isWeekendDate = isWeekend(firstAvailableDate);

      if (isInBlackoutDates || isWeekendDate) {
        firstAvailableDate = moment(firstAvailableDate).add(1, 'day').toDate();
      } else {
        if (value === 1 && (moment(firstAvailableDate).day() === 1 || moment(firstAvailableDate).day() === 2)) {
          firstAvailableDate = moment(firstAvailableDate).add(1, 'day').toDate();
        } else if ((value === 9 && (moment(firstAvailableDate).day() === 1))) {
          firstAvailableDate = moment(firstAvailableDate).add(1, 'day').toDate();
        }
        else {
          break;
        }
      }
    }
    change('requestedShipDate', firstAvailableDate)
  }

  const { shipViaId, city, zip, stateId } = formValues || {};
  const minDate = getMinDate({ defaultShipDate, daysToShip, blackoutDates, shipViaId })

  const shipdateOptions = (shipDates || [])
    .filter(({ requestedShipDate }) => moment(requestedShipDate, 'MM/DD/YYYY').isAfter(moment().startOf('day')))
    .filter(({ brandingId: b }) => b === brandingId)
    .filter(({ shippingServiceId }) => shippingServiceId === shipViaId)
    .map(({ requestedShipDate }) => ({
      value: moment(requestedShipDate).add(daysToShip, 'days').format('MM/DD/YYYY'),
      label: moment(requestedShipDate).add(daysToShip, 'days').format('MM/DD/YYYY')
    }))

  return (
    <div
      className={joinClasses(
        classes.shippingOption,
        method === DELIVER || method === PICKUP_FEDEX ? 'is-active' : 'is-disabled'
      )}
    >
      <div className={classes.section}>
        {hasAccount && method === DELIVER && (
          <SavedAddresses
            user={user}
            context='Shipping'
            method={method}
            stateOptions={stateOptions}
            addressBookId={addressBookId}
            setNewAddress={setNewAddress}
            clearAddress={clearAddress}
            handleAddressBookChange={handleAddressBookChange}
            formValues={formValues}
            address2={address2}
            company={company}
            addressInvalid={addressInvalid}
            saveAsDefaultAllowed
            onMakeDefaultAddress={onMakeDefaultAddress}
            isMarkedSaveDefault={isMarkedSaveDefault}
            overrideCountry
          />
        )}
        {!hasAccount && method === DELIVER && (
          <AddressForm
            heading='Shipping Address'
            stateOptions={stateOptions}
            company={company}
            address2={address2}
            context='Shipping'
            validState={validState}
            city={city}
            zip={zip}
            stateId={stateId}
          />
        )}

        {/* this is the form to enter a zip and search for fedex locations */}
        {method === PICKUP_FEDEX &&
          !pickUpPerson &&
          !selectedFedexLocation &&
          ((findFedexLocationResult || []).length < 1 ||
            (errors || []).length > 0) &&
          (!isHoldAtFedex ||
            changeLocation) && (
            <FedexPickUpLocationSearchForm
              findFedexLocation={findFedexLocation}
              findFedexLocationResult={findFedexLocationResult}
              findFedexLocationResultInProgress={findFedexLocationResultInProgress}
              errors={errors}
              states={states}
              hasAlcohol={hasAlcohol}
              onSearchZip={onSearchZip}
              onChangeDistance={onChangeDistance}
            />
          )}
        {/* this is to show the pick up person's name and pick up address once position selected and pick up person info collected*/}
        {method === PICKUP_FEDEX && ((pickUpPerson && selectedFedexLocation) || (initialValues || {}).isHoldAtFedex) && !changeLocation && (
          <SelectedFedexLocation
            pickUpPerson={pickUpPerson}
            selectedFedexLocation={selectedFedexLocation}
            onChangeSelectedFDXLocation={onChangeSelectedFDXLocation}
            initialValues={initialValues}
          />
        )}

        <p className={classes.disclaimer}>
          We don't ship wine to all locations due to restrictions.{' '}
          <button type='button' onClick={openWhereWeShip}>See where we ship</button>
        </p>
      </div>
      <div className={classes.mobileSpacer} />
      {
        !isClubJoin &&
        <div className={classes.section}>
          <p className={joinClasses('h6', classes.subHeadline)}>
            Shipping Details
          </p>
          <FormControl className={joinClasses(classes.shippingField)}>
            <Field
              name='shipViaId'
              validate={[validate.required]}
              onChange={onShipViaIdChange}
            >
              <InputLabel
                sdwlabel
                htmlFor='carrier'
                className='eyebrow color--gray'
              >
                Select Method
              </InputLabel>
              <Select sdwinput required>
                {(shipperOptions || []).map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <FieldError sdwerror />
            </Field>
          </FormControl>
          <FormControl className={classes.shippingField}>

            <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.extra}>
              <div className={classes.extra}>
                <ShipDateWrapper
                  shipViaId={shipViaId}
                  minDate={minDate}
                  defaultShipDate={defaultShipDate}
                  daysToShip={daysToShip}
                  requiresShipDate={requiresShipDate}
                  shipdateOptions={shipdateOptions}
                  blackoutDates={blackoutDates}
                />
              </div>
            </MuiPickersUtilsProvider>
          </FormControl>
          {/* <p style={{ marginTop: '5px' }}>Orders are processed within 1-2 business days. Note that shipping carriers are experiencing delays. Please call us at (707) 942-7137 if you need to expedite your order.</p> */}
          {/* Text below is for when extreme heat is present and we want to let customer know this. Normal text is above */}
          <p style={{ marginTop: '5px', fontWeight: 'bold' }}>
            Orders are processed within 1-2 business days. The safe arrival of your shipment is a top priority. If there are extreme weather conditions that could affect your order, we will delay the ship date. Please check email confirmations for more information.          </p>
        </div>
      }

      <div className={classes.section}>
        <p className={joinClasses('h6', classes.subHeadline)}>
          Special Instructions
        </p>
        <FormControl className={classes.instructions}>
          <Field name='specialInstructions' validate={[validate500]}>
            <Input
              id='instructions'
              type='text'
              multiline
              rows={4}
              disableUnderline
              placeholder='Business instructions, hold for weather, etc.'
            />
          </Field>
        </FormControl>
      </div>
    </div>
  );
};

const areShippingOptionsEqual = (a, b) => {
  if (a.length !== b.length) return false;

  return a.every(({ value, label }, i) => b[i].label === label && b[i].value === value);
}

class ShipDateWrapper extends Component {
  constructor(props) {
    super(props);
    const {
      defaultShipDate, requiresShipDate, shipdateOptions, blackoutDates
    } = props;
    this.validate = validDateShip({ defaultShipDate, requiresShipDate, shipdateOptions, blackoutDates })
  }
  componentWillReceiveProps(nextProps) {
    const {
      defaultShipDate, requiresShipDate, shipdateOptions, daysToShip, shipViaId, blackoutDates
    } = nextProps;
    if (
      (defaultShipDate === this.props.defaultShipDate) &&
      (daysToShip === this.props.daysToShip) &&
      (shipViaId === this.props.shipViaId) &&
      (requiresShipDate === this.props.requiresShipDate) &&
      (requiresShipDate ? areShippingOptionsEqual(shipdateOptions || [], this.props.shipdateOptions || []) : true)
    ) return;
    this.validate = validDateShip({ defaultShipDate, requiresShipDate, shipdateOptions, blackoutDates })

  }
  render() {
    const {
      minDate,
      shipViaId,
      shipdateOptions,
      requiresShipDate,
      blackoutDates
    } = this.props;
    if (requiresShipDate) return (
      <Field
        name='requestedShipDate'
        validate={[this.validate]}
      >
        <InputLabel className='eyebrow color--gray' sdwlabel shrink>
          Delivery Date
        </InputLabel>
        {(!(shipdateOptions || []).length) &&
          <Select sdwinput required>
            <MenuItem key={0} value={null}>
              {'No Shipping Options Available'}
            </MenuItem>
          </Select>
        }
        {((shipdateOptions || []).length > 0) &&
          <Select sdwinput required>
            {(shipdateOptions || []).map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        }
      </Field>
    )

    return (
      <Field
        name='requestedShipDate'
        validate={[this.validate]}
      >
        <InputLabel className='eyebrow color--gray' sdwlabel shrink>
          Delivery Date
        </InputLabel>
        <DatePicker
          required
          sdwinput
          minDate={minDate}
          views={['date']}
          autoOk
          disableToolbar
          shouldDisableDate={shouldDisableDate(shipViaId, blackoutDates)}
          variant='inline'
          labelFunc={date => {
            if (!date) return '';
            return `${dateUtils.getMonthText(date)} ${dateUtils.getDayText(
              date
            )}, ${dateUtils.getYear(date)}`
          }}
        />
      </Field>
    )
  }
}

export default Delivery;
