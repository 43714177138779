import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { joinClasses } from '../../../../utils';
import styles from './styles';

const DescriptionList = ({ classes, className, data }) => {
  const createMarkup = (content) => {
    return {__html: content};
  }
	return (
    <div className={joinClasses(
      classes.container,
      className
    )}>
      {data.map((dl, index) => (
        <div
          key={index}
          className={joinClasses(
            classes.descriptionList,
            index + 1 === data.length ? classes.lastChild : ''
          )}
        >
          <span
            className={classes.listTitle}
            dangerouslySetInnerHTML={createMarkup(dl.list_title)}
          />
          {dl?.list && dl.list.length > 0 &&
            <dl className={classes.dl}>
              {dl.list.map((list, listIndex) => (
                <div className={classes.dlItem} key={listIndex}>
                  <dt>{list.term}</dt>
                  <dd
                    dangerouslySetInnerHTML={createMarkup(list.description)}
                    className={
                      listIndex + 1 === dl.list.length ? classes.lastChild : ''
                    }
                  />
                </div>
              ))}
            </dl>
          }
        </div>
      ))}
    </div>
	);
};

DescriptionList.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired
};

export default withStyles(styles)(DescriptionList);
