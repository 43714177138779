import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';

import { joinClasses } from '../../../../../utils';
import { Field, validate } from '../../../../../utils/forms';
import { DELIVER, PICKUP_FEDEX } from '../../../../../utils/checkout';

const validate40 = validate.characters(40);
const validate50 = validate.characters(50);
const validate300 = validate.characters(300);

const maxLines8 = validate.maxLines(8);

const Gift = ({
  classes,
  isGift,
  method
}) => {
  const conditionalGiftValidation = fns => isGift ? fns : [];

  return (
    <div
      className={joinClasses(
        classes.container,
        (method === DELIVER || method === PICKUP_FEDEX) ? 'is-active' : 'is-disabled'
      )}
    >
      <p className={joinClasses('h6', classes.subHeadline)}>
        Is This a Gift?
      </p>

      <FormControl className={joinClasses(classes.gift, 'copy--large')}>
        <Field
          name="isGift"
          normalize={value => value === 'true'}
        >
          <RadioGroup
            aria-label="gift options"
            row
          >
            <FormControlLabel
              control={<Radio color="primary" value="false" checked={!isGift} />}
              label="This is not a gift"
              labelPlacement="end"
            />
            <FormControlLabel
              control={<Radio color="primary" value="true" checked={isGift} />}
              label="This is a gift"
              labelPlacement="end"
            />
          </RadioGroup>
        </Field>
      </FormControl>
      <div
        className={joinClasses(
          classes.section,
          classes.giftOptions,
          isGift ? 'is-active' : ''
        )}
      >
        <FormControl
          className={joinClasses(classes.giftInfo, classes.formControl)}
        >
          <Field name="giftSenderName" validate={conditionalGiftValidation([validate.required, validate40])}>
            <TextField
              label="Gift Sender's Name (40 character max)"
              required
              sdwinput
            />
          </Field>
        </FormControl>

        <FormControl
          className={joinClasses(classes.giftInfo, classes.formControl)}
        >
          <Field name="giftName" validate={conditionalGiftValidation([validate.required, validate50])}>
            <TextField
              label="Gift Recipient's Name (50 character max)"
              required
              sdwinput
            />
          </Field>

        </FormControl>

        <FormControl
          className={joinClasses(classes.giftInfo, classes.formControl)}
        >
          <Field name="giftRecipientEmail" type="email">
            <TextField
              label="Gift Recipient's Email"
              sdwinput
            />
          </Field>
        </FormControl>

        <FormControl
          className={classes.instructions}
        >
          <Field name="giftNote" validate={conditionalGiftValidation([validate300, maxLines8])}>
            <TextField
              placeholder="Include a message for recipient. (8 line limit)"
              sdwinput
              type="text"
              multiline
              rows={8}
              disableUnderline
            />
          </Field>
        </FormControl>
      </div>
    </div>
  )
}

export default Gift;