
import { createStyles } from '@material-ui/core';
import { grid } from '../../utils/mixins';

export default theme => createStyles({
	container: {
		display: 'flex',
		alignItems: 'flex-start',
		[theme.breakpoints.down("sm")]: {
			flexDirection: 'column',
			width: '100%',
		}
	},
	
	form: {
		width: grid(7, true),
		[theme.breakpoints.down("sm")]: {
			width: '100%',
		}
	}
});