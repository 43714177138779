import React from "react";
import ReactModal from "react-modal";
import { withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import close from "../../../../assets/vectors/Close.svg";
import Button from "../../../bits/Shared/Button";
import CheckedIcon from '../../../bits/Shared/Icons/Checked';
import UncheckedIcon from '../../../bits/Shared/Icons/Unchecked';
import styles from "./styles";

ReactModal.setAppElement(document.getElementById("root"));

const AccountModal = ({ classes, isOpen, onClose }) => {


  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const closeModal = e => {
    e.preventDefault();
    onClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={classes.container}
      overlayClassName="react-modal"
    >
      <h5 className={classes.heading}>
        Create An Account
      </h5>
      <p>Passwords should contain a minimum of 8 characters.</p>
      <TextField
        label="Password"
        value={password}
        type="password"
        className={classes.row}
        onChange={event => setPassword(event.target.value)}
        required
      />
      <TextField
        label="Confirm Password"
        value={confirmPassword}
        type="password"
        className={classes.row}
        onChange={event => setConfirmPassword(event.target.value)}
        required
      />
      <FormControlLabel
        className={classes.row}
        control={(
          <Checkbox
            color="secondary"
            icon={<CheckedIcon />}
            checkedIcon={<UncheckedIcon />}
          />
        )}
        label="Save my payment information"
      />
      <Button
        onClick={console.log}
        className={classes.button}
        title="Create An Account"
      />
      <button type="button" onClick={e => closeModal(e)} className={classes.close}>
        <img src={close} alt="Close overla" />
      </button>
    </ReactModal>
  );
};

export default withStyles(styles)(AccountModal);
