import { createStyles } from "@material-ui/core";
import { grid, vw } from "../../../../utils/mixins";

export default theme =>
  createStyles({
    container: {
      // backgroundColor: theme.colors.background.primary,
      marginBottom: 'var(--spacer-module-default)',
      paddingTop: theme.heights.nav.desktop,
      marginTop: '-12rem',
      width: '100%'
    },
    content: {
      padding: '2rem 0 4rem',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignItems: 'center',
        padding: '4.5rem 0'
      }
    },
    imageContainer: {
      marginBottom: '2rem',
      [theme.breakpoints.up('sm')]: {
        order: 2,
        width: grid(6),
        marginBottom: 0
      },

      '& img': {
        display: 'block'
      }
    },
    textContainer: {
      [theme.breakpoints.up('sm')]: {
        order: 1,
        width: grid(6, true),
      },
      [theme.breakpoints.up('lg')]: {
        padding: `0 ${grid(1, true)}`
      }
    },
    eyebrow: {
      color: '#7E7E7E'
    },
    heading: {
      maxWidth: '7em',
      margin: '1.5rem 0',
      [theme.breakpoints.up('sm')]: {
        margin: '3.5rem 0 2.5rem'
      }
    },
    bodyText: {
      color: '#646464',

      '& strong': {
        display: 'block',
        fontWeight: 500
      }
    }
  });
