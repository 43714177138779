import { createStyles } from '@material-ui/core';
import { vw, grid } from '../../../../utils/mixins';

export default (theme) => createStyles({
  wrapperContainer: {
		marginBottom: vw(50)
  },
	wrapper: {
		borderBottom: `1px solid ${theme.colors.border}`,
		[theme.breakpoints.down("sm")]: {
			borderBottom: 'none',
			marginBottom: 0,
		}
	},
	container: {
		position: 'relative',
		[theme.breakpoints.down("sm")]: {
			backgroundColor: theme.colors.primary,
			margin: 0,
			padding: `0 10px`,
			height: '60px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			flexDirection: 'row',
			width: '100vw',
		}
	},
	logoDivider: {
		width: '1px',
		backgroundColor: 'black',
		position: 'absolute',
		top: '50%',
		height: vw(38),
		marginTop: `calc(${vw(38)} / -2)`,
		left: `calc(var(--grid-padding) + ${vw(108)} + 15px)`,
		[theme.breakpoints.down("sm")]: {
			top: 'initial',
			marginTop: 'initial',
			marginLeft: '10px',
			marginRight: '10px',
			height: '26px',
			position: 'relative',
			left: 0,
			backgroundColor: 'white',
		},
		[theme.breakpoints.up("xl")]: {
			height: '45px',
			marginTop: '-24px',
			left: `calc(var(--grid-padding) + 148px + 20px + 1px)`,
		},
	},
	logo: {
		position: 'absolute',
		top: '50%',
		left: 'var(--grid-padding)',
		width: vw(108),
		maxWidth: '148px',
		height: '2.2rem',
		marginTop: '-1.1rem',
		[theme.breakpoints.down("sm")]: {
			top: 'initial',
			width: '82px',
			marginTop: 'initial',
			height: 'initial',
			position: 'relative',
			left: 0
		},
		[theme.breakpoints.up("xl")]: {
			width: '148px',
			marginTop: '-18px',
		},
		'& img': {
			width: '100%',
			height: 'auto',
		},
	},
	logoTW: {
		position: 'absolute',
		top: '53%',
		left: `calc(var(--grid-padding) + ${vw(108)} + 15px + 1px + 15px)`,
		width: vw(85),
		maxWidth: '125px',
		height: '2.2rem',
		marginTop: '-1.1rem',
		[theme.breakpoints.up("xl")]: {
			left: `calc(var(--grid-padding) + 148px + 20px + 1px + 20px)`,
			width: '125px',
			marginTop: '-15px',
		},
		[theme.breakpoints.down("sm")]: {
			top: 'initial',
			width: '62px',
			marginTop: 'initial',
			height: 'initial',
			position: 'relative',
			left: 0
		},
		'& img': {
			width: '100%',
			height: 'auto',
		},
	},
	nav: {
		[theme.breakpoints.down("sm")]: {
			display: 'none'
		}
	},
	navList: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		counterReset: 'checkout-nav-counter',
    marginLeft: '60px'
	},
	navListItem: {
		margin: '0 1.9rem'
	},
	navListLink: {
		display: 'block',
		padding: '4rem 0',
		opacity: '0.2',
		borderBottom: `0.2rem solid ${theme.colors.transparent}`,
		'&:before': {
			counterIncrement: 'checkout-nav-counter',
			content: 'counter(checkout-nav-counter, decimal-leading-zero) ". "'
		}
	},
	navListLinkActive: {
		fontWeight: '500',
		opacity: '1',
		borderBottomColor: 'inherit'
	},
	showMobile: {
		display: "none!important",
		[theme.breakpoints.down("sm")]: {
			display: "block!important",
			marginLeft: "auto"
		}
	},
	hideMobile: {
		display: "block!important",
		[theme.breakpoints.down("sm")]: {
			display: "none!important",
		}
	},
	mobileTotal: {
		display: 'flex',
		flexDirection: 'row',
		color: theme.colors.white,
		'& span': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'&:last-child': {
				backgroundColor: theme.colors.secondary,
				marginLeft: '10px',
				borderRadius: '30px',
				width: '30px',
				height: '30px',
			}
		},
	},
	steps: {
		color: 'black',
		backgroundColor: theme.colors.background.tertiary,
		padding: `1.5rem ${grid(1, true)}`,
	},
});
