import React from "react";
import ReactModal from "react-modal";
import { withStyles } from "@material-ui/styles";
import close from "../../../../assets/vectors/Close.svg";
import Button from "../../../bits/Shared/Button";
import TextField from "@material-ui/core/TextField";
import styles from "./styles";
import fetchDux from '../../../../store/reducers/fetch-dux';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Loader from '../../../bits/Shared/Loader';

ReactModal.setAppElement(document.getElementById("root"));

const PromoCode = ({
  classes,
  isOpen,
  onClose,
  postPromoCode,
  delPromoCode,
  inProgress,
  cart,
  postPromoCodeResult,
  clearPostPromoCode
}) => {

  const [code, setCode] = React.useState("");

  const closeModal = e => {
    e.preventDefault();
    onClose(code);
  };

  const submitPromoCode = () => {
    if (!code) return;
    postPromoCode({ promoCode: code });
  }

  const deletePromoCode = () => {
    delPromoCode();
    clearPostPromoCode();
  }

  const { promoCode } = cart || {};
  const hasPromo = !!promoCode;

  const{
    valid,
    reason
  } = postPromoCodeResult || {};

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={classes.container}
      overlayClassName="react-modal"
    >
      <h5 className={classes.heading}>
        Enter a Code
      </h5>
      {inProgress && (
        <Loader />
      )}
      {!hasPromo && !inProgress && (
        <>
          <TextField
            label="Promo Code"
            value={code}
            type="text"
            className={classes.row}
            onChange={event => setCode((event.target.value || '').substring(0, 50))}
            required
          />
          <Button
            onClick={submitPromoCode}
            className={classes.button}
            title="Apply Code"
          />
          { valid === false && 
            reason &&
            <p style={{color:'red'}}>
              <br/>
              {reason}
            </p>
          }
        </>
      )}
      {hasPromo && !inProgress && (
        <>
          <TextField
            label="Promo Code"
            value={promoCode}
            type="text"
            className={classes.row}
            required
            disabled
          />
          <p>
            Promo code successfully applied!
            <span onClick={deletePromoCode} style={{ float: 'right', color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>Delete Code</span>
          </p>
          <Button
            onClick={closeModal}
            className={classes.button}
            title="Close"
          />
        </>
      )}

      <button type="button" onClick={closeModal} className={classes.close}>
        <img src={close} alt="Close" />
      </button>
    </ReactModal>
  );
};

const mapState = state => {
  const {
    inProgress,
    result:postPromoCodeResult
  } = state.fetchReducer.postPromoCode.toJS();
  const {
    inProgress: delInProgress
  } = state.fetchReducer.delPromoCode.toJS();
  const {
    result: cart,
  } = state.fetchReducer.getCart.toJS();
  return {
    cart,
    inProgress: inProgress || delInProgress,
    postPromoCodeResult
  }
}

const mapDispatch = dispatch => bindActionCreators({
  postPromoCode: fetchDux.postPromoCode.createAction,
  clearPostPromoCode: fetchDux.postPromoCode.clearAction,
  delPromoCode: fetchDux.delPromoCode.createAction
}, dispatch);

const StyledApp = withStyles(styles)(PromoCode);

export default connect(mapState, mapDispatch)(StyledApp);
