import React from "react";
import ReactModal from "react-modal";
import { withStyles } from "@material-ui/styles";
import close from "../../../../../assets/vectors/Close.svg";

import Button from "../../../../bits/Shared/Button";
import PaymentMethodForm from "../../../../modules/Forms/PaymentMethod";
import { joinClasses } from "../../../../../utils";
import styles from "./styles";

ReactModal.setAppElement(document.getElementById("root"));

const PaymentMethodModal = ({ classes, isOpen, onClose, setNewCard, cardInvalid, isBulk }) => {
  const closeModal = e => {
    e.preventDefault();
    onClose();
  };
  const [submitted, setSubmitted] = React.useState(false);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={classes.container}
      overlayClassName="react-modal"
    >
      <p className={joinClasses("h5", classes.heading)}>Enter a New Payment Method</p>
      <PaymentMethodForm savePaymentAllowed cardInvalid={cardInvalid} submitted={submitted} isBulk={isBulk}/>

      <Button
        className={classes.button}
        title="Use Payment Method"
        onClick={e => {
          setSubmitted(true);
          if (cardInvalid) return;
          setNewCard()
          closeModal(e)
        }}
      />
      <button
        type="button"
        onClick={e => {
          closeModal(e)
        }}
        className={classes.close}
      >
        <img src={close} alt="Close overlay" />
      </button>
    </ReactModal>
  );
};

export default withStyles(styles)(PaymentMethodModal);
