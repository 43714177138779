/**
 * Tock Reservation Button (exploretock.com)
 *
 * The following reservation button uses Tock script to render
 * widget in modal on click.
 *
 * NOTE in `render()` method click handler:
 *
 * tock?.showWidgetForElement [ln:48]
 *
 * This is a private API method and may change in the future,
 * possibly being a breaking change. See SIL-212.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { joinClasses } from '../../../../utils';
import { loader } from './loader';
import styles from './styles';

const ReservationButton = ({ classes, className, data, inverse }) => {
  const [atts, setAtts] = useState({});

  const classInverse = inverse ? classes.inverse : null;

  const createMarkup = (content) => {
    return {__html: content};
  };

  // Use mutation observer to detect load/unload of Tock widget
  const { body } = document;
  const config = { childList: true };

  const callback = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        for (const node of mutation.addedNodes) {
          if (node.id === 'TockWidget') {
            node.insertAdjacentHTML('beforeend', loader);
          }
        }
      }
    }
  };

  const observer = new MutationObserver(callback);

  const onClick = (e) => {
    observer.observe(body, config);

    const href = e.currentTarget.getAttribute('href');
    const urls = [];

    // if (tock?.tockUrl[0].url !== href) {
    //   tock.tockUrl.forEach((entry) => {
    //     if (entry.url === href) {
    //       urls.unshift(entry);
    //     } else {
    //       urls.push(entry);
    //     }
    //   });

    //   tock.tockUrl = urls;
    // }

    // tock?.showWidgetForElement(e);
  };

  useEffect(() => {
    const newAtts = {};
    newAtts.href = data?.widget_href ? data.widget_href : 'https://www.exploretock.com/silveroakwineryalexandervalley';
    newAtts['data-tock-reserve'] = true;
    if (data?.experience_list) {
      newAtts['data-tock-experience-list'] = data.experience_list;
    }
    if (data?.experience_id) {
      newAtts['data-tock-experience'] = data.experience_id;
    }
    setAtts(prevAtts => ({
      ...prevAtts,
      ...newAtts
    }));

    return () => {
      if (observer) observer.disconnect();
    }
  },[]);

  return (
    <a
      onClick={onClick}
      {...atts}
      className={joinClasses(
        classes.button,
        classInverse,
        className
      )}
      dangerouslySetInnerHTML={createMarkup(data?.text || 'Book Now')}
    />
  )
};

ReservationButton.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    btnInverse: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string,
  inverse: PropTypes.bool
};

ReservationButton.defaultProps = {
  className: "",
  inverse: false
};

const StyledApp = withStyles(styles)(ReservationButton);

export default StyledApp;
