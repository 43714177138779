import { createStyles } from '@material-ui/core';
import { grid, vw } from '../../../../../utils/mixins';

export default theme =>
  createStyles({

    fedexSelectedLocation: {
      width: grid(5),
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
    },

    FedexAddress: {
      width: grid(3),
      [theme.breakpoints.down('sm')]: {
        width: grid(4),
      },
    },

    pickupperson: {
      width: grid(2),
      [theme.breakpoints.down('sm')]: {
        width: grid(4),
      },
    },

    dBlock: {
      display: 'block',
      color: theme.colors.tertiary
    },
    button2: {
      width: grid(4),
      // width: "unset",
      padding: '1.2rem',
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      }
    }
  });
