import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { joinClasses, formatUrl } from '../../../../utils';
import styles from './styles';

const Button = ({ classes, title, className, url, inverse, underlined = true, ...restProps }) => {
  const allClasses = joinClasses(
    inverse ? classes.btnInverse : classes.button,
    className,
    underlined && inverse ? classes.underlined : null
  );
  if (url) {
    return (
      <Link
        to={formatUrl(url)}
        className={allClasses}
        {...restProps}
        dangerouslySetInnerHTML={{ __html: `
        <div class="btn-inner">
            ${title}
            <figure>
            <svg viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 4.61398H17.4818M17.4818 4.61398L13.6886 0.933594M17.4818 4.61398L13.6886 8.29437"></path></svg>
          </figure>
        </div>
      ` }}
      />
    )
  }
  return (
    <button
      className={allClasses}
      type='button'
      dangerouslySetInnerHTML={{ __html: `
        <div class="btn-inner">
            ${title}
            <figure>
            <svg viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 4.61398H17.4818M17.4818 4.61398L13.6886 0.933594M17.4818 4.61398L13.6886 8.29437"></path></svg>
          </figure>
        </div>
      ` }}
      {...restProps}
    />
  )


};

Button.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    btnInverse: PropTypes.string.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  inverse: PropTypes.bool,
  onClick: PropTypes.func
};

Button.defaultProps = {
  className: '',
  inverse: false
};

const StyledApp = withStyles(styles)(Button);

export default StyledApp;
