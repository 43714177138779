import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import CheckoutNav from "../components/bits/Checkout/CheckoutNav";
import CheckoutFooter from "../components/bits/Checkout/CheckoutFooter";
import { joinClasses } from "../utils";
import Loader from '../components/bits/Shared/Loader';
import styles from "./styles";

const ConfirmationLayout = ({ children, classes, inProgress, id, isClubJoin, isBulk, showBulkConfirmation }) => (
  <div>
    <CheckoutNav id={id} style={{ marginBottom: '0' }} isClubJoin={isClubJoin} isBulk={isBulk} showBulkConfirmation={showBulkConfirmation} />
    <div className={joinClasses(classes.checkoutContainer)}>
      <div style={{ width: '100%' }}>
        {!inProgress && children}
        {inProgress && <Loader />}
      </div>
    </div>
    <CheckoutFooter style={{ marginTop: '0' }} />
  </div>
);

ConfirmationLayout.propTypes = {
  classes: PropTypes.shape({
    checkoutContainer: PropTypes.string.isRequired,
    checkoutForm: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(styles)(ConfirmationLayout);
