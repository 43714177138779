import React from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/styles";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ArrowBlack from "../../../../assets/vectors/ArrowBlack.svg";
import ArrowWhite from "../../../../assets/vectors/ArrowWhite.svg";
import { joinClasses } from "../../../../utils";
import styles from "./styles";

const getLabel = ({ label, required }) => {
  if (!label) return false;
  if (!required) return label;
  return <span><span className="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span> {label}</span>
}

const SelectWithArrow = ({
  classes,
  className,
  darkMode,
  inverse,
  noArrow,
  required,
  autocomplete,
  autoposition,
  options,
  label,
  ...props
}) => {
  const arrow = inverse || darkMode ? ArrowWhite : ArrowBlack;
  const selectStyles = inverse || darkMode ? classes.selectInverse : "";
  const noArrowStyles = noArrow ? classes.noArrowSelect : "";
  const isRequired = required ? classes.required : null;

  if (autocomplete) {
    return (
      <Autocomplete
        className={joinClasses(classes.root, selectStyles, noArrowStyles, className)}
        options={(options || [])}
        getOptionLabel={(o) => {
          if (typeof o === 'object') return o.label || '';
          const { label } = (options || []).find(({ value }) => value === o) || {};
          return label || '';
        }}
        getOptionSelected={(option, value) => {
          return parseInt(option.value) === parseInt(value)
        }}
        onChange={(e, selected) => {
          props.onChange(selected ? selected.value : null);
        }}
        onBlur={e => {
          props.onBlur(props.value)
        }}
        onFocus={e => {
          props.onFocus(props.value)
        }}
        value={props.value}
        renderInput={params => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password'
            }}
            fullWidth
            label={getLabel({ label, required })}
            error={!!props.error}
            helperText={props.helperText}
          />
        )}
      />
    )
  }
  return (
    <Select
      className={joinClasses(classes.root, selectStyles, noArrowStyles, className, isRequired)}
      onOpen={() => {document.body.classList.add('is-locked')}}
      onClose={() => {document.body.classList.remove('is-locked')}}
      IconComponent={() =>
        !noArrow ? (
          <img src={arrow} className={classes.arrowIcon} alt="arrow" />
        ) : null}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center"
        },
        marginThreshold: !autoposition ? -9999 : 16
      }}
      {...props}
    />
  );
};

SelectWithArrow.propTypes = {
  classes: PropTypes.shape({
    arrowIcon: PropTypes.string.isRequired
  }).isRequired,
  required: PropTypes.bool,
};

SelectWithArrow.defaultProps = {
  required: false
}

const StyledApp = withStyles(styles)(SelectWithArrow);

export default StyledApp;
