import { createStyles } from '@material-ui/core';

import { grid, vw } from '../../../../utils/mixins';

export default theme =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    heading: {
      marginBottom: vw(30)
    },
    textField: {
      marginBottom: '4rem'
    },
    rowContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: vw(30),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '100%',
      },
      '& > *': {
        marginRight: vw(30),
        '&:last-child': {
          marginRight: 0,
        }
      },
      '& input:last-child': {
        [theme.breakpoints.up('sm')]: {
          height: vw(30),
        },
      }
    },
    cardNumber: {
      width: grid(5),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    month: {
      width: grid(2, false),
      marginRight: '2rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%',
      },
    },
    year: {
      width: grid(1, false),
      marginRight: '2rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%',
      },
    },
    cvv: {
      width: grid(2, false),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    icons: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        margin: '3rem 0',
      },

      '& img': {
        maxWidth: '13rem'
      }
    },
    saveAddress: {
      marginTop: '3.5rem'
    }
  });
