import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Plus = ({reverse = false, className, props}) => {
  const color = reverse ? "white" : "#141414";

  return (
    <SvgIcon
      className={className}
      {...props}
    >
      <rect x="0.5" y="0.5" width="16" height="16" rx="8" stroke={color} />
      <rect x="8" y="4" width="1" height="9" fill={color} />
      <rect x="13" y="8" width="1" height="9" transform="rotate(90 13 8)" fill={color} />
    </SvgIcon>
  )};

export default Plus;