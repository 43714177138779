import React from "react";
import ReactModal from "react-modal";
import { withStyles } from "@material-ui/styles";
import close from "../../../../assets/vectors/Close.svg";
import { joinClasses } from "../../../../utils";
import styles from "./styles";

ReactModal.setAppElement(document.getElementById("root"));

const WhereWeShipModal = ({ 
  classes, 
  isOpen, 
  onClose, 
  inProgress,
  states,
}) => {

  const closeModal = e => {
    onClose();
  };

  let stateOptions = (states || [])
  .filter(({offSiteShipping, countryId})=>offSiteShipping && countryId === 37)
  .map(({stateName}) => stateName)
  .sort((a,b) => {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  })

  const numStates = stateOptions.length
  const statesPerRow = Math.floor(numStates / 4)
  const stateGroup1 = stateOptions.splice(0, statesPerRow)
  const stateGroup2 = stateOptions.splice(0, statesPerRow)
  const stateGroup3 = stateOptions.splice(0, statesPerRow)
  const stateGroup4 = stateOptions

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={classes.container}
      overlayClassName="react-modal"
    >
      <p className={joinClasses("h5", classes.heading)}>
         {`States We Ship To`}
      </p>
      <p className={classes.advise} >If you have any additional questions please contact us at: (888) 202-2006</p>
      <div className={classes.lists}>
        <ul style={{float:'left', width:'25%'}}>
          {stateGroup1.map((v, index) => {
            return(<li key={index}>{v}</li>)
          })}
        </ul>
        <ul style={{float:'left', width:'25%'}}>
          {stateGroup2.map((v, index) => {
            return(<li key={index}>{v}</li>)
          })}
        </ul>
        <ul style={{float:'left', width:'25%'}}>
          {stateGroup3.map((v, index) => {
            return(<li key={index}>{v}</li>)
          })}
        </ul>
        <ul style={{float:'left', width:'25%'}}>
          {stateGroup4.map((v, index) => {
            return(<li key={index}>{v}</li>)
          })}
        </ul>
      </div>

      {/* <Button
        onClick={closeModal}
        className={classes.button}
        title={"Okay"}
      /> */}
      <button type="button" onClick={closeModal} className={classes.close} disabled={inProgress}>
        <img src={close} alt="Close overlay" />
      </button>
    </ReactModal>
  );
};

export default withStyles(styles)(WhereWeShipModal);
