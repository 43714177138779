
const actionNamespace = 'silveroak-fetch'
const SET_BILLING = `${actionNamespace}/SET_BILLING`;
const CLEAR_BILLING = `${actionNamespace}/CLEAR_BILLING`;

const defaultState = null;

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_BILLING:
      return payload;
    case CLEAR_BILLING:
      return defaultState;
    default:
      return state;
  }
};

export const actionCreators = {
  setBilling: payload => ({ type: SET_BILLING, payload }),
  clearBilling: () => ({ type: CLEAR_BILLING })
};
