import overrides from './overrides';

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      smx: 720,
      md: 1024,
      mdx: 1280,
      lg: 1440,
      xl: 1500
    },
    keys: ['xs', 'sm', 'smx', 'md', 'mdx', 'lg']
  },
  colors: {
    primary: '#2F2F2E',
    secondary: '#646464',
    tertiary: '#7E7E7E',
    quaternary: '#CBCBCB',
    white: '#FFFFFF',
    black: '#000000',
    offBlack: '#161616',
    dark: '#202124',
    red: 'red',
    alert: '#990000',
    border: '#D0D0D0',
    borderSecondary: '#9B9B9B',
    transparent: 'transparent',
    gray64: '#646464',
    gray6A: '#6A6A6A',
    grayFA: '#FAF8F5',
    background: {
      primary: '#F2F0EC',
      secondary: '#EFECE8',
      tertiary: '#EDEAE6',
      quaternary: '#141414',
      zero: '#FAF8F5',
      grey: '#D5D3CF',
      account: '#333333',
      wildSand: '#E8E6E2'
    },
    body: {
      table: {
        rows: '#3D3D3D',
        headCells: '#AFAFAF',
      }
    }
  },
  heights: {
    nav: {
      desktop: '12rem',
      tablet: '8rem',
      mobile: '6rem'
    },
  },
  transitions: {
    all: 'all .25s var(--ease-out-jim)',
    hover: {
      all: 'all .25s var(--ease-out-jim)',
      background: 'background-color .25s var(--ease-out-jim)',
      border: 'border-color .25s var(--ease-out-jim)'
    },
    opacity: 'opacity .25s var(--ease-out-jim)',
    backgroundColor: 'background-color .15s linear',
    color: 'color .15s linear',
    fill: 'fill .15s linear',
    transform: 'transform .15s linear',
    top: 'top .15s linear'
  },
  typography: {
    roboto: {
      default: 'Roboto, sans-serif',
      light: 'Roboto-Light',
      regular: 'Roboto-Regular',
      medium: 'Roboto-Medium',
      bold: 'Roboto-Bold'
    },
    gtSuper: {
      default: 'GT Super Display, serif',
      light: 'GT-Super-Display-Light',
      regular: 'GT-Super-Display-Regular',
      medium: 'GT-Super-Display-Medium',
      bold: 'GT-Super-Display-Bold'
    }
  },
  z: {
    header: 900,
    mainNav: 1200,
    top: 1000,
  },
  spacing: factor => `${0.3125 * factor}rem`,
  overrides
};

export default theme;
