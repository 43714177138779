import React from 'react';
import { withStyles } from '@material-ui/styles';
import { joinClasses } from '../../../../../utils';
import styles from './styles';
import Button from "../../../../bits/Shared/Button";

const SelectedFedexLocation = ({
  classes,
  selectedFedexLocation,
  pickUpPerson,
  onChangeSelectedFDXLocation,
  initialValues
}) => {

  const { name_first, name_last, phone } = pickUpPerson || initialValues || {};
  const { address, contact } = selectedFedexLocation || {};
  const { city, streetLines, postalCode, stateOrProvinceCode } = address || {}
  const { companyName, phoneNumber } = contact || {}
  if (pickUpPerson) {
    return (
      <div>
        <div className={classes.fedexSelectedLocation}>
          <div className={classes.FedexAddress}>
            <p>Fedex Pick Up Address</p>
            <p>
              <span className={classes.dBlock}>{companyName}</span>
              <span className={classes.dBlock}>{streetLines}</span>
              <span className={classes.dBlock}>{city} {stateOrProvinceCode} {postalCode}</span>
              {phoneNumber ? <span className={classes.dBlock}>Phone number: {phoneNumber}</span> : ""}
            </p>
          </div>
          <div className={classes.pickupPerson}>
            <p>Recipient</p>
            <p>
              <span className={classes.dBlock}> {name_first} {name_last}</span>
              <span className={classes.dBlock}>{phone}</span>
            </p>
          </div>
        </div>
        <Button
          // disabled={inProgress}
          className={classes.button2}
          title="Change Location"
          onClick={onChangeSelectedFDXLocation}
        />
      </div>

    )
  } else {
    return (
      <div
        className={joinClasses(classes.shippingOption, 'is-active')}
      >
        <div className={joinClasses('grid', classes.section, classes.noMargin, 'copy--large')}>
          <div className={classes.pickupAddress}>
            <p>Fedex Pick Up Address</p>
            <p>
              <span className={classes.dBlock}>{(initialValues || {}).company}</span>
              <span className={classes.dBlock}>{(initialValues || {}).address1}</span>
              <span className={classes.dBlock}>{(initialValues || {}).address2}</span>
              <span className={classes.dBlock}>{(initialValues || {}).city} {(initialValues || {}).stateShort} {(initialValues || {}).zip}</span>
              {phoneNumber ? <span className={classes.dBlock}>Phone number: {phoneNumber}</span> : ""}
            </p>
          </div>

          <div className={classes.pickupPhone}>
            <p>Recipient</p>
            <p>
              <span className={classes.dBlock}> {name_first} {name_last}</span>
              <span >{phone}</span>
            </p>
          </div>

          <Button
            // disabled={inProgress}
            className={classes.button2}
            title="Change location"
            onClick={onChangeSelectedFDXLocation}
          />
        </div>
      </div>
    )

  }

};
export default withStyles(styles)(SelectedFedexLocation);
