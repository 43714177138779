import React from "react";
import ReactModal from "react-modal";
import { withStyles } from "@material-ui/styles";
import styles from "./styles";
import Loader from '../../../bits/Shared/Loader';

ReactModal.setAppElement(document.getElementById("root"));

const LocationLoading = ({
  classes
}) => {
  return (
    <ReactModal
      isOpen={true}
      className={classes.container}
      overlayClassName="react-modal"
    >
        <div className={classes.loading}>
          <Loader />
          <div className={classes.loadingText}>Loading locations, please wait</div>
        </div>
    </ReactModal>
  );
};
export default withStyles(styles)(LocationLoading);
