import React, { Component, cloneElement } from 'react';
import { reduxForm, Form as FormRedux, Field as FieldRedux } from 'redux-form';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import FormHelperText from '@material-ui/core/FormHelperText';
import moment from 'moment'

const getProps = ({ input, error, helperText, checkbox, onChange, normalizeOnBlur }) => {
  const onBlur = e => {
    const { value } = e.target || {};
    if (normalizeOnBlur) return input.onBlur(normalizeOnBlur(value));
  };
  if (checkbox) return {
    ...input,
    checked: input.value === true,
    error,
    helperText,
    onChange: onChange || input.onChange,
    onBlur
  }
  return {
    ...input,
    error,
    helperText,
    onChange: onChange || input.onChange,
    onBlur
  }
}
class FieldComponent extends Component {
  shouldComponentUpdate(nextProps) {
    return true
    // const { input: { value }} = this.props;
    // const { input: { value: v }} = nextProps;
    // return v !== value
  }
  render() {
    const {
      checkbox,
      onChange,
      input,
      meta,
      sdwChild,
      normalizeOnBlur,
      errorOverride
    } = this.props;

    const{
      status,
      message
    } = errorOverride || {};

    const { touched, error: err, warning } = meta || {};
    const error = status || (touched && (!!err || !!warning)) || undefined;
    const helperText = status ? message : (error ? err || warning : null)
    const props = getProps({ input, error, helperText, checkbox, onChange, normalizeOnBlur });
    return cloneElement(sdwChild, props);
  }
}
export class Field extends Component {
  render() {
    const {
      children,
      ...rest
    } = this.props;
    if (Array.isArray(children)) return React.Children.map(children, child => {
      const { props } = child || {};
      const { sdwinput, sdwlabel, sdwerror } = props || {};
      if (!sdwinput && !sdwlabel && !sdwerror) return child;
      return (
        <FieldRedux
          {...rest}
          sdwChild={child}
          component={FieldComponent}
        />
      )
    })
    return (
      <FieldRedux
        {...rest}
        sdwChild={children}
        component={FieldComponent}
      />
    )
  }
}

class FieldLabelComponent extends Component {
  render() {
    const {
      meta,
      sdwChild
    } = this.props
    const { touched, error: err, warning } = meta || {};
    const error = touched && (!!err || !!warning);
    return cloneElement(sdwChild, { error })
  }
}

export class FieldLabel extends Component {
  render() {
    const {
      children,
      ...rest
    } = this.props;
    if (Array.isArray(children)) return React.Children.map(children, child => {
      const { props } = child || {};
      const { sdwinput } = props || {};
      if (!sdwinput) return child;
      return (
        <FieldRedux
          {...rest}
          sdwChild={child}
          component={FieldLabelComponent}
        />
      )
    })
    return (
      <FieldRedux
        {...rest}
        sdwChild={children}
        component={FieldLabelComponent}
      />
    )
  }
}
export const FieldError = ({
  error,
  helpertext
}) => {
  if (!error) return false;
  return <FormHelperText error>{helpertext}</FormHelperText>;
}
const FormConstructor = ({
  children,
  handleSubmit
}) => {
  return (
    <FormRedux onSubmit={handleSubmit} noValidate>
      {children}
    </FormRedux>
  )
}
export const Form = reduxForm()(FormConstructor)
export const validate = {
  required: value => !value ? 'Required' : undefined,
  requiredNumber: value => value !== 0 && value !== '0' && !value ? 'Required' : undefined,
  requiredPhoneNum: value => ((value|| '').replace(/[^\d]/g, '') .length || '') < 10  ?  'Please enter a valid phone number' : undefined,
  characters: num => value => (value || '').length > num ? `Must not exceed ${num} characters` : undefined,
  maxLines: num => (value) => {

    const lines = (value || "").split(/\r|\r\n|\n/);
    const lineCount = lines.length
  
    return lineCount > num
    ? `Gift message must be less than ${num} lines`
    : undefined
  
  },
  validDate: value =>
    (value &&
    (value || '').length === 10 &&
    moment(value).isValid() &&
    moment(value).isBefore(moment().endOf('day')))
    ? undefined
    : 'Invalid',
  validDate21: value =>
    (value &&
    moment(value).isValid() &&
    moment(value).isBefore(moment().subtract(21, 'years')))
    ? undefined
    : 'Must be 21 years of age',
  validEmail: (email) => {
    const regexp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (regexp.test(email)) {
      return undefined;
    }
      return 'Please enter a valid email address';
  }
}

export const phoneMask = createTextMask({
  pattern: ' (999) 999-9999',
  placeholder: ' ',
  allowEmpty: true,
  guide: false,
});
