import React, { Component } from 'react';
import { SEO } from '../../../bits';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, change, isInvalid, hasSubmitFailed, getFormSyncErrors, getFormAsyncErrors } from 'redux-form';
import Carts from './Carts';
import SavedAddresses from '../Shipping/SavedAddresses'
import SavedCards from '../Payment/SavedCards';
import Button from '../../../bits/Shared/Button';
import { getPollingStatus, postCompleteBulkOrders } from '../../../../store/reducers/fetchDux/api.js';
import { joinClasses } from '../../../../utils';
import { Form } from '../../../../utils/forms';
import { getInitAddressId } from '../../../../utils/checkout';
import CheckoutLayout from '../../../../layouts/checkoutBulk';
import CheckoutLayoutConfirmation from '../../../../layouts/confirmation';
import fetchDux from '../../../../store/reducers/fetch-dux';
import { actionCreators } from '../../../../store/reducers/billingReducer';
import { actionCreators as accountActionCreators } from '../../../../store/reducers/accountReducer';
import styles from '../styles';
import queryString from 'query-string';
import Loader from '../../../bits/Shared/Loader/index.js';
import BulkConfirmation from './BulkConfirmation';

const FORM_NAME = 'billing-form';

const getInitialAddressValues = ({ AddressBook }) => {

  const {
    AddressBookID,
    FirstName: name_first,
    LastName: name_last,
    Email: email,
    Company: company,
    Address1: address1,
    Address2: address2,
    City: city,
    Zip: zip,
    Phone: phone,
    StateID: stateId,
    CountryID: countryId
  } = (AddressBook || []).find(({ IsDefaultMailing }) => IsDefaultMailing) || {};

  return {
    addressBookId: AddressBookID || '',
    name_first,
    name_last,
    email,
    company,
    address1,
    address2,
    city,
    zip,
    phone,
    stateId,
    countryId
  };
};
const getInitialCardId = ({ CreditCards }) => {

  const defaultCard = (CreditCards || []).find(({ IsDefault }) => IsDefault);

  const { CardId } = defaultCard || {};

  return CardId || null;
};
const getInitialValues = (
  { AddressBook, CreditCards }
) => {

  const {
    addressBookId,
    name_first,
    name_last,
    company,
    address1,
    address2,
    city,
    zip,
    phone,
    stateId,
    countryId
  } = getInitialAddressValues({ AddressBook });

  const cardId = getInitialCardId({ CreditCards });

  return {
    addressBookId,
    name_first,
    name_last,
    company,
    address1,
    address2,
    city,
    zip,
    phone,
    stateId,
    countryId: countryId || 37,
    cardId: cardId || '',
  };
};

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noAddress: false,
      PaymentStatusActiveLoading: false,
      showBulkConfirmation: false,
      bulkPaymentResult: null,
      bulkPaymentError: null,
      noCard: null,
      postPaymentError: null,
    };
    props.clearGetCart();
    props.clearPostCompleteBulkOrders();
  }

  componentDidMount() {
    const { location: { search } } = this.props;
    const searchQuery = queryString.parse(search);
    const { customerId } = searchQuery || {};
    window.scrollTo(0, 0);
    this.props.getBulkCarts({ customerId });

    this.props.getLookups();
    this.setState({
      noAddress: false,
      PaymentStatusActiveLoading: false,
      showBulkConfirmation: false,
      bulkPaymentResult: null,
      bulkPaymentError: null,
      postPaymentError: null,
      noCard: null,

    })
  }

  componentWillUnmount() {
    this.setState({
      noAddress: false,
      PaymentStatusActiveLoading: false,
      showBulkConfirmation: false,
      bulkPaymentResult: null,
      bulkPaymentError: null,
      postPaymentError: null,
      noCard: null,
    })
  }

  async pingPaymentStatus(id) {
    try {
      const status = await getPollingStatus(id);
      if (status.complete) {
        const { results, errors } = status || {};
        this.setState({
          PaymentStatusActiveLoading: false,
          bulkPaymentResult: results,
          bulkPaymentError: errors,
        });

        if ((results || []).length > 0) {
          const { customerId, brandingId, emailRecipient } = (results || [])[0] || {};
          const poIds = (results || []).map(po => po.purchaseOrderId);
          const purchaseOrderIds = poIds.join(',');
          const emailPayload = {
            brandingId,
            cc: null,
            purchaseOrderIds,
            to: emailRecipient,
            userId: customerId,
          }
          this.props.sendBulkOrderEmail(emailPayload)
        }
        return;
      }
      setTimeout(() => this.pingPaymentStatus(id), 5000);
    } catch (err) {
    }
  }
  render() {
    const {
      classes,
      changeFormValue,
      carts,
      lookups,
      inProgress,
      addressBookId,
      formValues,
      company,
      address2,
      paymentFormValues,
      makeDefaultShipping: isMarkedSaveDefault,
      formErrors,
      formAsyncErrors,
      paymentError,
      paymentInProgress,
      clearPostCompleteBulkOrders
    } = this.props;
    const {
      PaymentStatusActiveLoading,
      showBulkConfirmation,
      // pollingLoading,
      postPaymentError,
      noAddress,
      noCard
    } = this.state;

    const countryId = 37;
    const { user } = carts || {};
    const { AddressBook, CreditCards, userId, email } = user || {};

    const { states, countries } = lookups || {};
    const saveAsDefaultAllowed = false;
    const stateOptions = (states || [])
      .filter(({ countryId: itemCountryId }) => itemCountryId === 37)
      .map(({ stateId, stateName }) => ({
        value: stateId,
        label: stateName
      }));
    const countryOptions = (countries || [])
      .map(({ countryId, countryName }) => ({
        value: countryId,
        label: countryName
      }));
    const selectedCountry = (countries || [])
      .find(({ countryId: itemCountryId }) => itemCountryId === countryId);
    const shoppingCartIds = ((carts || {}).shoppingCarts || []).map(cart => cart.shoppingCartId);

    const clearAddress = () => {
      changeFormValue(FORM_NAME, 'name_first', '');
      changeFormValue(FORM_NAME, 'name_last', '');
      changeFormValue(FORM_NAME, 'company', '');
      changeFormValue(FORM_NAME, 'address1', '');
      changeFormValue(FORM_NAME, 'address2', '');
      changeFormValue(FORM_NAME, 'city', '');
      changeFormValue(FORM_NAME, 'zip', '');
      changeFormValue(FORM_NAME, 'phone', '');
      changeFormValue(FORM_NAME, 'stateId', null);
      changeFormValue(FORM_NAME, 'countryId', 37);
      changeFormValue(FORM_NAME, 'makeDefaultBilling', false);
      changeFormValue(FORM_NAME, 'saveAddress', false);
    };
    const addressInvalid =
      Object.keys(formErrors || {}).some(e => ['address1', 'city', 'name_first', 'name_last', 'phone', 'stateId', 'zip'].some(x => x === e))
      || Object.keys(formAsyncErrors || {}).some(e => ['address1', 'city', 'name_first', 'name_last', 'phone', 'stateId', 'zip'].some(x => x === e))

    const cardInvalid =
      Object.keys(formErrors || {}).some(e => ['cardId', 'expirationMonth', 'expirationYear', 'cardNumber', 'cvv'].some(x => x === e))
      || Object.keys(formAsyncErrors || {}).some(e => ['cardId', 'expirationMonth', 'expirationYear', 'cardNumber', 'cvv'].some(x => x === e))

    const onMakeDefaultAddress = (value) => {
      changeFormValue(FORM_NAME, 'saveAddress', value);
    }
    const handleAddressBookChange = addressId => {

      const address = (AddressBook || [])
        .find(({ AddressBookID }) => AddressBookID === parseInt(addressId)) || {};

      const {
        FirstName: name_first,
        LastName: name_last,
        Company: company,
        Address1: address1,
        Address2: address2,
        City: city,
        Zip: zip,
        Phone: phone,
        StateID: stateId
      } = address || {};

      changeFormValue(FORM_NAME, 'addressBookId', parseInt(addressId));
      changeFormValue(FORM_NAME, 'name_first', name_first);
      changeFormValue(FORM_NAME, 'name_last', name_last);
      changeFormValue(FORM_NAME, 'company', company);
      changeFormValue(FORM_NAME, 'address1', address1);
      changeFormValue(FORM_NAME, 'address2', address2);
      changeFormValue(FORM_NAME, 'city', city);
      changeFormValue(FORM_NAME, 'zip', zip);
      changeFormValue(FORM_NAME, 'phone', phone);
      changeFormValue(FORM_NAME, 'stateId', stateId);
    };
    const setNewAddress = () => {
      const addressBookIdMatch = getInitAddressId(AddressBook, formValues);
      changeFormValue(FORM_NAME, 'addressBookId', addressBookIdMatch)
    }

    const handleCardChange = selectedCardId => {

      const selectedCard = (CreditCards || [])
        .find(({ CardId }) => CardId === parseInt(selectedCardId)) || {};

      const {
        CardId: cardId,
      } = selectedCard || {};

      changeFormValue(FORM_NAME, 'cardId', parseInt(cardId));
    };


    const setNewCard = () => {
      changeFormValue(FORM_NAME, 'cardId', 'new');
    }
    const initialValues = getInitialValues({
      AddressBook,
      CreditCards,
    });

    const handleCompleteOrder = async (values) => {
      clearPostCompleteBulkOrders()
      this.setState({
        noCard: null, noAddress: null, postPaymentError: null, PaymentStatusActiveLoading: true,
      });
      if (!(values || {}).address1) {
        this.setState({ noAddress: true });
        return;
      }
      if (!(values || {}).cardId || ((values || {}).cardId === 'new' && !(values || {}).cardNumber)) {
        this.setState({ noCard: true });
        return;
      }
      const payload = {
        carts: shoppingCartIds,
        details: {
          cardId: values.cardId,
          cardNumber: values.cardNumber,
          cvv: values.cvv,
          defaultCard: values.makeDefaultBilling,
          expirationMonth: values.expirationMonth,
          expirationYear: values.expirationYear,
          saveCard: values.saveCard,
        },
        firstName: values.name_first,
        lastName: values.name_last,
        paymentType: 'card',
        userId,
        billingAddressPayload: {
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          zip: values.zip,
          phone: values.phone,
          saveAddress: values.saveAddress,
          makeDefaultBilling: values.makeDefaultBilling,
          stateId: values.stateId,
          addressBookId: values.addressBookId,
          name_first: values.name_first,
          name_last: values.name_last,
          email,
        }
      }

      try {
        const { id, bulkGatewayResult } = await postCompleteBulkOrders(payload);
        const { Result } = bulkGatewayResult || {};
        const resultNumber = Number(Result);
        if (resultNumber === 0) {
          this.setState({
            showBulkConfirmation: true,
          });
          window.scrollTo(0, 0);
        }
        if (isNaN(resultNumber) || resultNumber !== 0) {
          this.setState({
            postPaymentError: true,
            PaymentStatusActiveLoading: false,
          });
          return;
        }
        this.pingPaymentStatus(id);

      } catch (err) {
        this.setState({
          postPaymentError: true,
          PaymentStatusActiveLoading: false,

        })
      }
    }
    return (
      <>
        <SEO
          title='Bulk Orders'
          meta={null}
        />
        {inProgress && <Loader />}
        {!inProgress && !showBulkConfirmation && (
          <CheckoutLayout isBulk >
            {!((carts || {}).shoppingCarts || []).length > 0 && (
              <div className={classes.container}>
                <h2 className={joinClasses('h4', classes.headline)}>
                  Shopping Cart Information
                </h2>
                No shopping carts were found. Your link may have expired; please contact Customer Service.
              </div>
            )}

            {((carts || {}).shoppingCarts || []).length > 0 && !showBulkConfirmation && (
              <Form form={FORM_NAME} initialValues={initialValues} onSubmit={handleCompleteOrder}>
                <div className={classes.container}>
                  <h2 className={joinClasses('h4', classes.headline)}>
                    Shopping Cart Information
                  </h2>
                  {carts && <Carts
                    items={carts.shoppingCarts}
                    classes={classes}
                  />}
                </div>

                <div className={classes.container}>
                  <div className={classes.section}>
                    {carts &&
                      <div>
                        <h2 className='h4'>
                          Billing Address
                        </h2>
                        <SavedAddresses
                          context='Billing'
                          user={user}
                          stateOptions={stateOptions}
                          countryOptions={countryOptions}
                          setNewAddress={setNewAddress}
                          addressBookId={addressBookId}
                          handleAddressBookChange={handleAddressBookChange}
                          formValues={formValues}
                          address2={address2}
                          company={company}
                          clearAddress={clearAddress}
                          addressInvalid={addressInvalid}
                          saveAsDefaultAllowed={saveAsDefaultAllowed}
                          onMakeDefaultAddress={onMakeDefaultAddress}
                          isMarkedSaveDefault={isMarkedSaveDefault}
                          changeState={(input) => changeFormValue(FORM_NAME, 'stateId', input)}
                          states={states}
                          selectedCountry={selectedCountry}
                          isBulk
                        />
                        {noAddress &&
                          <p
                            style={{
                              color: '#dc3545',
                              'font-weight': 'bold'
                            }}
                          >
                            Please provide a valid billing address.
                          </p>

                        }
                      </div>}
                  </div>
                </div>

                <div className={classes.container}>
                  {CreditCards &&
                    <div>
                      <h2 className='h4'>
                        Payment Method
                      </h2>
                      <SavedCards
                        heading=''
                        user={user}
                        handleCardChange={handleCardChange}
                        setNewCard={setNewCard}
                        cardInvalid={cardInvalid}
                        paymentFormValues={paymentFormValues}
                        isBulk
                      />
                    </div>
                  }
                </div>

                {noCard &&
                  <p
                    style={{
                      color: '#dc3545',
                      'font-weight': 'bold'
                    }}
                  >
                    Please provide a valid credit card.
                  </p>
                }
                {postPaymentError && !paymentInProgress && (
                  <p
                    style={{
                      color: '#dc3545',
                      padding: '0px 0px 20px 0px',
                      'font-weight': 'bold'
                    }}
                  >
                    {
                      postPaymentError && postPaymentError.message ? postPaymentError.message
                        :
                        `Failed to apply payment. Please review billing information and try again.`
                    }
                  </p>
                )}
                {PaymentStatusActiveLoading && !postPaymentError && !paymentError &&
                  <div className={classes.buttonContainer} style={{
                    color: '#00a961',
                    textAlign: 'center',
                    'font-weight': 'bold'
                  }}>
                    Your orders are processing. Please do not navigate away from this page until they have completed.
                  </div>
                }
                {PaymentStatusActiveLoading && !postPaymentError && !paymentError && <Loader />}

                {!PaymentStatusActiveLoading && <div className={classes.buttonContainer} >
                  <Button
                    type='submit'
                    title='Submit Orders'
                  />
                </div>}
              </Form>
            )}

          </CheckoutLayout>
        )}
        {showBulkConfirmation &&
          <CheckoutLayoutConfirmation id='confirmation' isBulk showBulkConfirmation>
            <BulkConfirmation
              email={email}
            />

          </CheckoutLayoutConfirmation>}



      </>
    )
  }
}

const selector = formValueSelector(FORM_NAME);
const isInvalidSelector = isInvalid(FORM_NAME);
const hasSubmitFailedSelector = hasSubmitFailed(FORM_NAME);
const getFormSyncErrorsSelector = getFormSyncErrors(FORM_NAME);
const getFormAsyncSyncErrorsSelector = getFormAsyncErrors(FORM_NAME);

const mapState = state => {

  const addressBookId = selector(state, 'addressBookId');
  const address1 = selector(state, 'address1');
  const address2 = selector(state, 'address2');
  const name_first = selector(state, 'name_first');
  const name_last = selector(state, 'name_last');
  const city = selector(state, 'city');
  const zip = selector(state, 'zip');
  const stateId = selector(state, 'stateId');
  const countryId = selector(state, 'countryId');
  const company = selector(state, 'company');
  const phone = selector(state, 'phone');

  const makeDefaultShipping = selector(state, 'makeDefaultBilling');
  const saveAddress = selector(state, 'saveAddress');

  const cardId = selector(state, 'cardId');
  const expirationMonth = selector(state, 'expirationMonth');
  const expirationYear = selector(state, 'expirationYear');
  const cardNumber = selector(state, 'cardNumber');
  const cvv = selector(state, 'cvv');
  const saveCard = selector(state, 'saveCard');

  const sameAsShipping = selector(state, 'sameAsShipping');

  const saveInfo = selector(state, 'saveInfo') || null;

  const formErrors = getFormSyncErrorsSelector(state);
  const formAsyncErrors = getFormAsyncSyncErrorsSelector(state);


  const formValues = {
    address1,
    address2,
    name_first,
    name_last,
    city,
    zip,
    stateId,
    company,
    phone,
    countryId
  };

  const paymentFormValues = {
    cardId,
    expirationMonth,
    expirationYear,
    cardNumber,
    cvv,
    saveCard
  };

  const {
    error: getCartsError,
    inProgress,
    result: carts
  } = state.fetchReducer.getBulkCarts.toJS();

  const {
    error: paymentError,
    inProgress: paymentInProgress
  } = state.fetchReducer.postCompleteBulkOrders.toJS();


  const {
    // error,
    inProgress: lookupsInProgress,
    result: lookups
  } = state.fetchReducer.getLookups.toJS();

  const payment = state.billing || {};
  const formData = state.account || {};

  const isFormInvalid = isInvalidSelector(state);
  const hasSubmitFailedValue = hasSubmitFailedSelector(state);

  return {
    lookups,
    carts,
    payment,
    formData,
    addressBookId,
    formValues,
    paymentFormValues,
    sameAsShipping,
    company: !!company,
    address2: !!address2,
    saveInfo,
    isFormInvalid,
    formErrors,
    formAsyncErrors,
    hasSubmitFailedValue,
    makeDefaultShipping,
    saveAddress,
    paymentError,
    getCartsError,
    paymentInProgress,
    inProgress: inProgress || lookupsInProgress,

  };
};


const mapDispatch = dispatch => bindActionCreators({
  ...actionCreators,
  ...accountActionCreators,
  changeFormValue: change,
  getBulkCarts: fetchDux.getBulkCarts.createAction,
  postCompleteBulkOrders: fetchDux.postCompleteBulkOrders.createAction,
  clearPostCompleteBulkOrders: fetchDux.postCompleteBulkOrders.clearAction,
  sendBulkOrderEmail: fetchDux.sendBulkOrderEmail.createAction,
  clearGetCart: fetchDux.getBulkCarts.clearAction,
  getLookups: fetchDux.getLookups.createAction,
  postCartBilling: fetchDux.postCartBilling.createAction,
}, dispatch);

const StyledPayment = withStyles(styles)(Payment);

export default connect(mapState, mapDispatch)(StyledPayment);



