import React from "react";
import ReactModal from "react-modal";
import { withStyles } from "@material-ui/styles";
import { FormControl, RadioGroup, Radio, FormControlLabel} from "@material-ui/core";
import close from "../../../../../assets/vectors/Close.svg";
import Button from "../../../../bits/Shared/Button";
import { joinClasses, capitalizeFLetter } from "../../../../../utils";
import styles from "./styles";


ReactModal.setAppElement(document.getElementById("root"));

const ValidateAddressModal = ({ 
  classes, 
  isOpen, 
  onClose, 
  context,
  formValues,
  validateResult,
  clearPostValidateAddress,
  inProgress,
  handleValidateSubmit,
  states,
  hasAlcohol
}) => {
  
  const renderAddress = (
    title, 
    {    
      address1,
      address2,
      city,
      stateShort,
      zip,
      zip_4
  }) => (
    <div>
      <span>{title}</span>
      <p>{address1}</p>
      {address2 && <p>{address2}</p>}
      <p>{city}, {stateShort}</p>
      <span>{zip}{zip_4 && <span>-{zip_4}</span>}, United States</span>
    </div>
  )

  const [confirmSelected, setConfirmSelected] = React.useState('0');

  const {
    AddressSuggestion
  } = validateResult || {}

  const {    
    City,
    State,
    Street1,
    Street2,
    Zip1,
    Zip2,
    IsPoBox
  } = AddressSuggestion || {};

  const hasSuggestion = !!AddressSuggestion;

  const translateResult = {
    address1:Street1,
    address2:Street2,
    city: City,
    stateShort:State,
    zip:Zip1,
    zip_4:Zip2
  }

  const { stateId } = formValues || {};

  const shippingState = hasSuggestion ?
  (states || []).find(x => x.stateShort.toUpperCase() === (State || '').toUpperCase()) :
  (states || []).find(x => x.stateId === stateId);

  const enteredState = (states || []).find(x => x.stateId === stateId);

  formValues.stateShort = enteredState ? (enteredState.stateShort || '') : '';
  translateResult.stateId = hasSuggestion ? (shippingState.stateId || null) : null;

  const restrictedState = hasAlcohol && !(shippingState || {}).offSiteShipping;

  const disabledIgnore = hasAlcohol && !(enteredState || {}).offSiteShipping;

  const closeModal = e => {
    if (inProgress) return;
    clearPostValidateAddress();
    onClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={classes.container}
      overlayClassName="react-modal"
    >
      <p className={joinClasses("h5", classes.heading)}>
        {`Verify ${capitalizeFLetter(context)} Address`}
      </p>
      {!inProgress &&
        hasSuggestion &&
        !IsPoBox &&
        <p className={classes.advise}>To ensure delivery success, we suggest the address change below. Please verify which address you would like to use.</p>}
      {!inProgress &&
        !hasSuggestion &&
          !restrictedState && (
            <p className={classes.advise}>
              <span style={{ color: '#dc3545' }}>Unable to validate address.</span>
              <br />
              Please verify shipping address before proceeding
            </p>
          )}
      {!inProgress &&
        restrictedState && (
          <p className={classes.advise}>
            <span style={{ color: '#dc3545' }}>Selected state does not allow alcohol shipments.</span>
            <br />
            Please verify shipping address before proceeding
          </p>
        )}
      {!inProgress &&
        !restrictedState &&
          disabledIgnore && (
            <p className={classes.advise}>
              <span style={{ color: '#dc3545' }}>Selected state does not allow alcohol shipments.</span>
              <br />
              Please use the suggested address to continue
            </p>
          )}
      {!inProgress &&
        IsPoBox && (
            <p className={classes.advise}>
              <span style={{ color: '#dc3545' }}>Unable to ship to entered address.</span>
              <br />
              We are unable to deliver to PO Box addresses. Please enter another address.
            </p>
          )}
      {!inProgress &&
        hasSuggestion &&
        !IsPoBox && (
        <div>
          <FormControl style={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            <RadioGroup
              value={confirmSelected}
              aria-label="choose a card"
              onChange={e => setConfirmSelected(e.target.value)}
              row
              style={{justifyContent: 'flex-start', alignItems: 'flex-start'}}
            >
              <FormControlLabel
                value="0"
                control={<Radio color="primary" />}
                label={renderAddress('Suggested Address', translateResult || {})}
                labelPlacement="end"
              />
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label={renderAddress('Entered Address', formValues || {})}
                labelPlacement="end"
                disabled={disabledIgnore}
              />
            </RadioGroup>
          </FormControl>
        </div>
      )} 
      {!inProgress && 
        !hasSuggestion &&
        !IsPoBox &&
          <p> {renderAddress('Entered Address', formValues || {})} </p>}
      {inProgress && (
        <div style={{height:'125px', verticalAlign:'middle'}}>
          LOADING ... 
        </div>
      )}
      {!restrictedState &&
        !hasSuggestion && 
        !IsPoBox && (
          <Button
            onClick={() => handleValidateSubmit(confirmSelected, formValues)}
            className={classes.button}
            title="Verify Address"
            disabled={inProgress}
          />
        )}
      {!restrictedState &&
        hasSuggestion && 
        !IsPoBox && (
        <Button
          onClick={() => handleValidateSubmit(confirmSelected, formValues, translateResult)}
          className={classes.button}
          title="Confirm Selection"
          disabled={inProgress}
        />
      )}
      {!inProgress &&
        restrictedState && (
          <Button
            onClick={closeModal}
            className={classes.button}
            title="Okay"
            disabled={inProgress}
          />
        )}
      {!inProgress &&
        IsPoBox && (
          <Button
            onClick={closeModal}
            className={classes.button}
            title="Okay"
            disabled={inProgress}
          />
        )}
      <button type="button" onClick={closeModal} className={classes.close} disabled={inProgress}>
        <img src={close} alt="Close overlay" />
      </button>
    </ReactModal>
  );
};

export default withStyles(styles)(ValidateAddressModal);
