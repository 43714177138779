const overrides = {
  MuiCheckbox: {
    colorSecondary: {
      color: '#EDEAE6',
      '&:hover': {
        backgroundColor: 'rgba(47, 47, 46, 0.15)'
      },
      '&$checked': {
        color: '#EDEAE6',
        '&:hover': {
          backgroundColor: 'rgba(47, 47, 46, 0.15)'
        }
      }
    },
    colorPrimary: {
      '&:hover': {
        backgroundColor: 'rgba(47, 47, 46, 0.15)'
      },
      color: '#2F2F2E',
      '&$checked': {
        '&:hover': {
          backgroundColor: 'rgba(47, 47, 46, 0.15)'
        },
        color: '#2F2F2E'
      }
    }
  },
  MuiInput: {
    root: {
      fontSize: '1.1em',
      lineHeight: '1.75'
    },
    formControl: {
      'label + &': {
        marginTop: '1.4rem'
      }
    },
    input: {
      height: '1.6em',
      padding: '7px 0 8px'
    },
    underline: {
      '&:after': {
        borderBottom: '1px solid #202124'
      }
    }
  },
  MuiFormLabel: {
    root: {
      color: '#7e7e7e'
    },
    asterisk: {
      color: '#DB0000',
      float: 'left',
      marginRight: '0.3em',
      opacity: 0.7,
      '&$error': {
        color: '#DB0000'
      }
    }
  },
  MuiInputLabel: {
    root: {
      fontSize: '1.1em',
      lineHeight: '1.1666666667',
      textTransform: 'capitalize',
      '&$focused': {
        color: 'inherit'
      }
    },
    formControl: {
      fontSize: '1.1em',
      // top: "1rem",
      lineHeight: '1.1666666667'
    }
  },
  MuiFormControlLabel: {
    root: {
      marginRight: '4rem',
      marginBottom: '2rem',
      alignItems: 'flex-start',
      '& span': {
        paddingTop: '5px!important'
      }
    }
  },
  MuiTypography: {
    body1: {
      fontSize: 'inherit'
    }
  },
  MuiRadio: {
    colorSecondary: {
      '&:hover': {
        backgroundColor: 'rgba(47, 47, 46, 0.15)'
      },
      color: '#2F2F2E',
      '&$checked': {
        '&:hover': {
          backgroundColor: 'rgba(47, 47, 46, 0.15)'
        },
        color: '#2F2F2E'
      }
    },
    colorPrimary: {
      '&:hover': {
        backgroundColor: 'rgba(47, 47, 46, 0.15)'
      },
      color: '#2F2F2E',
      '&$checked': {
        '&:hover': {
          backgroundColor: 'rgba(47, 47, 46, 0.15)'
        },
        color: '#2F2F2E'
      }
    }
  },
  MuiSvgIcon: {
    root: {
      width: '1.4rem',
      height: '1.4rem'
    }
  },
  MuiPaper: {
    elevation8: {
      boxShadow: 0
    }
  },
  MuiMenu: {
    paper: {
      maxHeight: '220px',
      // '&::-webkit-scrollbar': {
      //     width: '10px'
      // },
      // '&::-webkit-scrollbar-track': {
      //     boxShadow: 'inset 0 0 10px 10px white',
      //     border: 'solid 3px #F2F0EC'
      // },
      // '&::-webkit-scrollbar-thumb': {
      //     boxShadow: 'inset 0 0 10px 10px rgba(0, 0, 0, 0.3)',
      //     border: 'solid 3px #F2F0EC'
      // }
    }
  },
  MuiList: {
    root: {
      backgroundColor: '#F2F0EC'
    },
    padding: {
      padding: '0!important'
    }
  },
  MuiMenuItem: {
    root: {
      fontSize: '14px',
      backgroundColor: '#F2F0EC',
      height: '40px',
      borderBottom: '1px solid #E7E5E4',
      selected: {
        backgroundColor: '#EEEBE5'
      }
    }
  },
  MuiInputBase: {
    inputMultiline: {
      fontSize: '1.4rem'
    }
  },
  MuiExpansionPanel: {
    root: {
      boxShadow: 'initial',
      '&$rounded': {
        padding: '20px 0',
        borderBottom: '1px solid #C8C7C3',
        '&:first-child': {
          borderTop: '2px solid red'
        }
      },
      '&$expanded': {
        margin: 0,
        backgroundColor: '#F2F0EC'
      }
    }
  },
  MuiAutocomplete: {
    listbox: {
      maxHeight: '235px',
      overflowY: 'scroll',
      backgroundColor: '#F2F0EC',
      overflow: 'hidden',
      padding: 0,
      '& li': {
        padding: '1.5rem 1.6rem',
        borderBottom: '1px solid #E7E5E4',
        fontSize: '1.4rem',
        backgroundColor: '#F2F0EC',
      }
    },
    paper: {
      margin: 0
    },
    root: {
      margin: 0,
    }
  },
  MuiPaper: {
    rounded: {
      borderRadius: 0
    },
    elevation1: {
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
    },

  }
};

export default overrides;
