import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { joinClasses } from '../../../../utils';
import Vimeo from '@u-wave/react-vimeo';
import styles from "./styles";

const Video = ({ classes, className, data }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [atts, setAtts] = useState({});

  const onVideoLoaded = () => {
    setIsVideoLoaded(true);
  }

  useEffect(() => {
    const newAtts = {};
    newAtts.autoPlay = data?.features?.autoplay;
    newAtts.controls = data?.features?.controls;
    newAtts.loop = data?.features?.loop;
    newAtts.muted = data?.features?.muted;
    newAtts.playsInline  = true;
    if (data?.features?.poster) {
      newAtts.poster = data.features.poster.url;
    }
    setAtts(prevAtts => ({
      ...prevAtts,
      ...newAtts
    }));
  }, [data]);

  return (
    <video
      {...atts}
      className={joinClasses(
      classes.container,
      className
      )}
      onLoadedData={onVideoLoaded}
    >
      {data.source.mp4 &&
        <source src={data.source.mp4.url} type={data.source.mp4.mime_type} />}
      {data.vimeo &&
        <source src={data.vimeo} type="video/mp4" />}
      {data.source.ogg &&
        <source src={data.source.ogg.url} type={data.source.ogg.mime_type} />}
      {data.source.webm &&
        <source src={data.source.webm.url} type={data.source.webm.mime_type} />}
      Your browser does not support the video tag.
    </video>
  );
};

Video.propTypes = {
  classes: PropTypes.shape({
    media: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.object.isRequired
};

export default withStyles(styles)(Video);
