import React from "react";
import ReactModal from "react-modal";
import { withStyles } from "@material-ui/styles";
import close from "../../../../../assets/vectors/Close.svg";
import styles from "./styles";
import { joinClasses } from "../../../../../utils";

const MembershipTermsModal = ({ isOpen, onClose, classes }) => {
    const closeModal = e => {
        e.preventDefault();
        onClose();
    };
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={classes.container}
            overlayClassName="react-modal"
        >
            <p className={joinClasses("h5", classes.heading)}>Twomey Membership Terms & Conditions</p>

            <p
                dangerouslySetInnerHTML={(() => {
                    return {
                        __html: `As a member, you will receive shipments from Twomey Cellars throughout the year. By joining, you agree to allow Twomey Cellars to charge your credit card for each shipment. <br/>
                    Wine memberships are available to residents of the following states: AK, AZ, CA, CO, CT, FL, GA, HI, IA, ID, IL, KS, KY, MA, MD, ME, MI, MN, MO, MT, NC, ND, NE, NH, NM, NV, NY, OK, OR, PA, SC, SD, TN, TX, VA, VT, WA, WI, WV, WY & DC. 
                    <br/>
                    Shipments made within certain states are subject to state sales tax. Void where prohibited by law. <br/>
                    Twomey Membership is ongoing, but may be canceled at any time with written notice submitted to membership@twomey.com. A minimum of one shipment is required to qualify for membership benefits. Thereafter, you have no obligations to continue as a member. 
                    <br/><br/>
                    <h6 style='font-weight:bold'>Order Processing & Shipments</h6>
                     All wine orders must be placed and received by a person 21 years of age or older. Shipper requires verification of age at the time of delivery. Failure to produce age verification forfeits the right to receive the shipment until identification is produced. If wine is returned as a result of the inability to provide age verification, all associated costs will be the responsibility of, and charged to, the customer. We recommend the use of a business address to facilitate delivery of your purchase. No delivery to P.O. Boxes.  
                     <br/>
                    Orders are processed 5-7 days prior to each shipment and will include applicable taxes and flat rate shipping charges. To ensure that the quality of your wine is not compromised, all orders are shipped 2-Day Air on Monday or Tuesday to avoid weekends. Please note that a signature from an adult (21 years or older) is always required upon delivery.  
                    <br/>
                    For Twomey Membership shipments, please notify us two weeks prior to the first of any shipment month to update your account, change your address or cancel your membership. You may call 707-942-7111 or email membership@twomey.com for assistance with your membership. Misdirected shipments and delivery difficulties will result in additional shipping charges. Will-call selections are held at the wineries for six weeks after each shipment. If wines are not claimed at that time, they will be carefully packaged and sent to your shipping address on record and shipping charges will be incurred. 
                    <br/><br/>
                    <h6 style='font-weight:bold'>Required Legal Notice</h6>
                    Privacy: The shipping information related to an order (but not the purchase information) will be shared with the shipping company that Silver Oak and Twomey Cellars use for any given order. If an order is being shipped directly from a Silver Oak or Twomey partner rather than our warehouse, the shipping information (but not the purchase information) will be shared with that partner. 
                    <br/>
                    Twomey Cellars reserves the right to change the cost of shipments as shipping and/or bottle prices change. Benefits, privileges, terms, and conditions of membership are subject to change and may be denied, modified, or terminated at any time, at the sole discretion of Twomey Cellars.
                    `
                    }
                })()}
            />
            <button
                type="button"
                onClick={e => {
                    closeModal(e)
                }}
                className={classes.close}
                style={{ right: "5vw" }}
            >
                <img src={close} alt="Close overlay" />
            </button>

        </ReactModal>
    )
}

export default withStyles(styles)(MembershipTermsModal);