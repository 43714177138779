import { createStyles } from "@material-ui/core";

export default theme =>
  createStyles({
    root: {
      // "& .MuiSelect-select": {
      //   paddingTop: "8px",
      //   paddingBottom: "3px",
      // }
    },
    noArrowSelect: {
      "& .MuiSelect-select": {
        paddingRight: 0
      }
    },
    selectInverse: {
      "&:before": {
        borderColor: theme.colors.quaternary + "!important"
      },
      "&:after": {
        borderColor: theme.colors.quaternary + "!important"
      },
      "& div": {
        color: theme.colors.white
      },
    },
    arrowIcon: {
      right: "0.4em",
      width: "0.6em",
      position: "absolute",
      pointerEvents: "none"
    },
    required: {
      '& .MuiSelect-selectMenu': {
        paddingLeft: '2rem',
      },
     '&:before' : {
       content: '"*"',
       color: "#DB0000",
       opacity: 0.7,
     },
     '&:hover': {
       '&:before' : {
        borderBottom: '1px solid rgba(0, 0, 0, 0.87)!important'
       },
     }

    }
  });
