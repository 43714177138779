import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { getCheckZip } from '../../../../store/reducers/fetchDux/api';
import Button from '../../../bits/Shared/Button';

import CheckedIcon from '../../../bits/Shared/Icons/Checked';
import UncheckedIcon from '../../../bits/Shared/Icons/Unchecked';
import { joinClasses } from '../../../../utils';
import { Field, FieldError, validate, Form } from '../../../../utils/forms';
import styles from './styles';

const normalizePhone = require('../../../../utils/normalizePhone').handler;

const validate30 = validate.characters(30)
const validate50 = validate.characters(50)
const validate75 = validate.characters(75)
const validate100 = validate.characters(100)
const validate255 = validate.characters(255)
const FORM_NAME = 'FEDEX_PICKUP_INFO';

const PickUpInfoForm = ({
  classes,
  heading,
  className,
  selectedCountry,
  company = null,
  address2 = null,
  selectedFedexLocation,
  onHandleGoBackToLoactionMap,
  onSavePickUpInfo,
  onClose
}) => {
  const { address } = selectedFedexLocation || {};
  const { stateOrProvinceCode } = address || {};
  const initialValues = {
    stateShort: stateOrProvinceCode
  }
  const validateZip = async (values) => {

    const { zip, stateShort } = values;

    if (!zip) throw { zip: 'Required' };
    const result = await getCheckZip(zip);
    const { places, valid, err } = result || {};
    if (err) {
      return;
    };

    if (!valid) {
      throw { zip: 'Invalid Zip Code.' };
    };
    const { 'state abbreviation': stateNameFromZip } = (places || [])[0] || {}
    if (stateShort !== stateNameFromZip) {
      throw { zip: 'Zip is not in selected state.' };
    };
  };

  const {
    countryId
  } = selectedCountry || {};
  const [showCompany, setShowCompany] = React.useState(!!company);
  const [showAddressLine2, setShowAddressLine2] = React.useState(!!address2);
  const HandleSavePickUpInfo = (values) => {
    const payload = Object.assign({}, { selectedFedexLocation }, { pickUpPerson: values })
    onSavePickUpInfo(payload)

  }
  return (
    <Form
      form={FORM_NAME}
      asyncValidate={validateZip}
      className={joinClasses('address-form', className)}
      initialValues={initialValues}
      onSubmit={HandleSavePickUpInfo}

    >
      {heading && (
        <p className={joinClasses('h6', classes.heading)}>{heading}</p>
      )}
      <p style={{marginBottom:"5px"}}> Alcohol regulations require that the recipient resides at an address in the same state as the pickup location.
      Please ensure that the name and address provided matches the name and address of the person picking up.</p>
      <Field
        name="name_first"
        validate={[validate.required, validate50]}
      >
        <TextField
          required
          label="First name"
          className={joinClasses(classes.textField, classes.firstName)}
          sdwinput
        />
      </Field>
      <div
        className={joinClasses(classes.lastNameContainer, classes.textField)}
      >
        <Field name="name_last" validate={[validate.required, validate50]}>
          <TextField
            required
            label="Last name"
            className={classes.lastName}
            sdwinput
          />
        </Field>
        <FormControlLabel
          className={joinClasses((showCompany ? classes.toggleActive : classes.toggle), classes.hideMobile)}
          control={<Checkbox color="secondary" icon={<CheckedIcon />} checkedIcon={<UncheckedIcon />} checked={showCompany} />}
          label="Company Name"
          onChange={event => setShowCompany(event.target.checked)}
        />
      </div>

      <Field name="company" validate={[validate100]}>
        <TextField
          label="Company name"
          className={joinClasses(
            classes.textField,
            classes.companyName,
            showCompany ? classes.isActive : ''
          )}
          sdwinput
        />
        {/* <FieldError sdwerror /> */}
      </Field>
      <div className={joinClasses(classes.addressContainer, classes.textField)}>
        <Field name="address1" validate={[validate.required, validate255]}>
          <TextField
            label="Address Line 1"
            className={classes.addressLine1}
            required
            sdwinput
          />
        </Field>
        <FormControlLabel
          className={joinClasses((showAddressLine2 ? classes.toggleActive : classes.toggle), classes.hideMobile)}
          control={<Checkbox color="secondary" icon={<CheckedIcon />} checkedIcon={<UncheckedIcon />} checked={showAddressLine2} />}
          label="Address Line 2"
          onChange={event => setShowAddressLine2(event.target.checked)}
        />
      </div>
      <Field name="address2" validate={[validate75]}>
        <TextField
          label="Address Line 2"
          className={joinClasses(
            classes.textField,
            classes.addressLine2,
            showAddressLine2 ? classes.isActive : ''
          )}
          sdwinput
        />
        <FieldError sdwerror />
      </Field>
      <Field name="city" validate={[validate.required, validate75]}>
        <TextField
          label="City"
          className={joinClasses(classes.textField, classes.city)}
          required
          sdwinput
        />
      </Field>
      <Field
        name="stateShort"
        validate={[validate.required, validate50]}
      >
        <TextField
          required
          label="State"
          className={joinClasses(classes.textField, classes.firstName)}
          disabled={true}
        />
        {/* <FieldError sdwerror /> */}
      </Field>
      <Field name="zip" validate={[validate.required, validate30]}>
        <TextField
          label="ZIP Code"
          className={joinClasses(classes.textField, classes.zip)}
          required
          sdwinput
        />
      </Field>
      <Field name="phone" validate={[validate.requiredPhoneNum, validate50]} normalizeOnBlur={normalizePhone(countryId)}>
        <TextField
          label="Phone Number"
          className={joinClasses(classes.textField, classes.zip, classes.phoneNumber)}
          required
          sdwinput
        />
        <FieldError sdwerror />
      </Field>

      <div className={classes.buttonContainer}>
        <Button
          onClick={onHandleGoBackToLoactionMap}
          className={classes.button}
          title="Go back"
        />
        <Button
          className={classes.button}
          title="Save"
          type='submit'
        />
      </div>
    </Form>
  );
};

PickUpInfoForm.propTypes = {
  heading: PropTypes.string,
  saveAsDefaultAllowed: PropTypes.bool,
  countryField: PropTypes.bool,
  classes: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    textField: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastNameContainer: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    addressContainer: PropTypes.string.isRequired,
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    makeDefault: PropTypes.string.isRequired,
    toggle: PropTypes.string.isRequired,
    isActive: PropTypes.string.isRequired
  }).isRequired
};

PickUpInfoForm.defaultProps = {
  heading: '',
  saveAsDefaultAllowed: false,
  countryField: false,
};

const StyledApp = withStyles(styles)(PickUpInfoForm);
const selector = formValueSelector(FORM_NAME);

const mapState = state => {
  const zip = selector(state, 'zip');

  return {
    zip
  }
}
export default connect(mapState)(StyledApp);
