const actionNamespace = 'silveroak-fetch'
const SET_ACCOUNT = `${actionNamespace}/SET_ACCOUNT`;
const CLEAR_ACCOUNT = `${actionNamespace}/CLEAR_ACCOUNT`;

const defaultState = null;

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_ACCOUNT:
      return payload;
    case CLEAR_ACCOUNT:
      return defaultState;
    default:
      return state;
  }
};

export const actionCreators = {
  setAccount: payload => ({ type: SET_ACCOUNT, payload }),
  clearAccount: () => ({ type: CLEAR_ACCOUNT })
};