import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { pushDataLayer } from '../../../../utils'

const SEO = ({ title, description, meta, link, pageData, prerender, children }) => {

  const [pageTitle, setPageTitle] = useState(title)



  useEffect(() => {
    const previousTitle = pageTitle
    let newTitle = null
    if (title && title !== previousTitle) {
      newTitle = title
    } else if (pageData?.yoast_title && pageData?.yoast_title !== previousTitle) {
      newTitle = pageData.yoast_title
    } else if (pageData?.title?.rendered && pageData?.title?.rendered !== previousTitle) {
      newTitle = pageData.title.rendered
    }
    if (newTitle) {
      newTitle = newTitle.replace(/&amp;/g, '&')
      setPageTitle(newTitle)
    }
  }, [title, pageData])

  useEffect(() => {
    const dataLayer = window.dataLayer
    const obj = {}
    if (pageTitle) {
      obj.event = 'pageTitleUpdated'
      obj.pageTitle = pageTitle
      if (dataLayer.slice(-1)[0].pageTitle !== obj.pageTitle) { // hack for Blog page title duping issue
        pushDataLayer(obj)
      }
    }
  }, [pageTitle])

  return (
    <Helmet
      defaultTitle="Silver Oak"
      titleTemplate="%s"
      // dangerouslySetInnerHTML={createMarkup(pageData?.yoast_head)}
    >
      {!prerender && <meta name="prerender-status-code" content="404" />}
      <title>{pageTitle}</title>
      {description &&
        <meta name="description" content={description} />
      }
      {pageData?.yoast_meta &&
      pageData?.yoast_meta.map((m, i) => (
        <meta key={i} {...m} />
      ))}
      {meta &&
      meta.map((m, i) => (
        <meta key={i} {...m} />
      ))}
      {link &&
      link.map((m, i) => (
        <link key={i} {...m} />
      ))}
      {children}
    </Helmet>
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.array,
  pageData: PropTypes.object,
  prerender: PropTypes.bool
}

SEO.defaultProps = {
  prerender: true
}

export default SEO