import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { joinClasses } from "../../../../utils";
import styles from "./styles";

const HeroOrderConfirmation = ({
  className,
  classes,
  image,
  eyebrow,
  title,
  email,
  customerPayLink
}) => {
  return (
    <div className={joinClasses(classes.container, className)}>
      <div>
        <div className={classes.content}>
          <div className={classes.imageContainer}>
            {image && (
              <img src={image.url} alt={image.alt} />
            )}
          </div>
          <div className={classes.textContainer}>
            <p className={joinClasses('eyebrow', classes.eyebrow)}>{eyebrow}</p>
            <h3 className={classes.heading}>{title}</h3>
            <div className={joinClasses(classes.bodyText, 'copy--large')}>
              {customerPayLink ? <p>A confirmation email will be sent to the following address shortly: <strong>{email}</strong></p>
                : <p>An email confirmation has been sent to: <strong>{email}</strong></p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

HeroOrderConfirmation.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object,
  eyebrow: PropTypes.string,
  title: PropTypes.string,
};

HeroOrderConfirmation.defaultProps = {
  inverse: false,
};

const StyledApp = withStyles(styles)(HeroOrderConfirmation);

export default StyledApp;
