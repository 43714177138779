import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Checked = (props) => (
  <SvgIcon {...props}>
    <path stroke="#A19F95" d="M.5 2.08h16v16.16H.5z" />
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M14.5 1.89a.75.75 0 10-1.37-.61L8.08 12.64a19.52 19.52 0 00-3.7-4.48.75.75 0 10-.97 1.14c.9.77 2.98 2.95 4.05 5.4a.75.75 0 001.43-.2l5.6-12.61z" 
      fill="#393834"
    />
  </SvgIcon>
  );

export default Checked;