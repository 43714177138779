import { createStyles } from '@material-ui/core';
import { vw, grid } from '../../../../utils/mixins';

export default (theme) => createStyles({
	root: {
		color: theme.colors.white,
		marginTop: vw(100),
	},
	firstRow: {
		padding: `${vw(76)} 0`,
		backgroundColor: theme.colors.black,
		fontSize: '1.2rem',
		[theme.breakpoints.up('md')]: {
			paddingRight: grid(5),
    },
		'& span': {
			fontWeight: 'bold'
		}
	},
	secondRow: {
		padding: `${vw(20)} 0`,
		backgroundColor: theme.colors.primary,
		[theme.breakpoints.down("sm")]: {
			padding: `${vw(30)} 0`,
		},
	},
	secondRowWrapper: {
		display: "flex",
		flexDirection: "row",
		// justifyContent: "space-between",
		'& p': {
			display: 'inline-block',
			fontSize: '.8rem',
		},
  },
  silveroakImg: {
  //  width: vw(104),
		height: vw(36)
  },
  twImg: {
    //width: vw(82),
    marginLeft: vw(30),
		height: vw(36)
  },
	logoDivider: {
		width: '1px',
    marginLeft: vw(30),
		backgroundColor: '#ffffff',
  },
  hideMobile: {
		display: "block!important",
		[theme.breakpoints.down("xs")]: {
			display: "none!important",
		}
	},
});
