import { createStyles } from '@material-ui/core';
import { grid } from '../utils/mixins';

export default theme => createStyles({
	checkoutContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			width: '100%',
			padding: 0,
		}
	},
	checkoutContainer: {
		display: 'flex',
    alignItems: 'flex-start',
    // backgroundColor: theme.colors.background.primary,
      // padding: '0 var(--grid-padding)',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			width: '100%',
			padding: 0,
		}
	},
	
	checkoutForm: {
		width: grid(7, true),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		}
	},
	
	hideMobile: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		}
	}
});