class AnimationController {

	frame;

	subscribers = [];

	loop() {
		this.frame = requestAnimationFrame(() => this.loop());
		this.update();
		this.render();
	}

	// update (read cycle)

	update() {
		for (let i = 0, l = this.subscribers.length; i < l; ++i) {
			const animation = this.subscribers[i];
			if (animation && animation.update()) animation.updated = true;
		}
	}

	// render (write cycle)

	render() {
		for (let i = 0, l = this.subscribers.length; i < l; ++i) {
			const animation = this.subscribers[i];
			if (animation && animation.updated) {
				animation.updated = false;
				animation.render();
				if (animation.die) this.clear(i);
			}
		}
	}

	// start / pause animation loop

	start() {
		this.loop();
	}

	pause() {
		cancelAnimationFrame(this.frame);
		this.frame = null;
	}

	// add / remove subscribers

	set(obj) {
		obj.updated = false;
		this.subscribers.push(obj);
		return this.subscribers.length - 1;
	}

	clear(index) {
		delete this.subscribers[index];
	}

	// subscribe new object for only one tick cycle

	one(obj) {
		const index = this.set(obj);
		this.subscribers[index].die = true;
	}

}

export default new AnimationController();
