import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import SilverOakLogo from "../../../../assets/vectors/SilverOak_Logo.svg";
import TwomeyLogo from "../../../../assets/vectors/Twomey_Logo.svg";
import TwomeyLogoMobile from "../../../../assets/vectors/twomey_white.svg";
import SilverOakLogoMobile from "../../../../assets/vectors/SilverOak_Logo_Inverse.svg";
// import SilverOakLogo from '../../../../assets/images/so-logo-black.png';
// import TwomeyLogo from '../../../../assets/images/twomey-logo-black.png';
// import SilverOakLogoMobile from '../../../../assets/images/so-logo-white.png';
// import TwomeyLogoMobile from '../../../../assets/images/twomey-logo-white.png';
import { joinClasses } from "../../../../utils";
import styles from "./styles";
import { connect } from 'react-redux';
import numeral from 'numeral';
import TwomeyLink from '../../../../utils/TwomeyLink'
import SilveroakLink from '../../../../utils/SilveroakLink'


const SHOW_SHIPPING_WARNING = process.env.REACT_APP_SHOW_SHIPPING_WARNING === 'true'

const formatMoney = p => numeral(p || 0).format('$0,0.00');

const getStep = id => {
  switch (id) {
    case 'shipping':
      return 1;
    case 'payment':
      return 2;
    case 'review':
      return 3;
    case 'confirmation':
      return 4;
    default:
      return 1;
  }
}

const warningStyle = {
  backgroundColor: '#f44336',
  fontWeight: 'bold',
  color: 'white',
  textAlign: 'center'
}

const getCartGrandTotal = (id, { totals }) => {
  const {
    subTotal,
    totalAmountDue,
    totalMarkdown
  } = totals || {};

  return id === 'shipping' ? (subTotal || 0) - (totalMarkdown || 0) : totalAmountDue;
}

class CheckoutNav extends Component {
  render() {
    const {
      id,
      cart,
      billingLinkActive,
      reviewLinkActive,
      style,
      isClubJoin,
      isBulk,
      showBulkConfirmation
    } = this.props;

    const { shoppingCartItems } = cart || {};
    const { classes } = this.props;

    const step = getStep(id);
    const checkActiveLink = isActive => e => isActive ? null : e.preventDefault();

    const grandTotal = getCartGrandTotal(step, cart || {});

    const numItems = (shoppingCartItems || []).reduce((prev, { qty: q }) => prev + parseInt(q), 0);

    const orderComplete = id === "confirmation";

    return (
      <div className={classes.wrapperContainer} style={style || {}}>
        <div className={classes.wrapper}>
          <div className={joinClasses("container container--wide", classes.container)}>
            <SilveroakLink>
              <Link to="/" className={classes.logo}>
                <img className={classes.hideMobile} src={SilverOakLogo} alt="Silver Oak Logo" />
                <img className={classes.showMobile} src={SilverOakLogoMobile} alt="Silver Oak Logo Mobile" />
              </Link>
            </SilveroakLink>
            <div className={classes.logoDivider} />
            <TwomeyLink>
              <Link to="/" className={classes.logoTW}>
                <img className={classes.hideMobile} src={TwomeyLogo} alt="Twomey Logo" />
                <img className={classes.showMobile} src={TwomeyLogoMobile} alt="Twomey Logo Mobile" />
              </Link>
            </TwomeyLink>

            <nav className={classes.nav}>
              <ol className={classes.navList}>
                {isBulk && <li className={joinClasses(classes.navListItem, classes.hideMobile)}>
                  <Link
                    to={`#`}
                    className={joinClasses(!showBulkConfirmation && classes.navListLinkActive, classes.navListLink )}
                  >
                    Bulk Orders
                  </Link>
                </li>}
                {!isBulk && !showBulkConfirmation && <li className={joinClasses(classes.navListItem, classes.hideMobile)}>
                  <Link
                    to={`/shipping?clubJoin=${isClubJoin}`}
                    onClick={checkActiveLink(!orderComplete)}
                    className={joinClasses(
                      id === "shipping" ? classes.navListLinkActive : "",
                      classes.navListLink
                    )}
                  >
                    Shipping
                  </Link>
                </li>}
                {!isBulk && !showBulkConfirmation &&   <li className={classes.navListItem}>
                  <Link
                    to={`/billing?clubJoin=${isClubJoin}`}
                    onClick={checkActiveLink(billingLinkActive && !orderComplete)}
                    className={joinClasses(
                      id === "payment" ? classes.navListLinkActive : "",
                      classes.navListLink
                    )}
                  >
                    Payment
                  </Link>
                </li>}
                {!isBulk && !showBulkConfirmation &&  <li className={classes.navListItem}>
                  <Link
                    to={`/review?clubJoin=${isClubJoin}`}
                    onClick={checkActiveLink(reviewLinkActive && !orderComplete)}
                    className={joinClasses(
                      id === "review" ? classes.navListLinkActive : "",
                      classes.navListLink
                    )}
                  >
                    Review
                  </Link>
                </li>}
                <li className={classes.navListItem}>
                  <Link
                    to={`/confirmation?clubJoin=${isClubJoin}`}
                    onClick={checkActiveLink(false)}
                    className={joinClasses(
                      id === "confirmation" ? classes.navListLinkActive : "",
                      classes.navListLink
                    )}
                  >
                    Confirmation
                  </Link>
                </li>
              </ol>
            </nav>
            <div className={joinClasses(classes.showMobile)}>
              <div className={joinClasses(classes.mobileTotal)}>
                <span>{formatMoney(grandTotal)}</span>
                <span>{numItems}</span>
              </div>
            </div>
          </div>
          {!isBulk && !showBulkConfirmation &&
            <span className={joinClasses(classes.steps, classes.showMobile)}>{`STEP ${step} / 4`}</span>
          }
        </div>
        {SHOW_SHIPPING_WARNING && (
          <div style={warningStyle}>
            {/* Please note that shipping carriers are experiencing delays across the country and delivery dates may be impacted by events outside our control. */}
            {/* Specific extreme heat text is used below when requested by SO */}
            Shipment date may be delayed due to carrier delays and/or extreme weather conditions. For earliest delivery, we recommend overnight service.
          </div>
        )}
      </div>
    );
  }
}

const mapState = state => {
  const payment = state.billing;


  const {
    result: shipping
  } = state.fetchReducer.getCartShipping.toJS();
  const {
    result: cart
  } = state.fetchReducer.getCart.toJS();

  const billingLinkActive = !!shipping;
  const reviewLinkActive = !!payment;

  return {
    cart,
    billingLinkActive,
    reviewLinkActive
  };
}

const StyledCheckoutNav = withStyles(styles)(CheckoutNav);

export default connect(mapState)(StyledCheckoutNav)
