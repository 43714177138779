import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { Switch, Route, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import SDWLoader from '../../utils/SDWLoader'

import fetchDux from '../../store/reducers/fetch-dux';
import styles from "./styles";
// import Nav from "../../components/modules/Shared/Nav";
// import SignIn from "../../components/modules/Account/SignIn";
import Shipping from "../../components/modules/Checkout/Shipping";
import Payment from "../../components/modules/Checkout/Payment";
import Review from "../../components/modules/Checkout/Review";
import Confirmation from "../../components/modules/Checkout/Confirmation";
import queryString from 'query-string';

// const CheckoutSignin = () => (
//   <div>
//     <Nav />
//     <SignIn />
//   </div>
// )

class Checkout extends Component {
  componentDidMount() {
    const { location: { search } } = this.props;
    const searchQuery = queryString.parse(search);
    const { clubJoin } = searchQuery || {};
    const isClubJoin = clubJoin === 'true';
    this.props.getLookups();
    this.props.getCart({ clubJoin: isClubJoin });
    this.props.validateLogin();
  }
  render() {

    // logged in user navigates to /checkout
    // if (user && !id) id = "shipping";

    return (
      <>
        {/* <SEO
          title='Checkout'
          meta={null}
        /> */}
        <SDWLoader />

        <Switch>
          <Route exact path="/review" component={Review} />
          <Route exact path="/billing" component={Payment} />
          <Route exact path="/shipping" component={Shipping} />
          <Route exact path="/confirmation" component={Confirmation} />
          <Redirect to="/shipping/" />
          {/* <Route exact path="/checkout" component={CheckoutSignin} /> */}
        </Switch>
      </>
    )
  }
}

const mapState = state => ({
  // data: state.sampleReducer.data
});

const mapDispatch = dispatch => bindActionCreators({
  getLookups: fetchDux.getLookups.createAction,
  getCart: fetchDux.getCart.createAction,
  validateLogin: fetchDux.validateLogin.createAction,
}, dispatch);

Checkout.propTypes = {
  classes: PropTypes.shape({}).isRequired
};

export const StyledApp = withStyles(styles)(Checkout);

export default connect(mapState, mapDispatch)(StyledApp);
