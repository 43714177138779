import { createStyles } from '@material-ui/core';
import { grid, vw } from '../../../../utils/mixins';

export default theme =>
  createStyles({
    container: {
  		position: 'relative',
  		padding: `${vw(60)} ${vw(60)}`,
  		backgroundColor: theme.colors.background.zero,
  		width: grid(11, false),
      height: '90vh',
  		margin: 'auto',
  		[theme.breakpoints.down("sm")]: {
  			margin: '0 10px',
  		}
  	},
  	heading: {
  		marginBottom: vw(20)
  	},
    loading: {
      marginTop: vw(340),
    },
    loadingText: {
      opacity: 0.6,
      textAlign: 'center',
      marginTop: '-6rem',
    	marginBottom: vw(20)
    },
    close: {
      display: 'block',
  		position: 'absolute',
  		top: vw(80),
  		right: vw(60),
  		width: '3.8rem',
  		height: '3.8rem',
  		marginTop: '-2.5rem',
  		[theme.breakpoints.down('md')]: {
  		    marginTop: '-1.5rem',
  		},
    },
    locationContainer: {
  		display: 'flex',
  		flexDirection: 'row',
  		flexWrap: 'wrap',
  		alignItems: 'stretch',
  		justifyContent: 'space-between',
  		width: '100%',
  		[theme.breakpoints.down('sm')]: {
  			width: '100%',
  		},
  	},

    sidebar: {
      position: 'relative',
      margin: '0',
      flex: "0 0 32%",
      height: '69vh',
      overflow: 'hidden',
      [theme.breakpoints.down("sm")]: {
        flex: "0 0 100%",
        height: '81vh',
      }
    },

    map: {
      position: 'relative',
      flex: "0 0 68%",
      paddingLeft: '0.5rem',
      height: '69vh',
      [theme.breakpoints.down("sm")]: {
        display: 'none',
      }
    },

    listings: {
      height: '100%',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '10px',
        tranitionDuration: "1s"
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 10px 10px white',
        border: 'solid 3px #F2F0EC'
      },
      '&::-webkit-scrollbar-thumb': {
        boxShadow: 'inset 0 0 10px 10px rgba(0, 0, 0, 0.3)',
        border: 'solid 3px #F2F0EC'
      }

    },

    item: {
      padding: '1rem',
      textDecoration: 'none',
      backgroundColor: theme.colors.background.primary,
      margin: '0 0 1rem',
      '& :lastChild': {
        margin: '0'
      },
    },
    itemAddress: {
      lineHeight: 1.2,
    },
    active: {
      backgroundColor: '#978A5E',
      '& > div': {
        color: 'white'
      },
      '& > div > div': {
        color: 'white'
      }
    },
    button: {
      margin: `0`,
      padding: '1rem',
    },
    storeOpeningHours: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    storeHours: {
      color: theme.colors.secondary,
      fontSize: '80%',
      opacity: 0.75,
      display: 'flex',
  		alignItems: 'stretch',
  		justifyContent: 'space-between',
      lineHeight: 1.2,
      '& > span': {
        textAlign: 'right'
      }
    },
    popup: {
      padding: '5px 0',
      width: '200px',
      borderRadius: '10px'
    },
    companyName: {
      fontWeight: 'bold',
      marginBottom: '3px'
    },
    companyAddress: {
      lineHeight: 1.2,
    },
    searchForm :{
      textAlign:'center'

    },
    mapInput :{
      backgroundColor: 'theme.colors.border',
      // margin: '5px',
      padding: '3px 5px',

    },
    select: {
      marginTop: '1.5rem!important',
      marginLeft: "3rem",
      marginBottom: "2rem",
      textAlign:'left'
    },
    searchButton:{
      backgroundColor:'black',
      color: 'white',
      padding: '10px 15px',
      marginTop: '1.5rem!important',
      marginLeft: "3rem",

    }
  });
