import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { joinClasses } from '../../../../utils';
import styles from './styles';

const Wysiwyg = ({ classes, className, content }) => {
  const createMarkup = () => {
    return {__html: content};
  }
	return (
  <div
    className={joinClasses(
        classes.container,
        className
      )}
    dangerouslySetInnerHTML={createMarkup()}
  />
	);
};

Wysiwyg.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
  content: PropTypes.string
};

export default withStyles(styles)(Wysiwyg);
