import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
// import { SEO } from 'Bits';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom';
import numeral from 'numeral';

// import Layout from '../../../../layouts/primary';
import CheckoutLayout from '../../../../layouts/confirmation';
import Hero from '../../Heroes/OrderConfirmation';
import { joinClasses } from '../../../../utils';
import fetchDux from '../../../../store/reducers/fetch-dux';
import { Wysiwyg, SEO } from '../../../bits';
import DataController from "../../../../utils/data-controller";
import styles from './styles';
import Button from "../../../bits/Shared/Button";
import SilveroakLink from '../../../../utils/SilveroakLink'
import TwomeyLink from '../../../../utils/TwomeyLink'
import queryString from 'query-string';

const formatMoney = p => numeral(p || 0).format('$0,0.00');

const getImageHref = (
  {
    imageHref
  }
) => `${imageHref}`;

const url = '/wp-json/acf/v2/options/';

class Confirmation extends Component {
  constructor() {
    super();

    this.state = {
      data: {}
    };
  }

  componentDidMount() {
    const { order, clearGetCart } = this.props;
    clearGetCart();
    const fetchData = async () => {
      const res = await DataController.load(url);
      this.setState({
        data: res
      });
    };
    fetchData();
    addTracking(order);
  }

  componentWillUnmount() {
    this.props.clearPostCompleteOrder();
    window.location = window.REDIRECT_URL;
  }

  render() {
    const { classes, order, location: { search } } = this.props;

    const searchQuery = queryString.parse(search);
    const { clubJoin } = searchQuery || {};
    const isClubJoin = clubJoin === 'true';

    const {
      purchaseOrderId,
      total,
      tax,
      freight,
      subtotal,
      items,
      brandingId,
      totalMarkdown,
      customer,
      clubName,
      taxDetails,
      SetCoRetailTax
    } = order || {};
    const { taxBreakout } = taxDetails || {};
    const {
      CRVTax,
      CODeliveryFee,
      ExciseTax,
      WholesaleTax,
      SalesTax
    } = taxBreakout || {};
    const { email } = customer || {};
    const containsExciseOrWholeSale = (!!ExciseTax || !!WholesaleTax) ? true : false;

    if (!purchaseOrderId) return <Redirect to="/" />; // SDW TODO

    return (
      <>
        <SEO
          title='Order Confirmation'
          meta={null}
        />
        <CheckoutLayout id='confirmation' isClubJoin={isClubJoin}>
          <div className={classes.dark}>
            <div className={classes.subContainer}>
              <Hero
                image={this.state.data.acf?.order_confirm_hero_image}
                title={isClubJoin ? `Thank you for joining ${clubName} membership.` : this.state.data.acf?.order_confirm_heading}
                eyebrow={`Order #${purchaseOrderId}`}
                email={email}
              />
            </div>
          </div>

          <div className={classes.details}>
            <div className="container">
              <p className={joinClasses('h4', classes.detailsHeading)}>What's Next?</p>
              {/* <p className={joinClasses('h6', classes.subHeading)}>What's Next?</p> */}
              <div className={joinClasses('copy--large', classes.detailsBodyText)}>
                <p>Orders are processed within 24-48 hours of receipt. To ensure your wine’s integrity during extreme temperature, we may hold your shipment.</p>
              </div>
              <SilveroakLink>
                <Button className={classes.detailsButton} title="Shop Silver Oak" />
              </SilveroakLink>
              <TwomeyLink>
                <Button className={classes.detailsButton} title="Shop Twomey" />
              </TwomeyLink>
            </div>
          </div>

          <div className={classes.summary}>
            <div className="container">
              <p className={joinClasses('h6', classes.subHeading)}>Order Summary</p>
              <header className={classes.summaryHeader}>
                <div className={classes.summaryHeaderOrderNumber}>
                  <span className="eyebrow">Order No.</span>
                  <p className="copy--large">{purchaseOrderId}</p>
                </div>
                {/*
                <div className={classes.summaryHeaderDownloadCTA}>
                  <button type="button">Download Invoice</button>
                </div>
                */}
              </header>
              <div className={classes.items}>
                {(items || []).map((item, i) => (
                  <div key={i} className={classes.item}>
                    <div className={classes.itemImageContainer}>
                      {brandingId === 2 && (
                        <img
                          src={getImageHref({ imageHref: item.imageHref, clubContinuityTypeId: item.clubContinuityTypeId })}
                          alt="Twomey"
                        />
                      )}
                      {brandingId === 1 && (
                        <img
                          src={getImageHref({ imageHref: item.imageHref, clubContinuityTypeId: item.clubContinuityTypeId })}
                          alt="SilverOak"
                        />
                      )}
                    </div>
                    <div className={classes.itemDescription}>
                      <p>{item.description}</p>
                      <p>Qty: {item.quantity}</p>
                    </div>
                    <div className={joinClasses(classes.itemPrice, 'copy--large')}>
                      <p>{formatMoney(((item.kitPrice || 0) * (item.quantity || 0)))}</p>
                      {item.discountPodMarkDown > 0 && (
                        <p className={classes.itemPriceDiscount}>-{formatMoney(item.discountPodMarkDown)}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className={classes.total}>
                <dl>
                  <div>
                    <dt className={joinClasses('eyebrow color--gray')}>Subtotal</dt>
                    <dd className="copy--large">{formatMoney(subtotal)}</dd>
                  </div>
                  <div>
                    <dt className={joinClasses('eyebrow color--gray')}>Shipping</dt>
                    <dd className="copy--large">{formatMoney(freight)}</dd>
                  </div>
                  {containsExciseOrWholeSale && <div>
                    <dt className={joinClasses('eyebrow color--gray')}>Sales Tax</dt>
                    <dd className="copy--large">{formatMoney(SalesTax || 0)}</dd>
                  </div>}
                  {!containsExciseOrWholeSale && <div>
                    <dt className={joinClasses('eyebrow color--gray')}>Tax</dt>
                    <dd className="copy--large">{formatMoney(CRVTax > 0 ? tax - CRVTax :
                      SetCoRetailTax ? tax - CODeliveryFee || 0 : tax)}</dd>
                  </div>}
                  {CRVTax > 0 && <div>
                    <dt className={joinClasses('eyebrow color--gray')}>CRV</dt>
                    <dd className="copy--large">{formatMoney(CRVTax)}</dd>
                  </div>}
                  {SetCoRetailTax && <div>
                    <dt className={joinClasses('eyebrow color--gray')}>CO Retail Delivery Fee</dt>
                    <dd className="copy--large">{formatMoney(CODeliveryFee || 0)}</dd>
                  </div>}
                  {WholesaleTax > 0 && <div>
                    <dt className={joinClasses('eyebrow color--gray')}>Wholesale Tax</dt>
                    <dd className="copy--large">{formatMoney(WholesaleTax || 0)}</dd>
                  </div>}
                  {ExciseTax > 0 && <div>
                    <dt className={joinClasses('eyebrow color--gray')}>Excise Tax </dt>
                    <dd className="copy--large">{formatMoney(ExciseTax || 0)}</dd>
                  </div>}
                  {totalMarkdown > 0 && (
                    <div>
                      <dt className={joinClasses('eyebrow color--gray', classes.itemPriceDiscount)}>Discount</dt>
                      <dd className="copy--large">{formatMoney(totalMarkdown)}</dd>
                    </div>
                  )}
                </dl>
                <dl className="copy--large">
                  <div>
                    <dt>Grand Total</dt>
                    <dd>{formatMoney(total)}</dd>
                  </div>
                </dl>
              </div>
            </div>
            {this.state.data.acf?.order_confirm_help_text && (
              <div className={classes.helpText}>
                <div className="container">
                  <Wysiwyg
                    className={joinClasses(classes.helpTextBody, 'copy--small')}
                    content={this.state.data.acf?.order_confirm_help_text}
                  />
                </div>
              </div>
            )}
          </div>
          {/* {this.state.data.acf && (
            <ThreeUpCardsBucket
              isStatic={true}
              data={{}}
            />
          )} */}
        </CheckoutLayout>
      </>
    );
  }
}

const mapState = state => {
  const {
    result: order
  } = state.fetchReducer.postCompleteOrder.toJS();
  return {
    order
  }
}

const mapDispatch = dispatch => bindActionCreators({
  clearPostCompleteOrder: fetchDux.postCompleteOrder.clearAction,
  clearGetCart: fetchDux.getCart.clearAction
}, dispatch);

const StyledApp = withStyles(styles)(Confirmation);

export default connect(mapState, mapDispatch)(StyledApp);


const addTracking = (order) => {

  const { purchaseOrderId, total, freight, tax, items, brandingId } = order || {};

  const getAffiliation = (id) => {
    if (id === 1) {
      return "Silver Oak";
    } else if (id === 2) {
      return "Twomey";
    }
  }

  if (window && window.dataLayer) {
    const transactionProducts = (items || []).map(({
      quantity,
      productId,
      description,
      kitPrice,
      ProductBrandingId,
      ProductBrandingName,
      discount,
      categories
    }) => {

      const itemCategories = (categories || []).map(({ productCategoryName }, index) => ({
        [`item_category${index + 1}`]: productCategoryName
      }));

      return ({
        item_id: productId ?? '',
        item_name: description ?? '',
        affiliation: getAffiliation(ProductBrandingId),
        currency: "USD",
        item_brand: ProductBrandingName,
        ...(itemCategories.length > 0 && itemCategories.reduce((acc, item) => ({ ...acc, ...item }))),
        discount: discount ?? 0,
        price: kitPrice ?? 0,
        quantity
      });
    })

    const gaEcomm = {
      event: 'purchase',
      ecommerce: {
        transaction_id: purchaseOrderId ?? '',
        affiliation: getAffiliation(brandingId),
        currency: 'USD',
        value: total ?? 0,
        tax: tax ?? 0,
        shipping: freight ?? 0,
        items: transactionProducts
      }
    }
    window.dataLayer.push(gaEcomm);
  }
}
