import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from '../../utils/history';
import { reducer as formReducer } from 'redux-form';
import sampleReducer from './sampleReducer';
import { fetchReducer } from './fetchDux/helpers';
import billingReducer from './billingReducer'
import accountReducer from './accountReducer'

export default combineReducers({
  router: connectRouter(history),
  sampleReducer,
  form: formReducer,
  fetchReducer,
  billing: billingReducer,
  account: accountReducer
});
