import React from 'react';
import ReactModal from 'react-modal';
import { withStyles } from '@material-ui/styles';
import close from '../../../../assets/vectors/Close.svg';
import PickUpInfoForm from '../../Forms/FedexPickUpInfo';
import { joinClasses } from '../../../../utils';
import styles from './styles';

ReactModal.setAppElement(document.getElementById('root'));

const FedexPickUpInfoModal = ({
  classes,
  isOpen,
  onClose,
  onSavePickUpInfo,
  onHandleGoBackToLoactionMap,
  selectedFedexLocation
}) => {

  return (
    <ReactModal
      isOpen={isOpen}
      className={classes.container}
      overlayClassName="react-modal"
    >
      <p className={joinClasses('h5', classes.heading)}>
        Recipient Information
      </p>
      <PickUpInfoForm
        selectedFedexLocation={selectedFedexLocation}
        onSavePickUpInfo={onSavePickUpInfo}
        onHandleGoBackToLoactionMap={onHandleGoBackToLoactionMap}
        classes={classes}
      />
      <button type="button" onClick={onClose} className={classes.close}>
        <img src={close} alt="Close overlay" />
      </button>
    </ReactModal>
  );
};

export default withStyles(styles)(FedexPickUpInfoModal);
