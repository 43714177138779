import React, { Component } from 'react';
import { joinClasses } from "../../../../utils";

class SoldOutItem extends Component {
  constructor(props) {
    super(props);
    this.state = { soldOutNotified: [] }
  }
  componentDidMount() {
    try {
      const payload = sessionStorage.getItem('soldOutNotified')
      const soldOutNotified = JSON.parse(payload) || [];
      this.setState({ soldOutNotified });
    } catch (err) {
      //noop
    }
  }
  render() {
    const {
      classes,
      soldOutItems
    } = this.props;

    const { soldOutNotified } = this.state;

    const items = (soldOutItems || [])
      .filter(({ shoppingCartItemId }) => !soldOutNotified.some(s => s === shoppingCartItemId));

    const showMessage = items.length > 0;

    if (!showMessage) return false;

    return (
      <p className={joinClasses(classes.headline)} style={{ fontWeight: '500', marginTop: '10px' }}>
        An item in your cart is no longer available and has been removed
      </p>
    )
  }
}

export default SoldOutItem;