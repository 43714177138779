exports.handler = (countryId) =>
(value, previousValue, allValues) => {
  if (!value) return value;
  if (Number(countryId) !== 37 && !!countryId) return value;
  if (/^\(\d{1,3}\) \d{1,3}-\d{1,4}$/.test(value)) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return `(${onlyNums})`;
    }
    if (onlyNums.length === 6) {
      return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}-`;
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
  }
  if(onlyNums.length > 10) return `(${onlyNums.slice(onlyNums.length-10, onlyNums.length-7)}) ${onlyNums.slice(onlyNums.length-7, onlyNums.length-4)}-${onlyNums.slice(onlyNums.length-4, onlyNums.length)}`;
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
  };