import { createStyles } from '@material-ui/core';

export default theme =>
  createStyles({
    media: {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
  
      '& iframe': {
        width: '100%',
        height: '100%'
      }
    },
  });
