import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { formValueSelector, isInvalid, hasSubmitFailed, change, getFormSyncErrors, getFormAsyncErrors } from 'redux-form';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { SEO } from '../../../bits';
import { MenuItem, InputLabel, FormControl, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';

import fetchDux from '../../../../store/reducers/fetch-dux';
import { getCheckZip, validateEmail } from '../../../../store/reducers/fetchDux/api';

import Select from '../../../bits/Shared/Select';
import Button from '../../../bits/Shared/Button';
import ValidateAddressModal from '../../Modals/Account/ValidateAddress';
import WhereWeShipModal from '../../Modals/WhereWeShip';
import FedexLocationsModal from '../../Modals/FedexLocations';
import LocationLoading from '../../Modals/FedexLocations/LocationLoading';
import FedexPickUpInfoModal from '../../Modals/FedexPickUpInfo';

import { joinClasses } from '../../../../utils';
import PickupOakville from './Pickup/Oakville';
import PickupHealdsburg from './Pickup/Healdsburg';
import PickupTWHealdsburg from './Pickup/TWHealdsburg';
import PickupTWCalistoga from './Pickup/TWCalistoga';
import CheckoutLayout from '../../../../layouts/checkout';
import { Form, Field, FieldError, validate } from '../../../../utils/forms';
import styles from '../styles';
import Delivery from './Delivery'
import Gift from './Gift'
import queryString from 'query-string';

import {
  getInitialValues,
  DELIVER,
  PICKUP_OAKVILLE,
  PICKUP_HEALDSBURG,
  PICKUP_TW_CALISTOGA,
  PICKUP_TW_HEALDSBURG,
  getInitAddressId,
  PICKUP_FEDEX
} from '../../../../utils/checkout'

const FORM_NAME = 'checkout-shipping';

const twomeyWebsiteLink = process.env.REACT_APP_TWOMEY_LINK;

const formatPayload = ({
  shipViaId,
  deliveryMethod,
  requestedShipDate,
  ...rest
}) => {
  const days = (deliveryMethod === DELIVER || deliveryMethod === PICKUP_FEDEX) ? shipViaId === 9 ? 1 : 2 : 0;
  return {
    ...rest,
    deliveryMethod,
    shipViaId,
    requestedShipDate: moment(requestedShipDate).subtract(days || 0, 'days').format('MM/DD/YYYY')
  }
}

const validate100 = validate.characters(100);

const getIsAbleToShip = ({ selectedStateId, hasAlcohol, offSiteShipping }) => {
  if (!selectedStateId || !hasAlcohol) return true;
  return offSiteShipping;
}

const getMinPickupDate = ({ defaultShipDate }) => {
  const limit = defaultShipDate && moment(defaultShipDate, 'MM/DD/YYYY').isValid() ? moment(defaultShipDate, 'MM/DD/YYYY') : moment();
  return limit.add(2, 'days').toDate();
}

class Shipping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validateModalActive: false,
      WhereWeShipActive: false,
      noAddress: false,
      selectedFedexLocation: {},
      finalPickUpInfo: {},
      pickupInfoModalActive: false,
      FedexLocationsModalAcitve: true,
      changeLocation: false,
      searchZip: null,
      searchDistance: 10,
      zipError: null,
      trackingAdded: false
    };
    window.scrollTo(0, 0);
    props.clearGetCart();
    props.clearGetCartBilling();
    props.clearGetCartShipping();
  }

  componentDidMount() {
    const { location: { search } } = this.props;
    const searchQuery = queryString.parse(search);
    const { clubJoin } = searchQuery || {};
    const isClubJoin = clubJoin === 'true';
    this.setState({ isClubJoin });
    window.scrollTo(0, 0);
    this.props.validateLogin();
    this.props.getCart({ clubJoin: isClubJoin });
    this.props.getCartShipping({ clubJoin: isClubJoin });
    this.props.getLookups();
    this.props.clearPostValidateAddress();
    this.setState({
      validateModalActive: false,
      WhereWeShipActive: false,
      noAddress: false,
      pickupInfoModalActive: false,
      FedexLocationsModalAcitve: true,
      selectedFedexLocation: {},
      finalPickUpInfo: {},
      searchDistance: 10,
      zipError: null
    });

  }

  componentDidUpdate(prevProps) {
    const { results } = this.props;
    const { cart } = JSON.parse(results);
    if (this.props.results !== prevProps.results && cart?.shoppingCartItems?.length > 0 && !this.state.trackingAdded) {
      addTracking(cart, 'begin_checkout');
      this.setState({ trackingAdded: true });
    }
  }

  componentWillUnmount() {
    this.props.clearPostValidateAddress();
    this.setState({
      validateModalActive: false,
      WhereWeShipActive: false,
      noAddress: false,
      pickupInfoModalActive: false,
      FedexLocationsModalAcitve: true,
      selectedFedexLocation: {},
      finalPickUpInfo: {},
      searchDistance: 10,
      zipError: null
    })
  }

  render() {
    const {
      classes,
      method,
      isGift,
      results,
      inProgress,
      history,
      postCartShipping,
      postValidateAddress,
      // postCartShippingInProgress,
      clearPostValidateAddress,
      changeFormValue,
      company,
      address2,
      isFormInvalid,
      hasSubmitFailedValue,
      formErrors,
      formAsyncErrors,
      findFedexLocation,
      clearFindFedexLocation
    } = this.props;

    const {
      validateModalActive,
      WhereWeShipActive,
      noAddress,
      pickupInfoModalActive,
      selectedFedexLocation,
      finalPickUpInfo,
      FedexLocationsModalAcitve,
      changeLocation,
      searchZip,
      searchDistance,
      zipError
    } = this.state;

    const {
      lookups,
      shipping,
      cart,
      user,
      addressBookId,
      formValues,
      postValidateAddressResult,
      postValidateAddressInProgress,
      postCartShippingError,
      findFedexLocationError,
      findFedexLocationResult,
      findFedexLocationInProgress
    } = JSON.parse(results);
    const scrollToTop = () => window.scrollTo(0, 0);

    const { isClubJoin } = this.state

    // if (getCartError) return <Redirect to='/' />;

    const hasAccount = user && user.userId;
    const { AddressBook } = user || {};

    const {
      shoppingCartItems,
      shipper,
      clubMembershipRemoved,
      brandingId,
      pickUpPersonAddress
    } = cart || {};

    const hasAlcohol = (shoppingCartItems || []).some(x => x.alcoholVolume);
    const requiresShipDate = (shoppingCartItems || [])
      .some(x => x.requiresShipDate);

    const { clubContinuityTypeId: clubTypeId } = ((shoppingCartItems || []).find(item => item.clubContinuityTypeId > 0) || {})

    const { defaultShipDate, states, shipDates, blackoutDates } = lookups || {};
    const { stateId: selectedStateId, makeDefaultShipping: isMarkedSaveDefault } = formValues || {};
    // TODO email
    const initialValues = getInitialValues({
      AddressBook,
      loggedIn: hasAccount,
      shipper,
      shipping,
      cart,
      requiresShipDate,
      shipDates,
      defaultShipDate,
      blackoutDates,
      method // TODO
    });
    const handlePostCartShipping = payload => {
      const {
        address1,
        address2,
        city,
        name_first,
        name_last,
        zip,
        stateId,
        deliveryMethod
      } = payload || {};

      if (deliveryMethod !== DELIVER && deliveryMethod !== PICKUP_FEDEX) {
        //remove company that could be saved if customer selected Fedex HAL previously
        const updatePayload = Object.assign({}, payload, { company: null })
        onSubmitShipping(updatePayload);
        return;
      }
      const { pickUpPerson, selectedFedexLocation } = finalPickUpInfo || {};
      //if slected pick up at fedex, we will use fedex location address as shipping address on submit shipping
      if (deliveryMethod === PICKUP_FEDEX && pickUpPerson) {
        const { name_first, name_last, phone } = pickUpPerson || {};
        const { address, contact, locationId } = selectedFedexLocation || {};
        const { city, postalCode, stateOrProvinceCode, streetLines } = address || {};
        const { companyName } = contact || {};
        const { stateId } = (states || []).find(
          ({ stateShort }) => (stateShort === stateOrProvinceCode)) || {};

        const fedexPickUpPayload = Object.assign(
          {},
          payload,
          {
            name_first,
            name_last,
            phone,
            isHoldAtFedex: true,
            company: companyName,
            address1: (streetLines || [])[0],
            address2: (streetLines || [])[1],
            city,
            stateShort: stateOrProvinceCode,
            stateId,
            zip: postalCode,
            stateId,
            fedexLocationId: locationId,
            pickUpPersonAddress: { ...pickUpPerson, stateId }
          }
        )
        onSubmitShipping(fedexPickUpPayload);
        return;
      };
      //this is for if the customer go back to shipping page and did change the selected pick up location, but changed shipping method, special instruction or gift notes...
      if (deliveryMethod === PICKUP_FEDEX && !pickUpPerson) {
        if (!initialValues.isHoldAtFedex) {
          this.setState({ zipError: 'Please select a Fedex pick up location' });
          return;
        }
        const updatePayload = Object.assign({},
          initialValues,
          {
            requestedShipDate: formValues.requestedShipDate,
            shipViaId: formValues.shipViaId,
            specialInstructions: formValues.specialInstructions,
            isGift: formValues.isGift,
            giftName: formValues.giftName,
            giftNote: formValues.giftNote,
            giftRecipientEmail: formValues.giftRecipientEmail,
            giftSenderName: formValues.giftSenderName,
            pickUpPersonAddress
          })
        onSubmitShipping(updatePayload);
        return;
      };
      if (!address1 && !name_first) {
        this.setState({ noAddress: true });
        return
      }

      this.setState({ noAddress: false });

      this.setState({ validateModalActive: true });

      postValidateAddress({
        address1,
        address2,
        city,
        name_first,
        name_last,
        zip,
        stateId
      });

    };

    const onSubmitShipping = values => {
      scrollToTop();
      const payload = formatPayload(values);
      postCartShipping({ payload: { ...payload, clubJoin: isClubJoin } }, FORM_NAME, null, data => {
        const { clubMembershipRemoved } = data || {};
        scrollToTop();
        addTracking(cart, 'add_shipping_info');
        if (clubMembershipRemoved) return;
        history.push(`/billing?clubJoin=${isClubJoin}`);
      });
      scrollToTop();
    };

    const handleValidateSubmit = (selected, formValues, suggestion) => {
      scrollToTop();
      this.setState({ validateModalActive: false })
      const payload = selected === '1' ? formValues : ({ ...formValues, ...(suggestion || {}) })
      onSubmitShipping(payload);
      scrollToTop();
    }

    const daysToShip = (method !== DELIVER && method !== PICKUP_FEDEX) ? 0 : formValues.shipViaId === 9 ? 1 : 2;
    const { defaultValue: defaultShipDateValue } = defaultShipDate || {};

    // initialValues.deliveryMethod = initialValues.deliveryMethod || 'DELIVER';

    const handleAddressBookChange = addressId => {

      const address = (AddressBook || [])
        .find(({ AddressBookID }) => AddressBookID === parseInt(addressId)) || {};

      const {
        FirstName: name_first,
        LastName: name_last,
        Company: company,
        Address1: address1,
        Address2: address2,
        City: city,
        Zip: zip,
        Phone: phone,
        StateID: stateId
      } = address || {};

      changeFormValue(FORM_NAME, 'addressBookId', parseInt(addressId));
      changeFormValue(FORM_NAME, 'name_first', name_first);
      changeFormValue(FORM_NAME, 'name_last', name_last);
      changeFormValue(FORM_NAME, 'company', company);
      changeFormValue(FORM_NAME, 'address1', address1);
      changeFormValue(FORM_NAME, 'address2', address2);
      changeFormValue(FORM_NAME, 'city', city);
      changeFormValue(FORM_NAME, 'zip', zip);
      changeFormValue(FORM_NAME, 'phone', phone);
      changeFormValue(FORM_NAME, 'stateId', stateId);
    };

    const handleDeliveryMethodChange = e => {
      const { value } = e.target || {};
      changeFormValue(FORM_NAME, 'deliveryMethod', value)
      if (value === DELIVER) {
        changeFormValue(FORM_NAME, 'shipViaId', 1);
        changeFormValue(FORM_NAME, 'addressBookId', null);
        changeFormValue(FORM_NAME, 'company', '');
        changeFormValue(FORM_NAME, 'address1', '');
        changeFormValue(FORM_NAME, 'address2', '');
        changeFormValue(FORM_NAME, 'city', '');
        changeFormValue(FORM_NAME, 'zip', '');
        changeFormValue(FORM_NAME, 'phone', '');
        changeFormValue(FORM_NAME, 'stateId', null);
        return;
      }
      if (value === PICKUP_FEDEX) {
        changeFormValue(FORM_NAME, 'shipViaId', 1);
        changeFormValue(FORM_NAME, 'addressBookId', null);
        changeFormValue(FORM_NAME, 'company', '');
        changeFormValue(FORM_NAME, 'address1', '');
        changeFormValue(FORM_NAME, 'address2', '');
        changeFormValue(FORM_NAME, 'city', '');
        changeFormValue(FORM_NAME, 'zip', '');
        changeFormValue(FORM_NAME, 'phone', '');
        changeFormValue(FORM_NAME, 'stateId', null);
        return
      }
      changeFormValue(FORM_NAME, 'shipViaId', value);
    };
    const clearAddress = () => {
      changeFormValue(FORM_NAME, 'name_first', '');
      changeFormValue(FORM_NAME, 'name_last', '');
      changeFormValue(FORM_NAME, 'company', '');
      changeFormValue(FORM_NAME, 'address1', '');
      changeFormValue(FORM_NAME, 'address2', '');
      changeFormValue(FORM_NAME, 'city', '');
      changeFormValue(FORM_NAME, 'zip', '');
      changeFormValue(FORM_NAME, 'phone', '');
      changeFormValue(FORM_NAME, 'stateId', null);
      changeFormValue(FORM_NAME, 'makeDefaultShipping', false);
      changeFormValue(FORM_NAME, 'saveAddress', false);
    };
    const setNewAddress = () => {
      const addressBookIdMatch = getInitAddressId(AddressBook, formValues);
      changeFormValue(FORM_NAME, 'addressBookId', addressBookIdMatch)
    }

    const onMakeDefaultAddress = (value) => {
      changeFormValue(FORM_NAME, 'saveAddress', value);
    }

    const closeModalValidate = () => {
      this.setState({ validateModalActive: false });
    };
    const closeModalWhereWeShip = () => {
      this.setState({ WhereWeShipActive: false });
    };
    const closeModalFedexLocations = () => {
      this.setState({ searchZip: null, searchDistance: 10 });
      clearFindFedexLocation();
    };
    const closeFedexPickUpInfoModal = () => {
      this.setState({ pickupInfoModalActive: false });
      this.setState({ FedexLocationsModalAcitve: true });

    };
    const openWhereWeShip = () => {
      this.setState({ WhereWeShipActive: true });
    };

    const { offSiteShipping } = (states || [])
      .find(({ stateId: lookupStateId }) => (lookupStateId === selectedStateId)) || {};

    const change = (name, value) => changeFormValue(FORM_NAME, name, value);

    const isAbleToShip = getIsAbleToShip({ selectedStateId, hasAlcohol, offSiteShipping })

    const validState = () => isAbleToShip ? undefined : 'Invalid State';

    const addressInvalid =
      Object.keys(formErrors || {}).some(e => ['address1', 'city', 'name_first', 'name_last', 'phone', 'stateId', 'zip'].some(x => x === e))
      || Object.keys(formAsyncErrors || {}).some(e => ['address1', 'city', 'name_first', 'name_last', 'phone', 'stateId', 'zip'].some(x => x === e))

    const minPickupDate = getMinPickupDate({ defaultShipDate: defaultShipDateValue })

    const validateZip = async (values) => {

      const { zip, deliveryMethod, email } = values;

      if (!hasAccount) {
        if (!email) throw { email: 'Required' };
        if (isClubJoin) {
          const emailValidationResult = await validateEmail(email);
          if (emailValidationResult.err) {
            return;
          }
          const { exists } = emailValidationResult;
          if (exists) {
            this.setState({ existingAccount: true })
            throw { email: '' }
          }
        }
      }
      const isDelivery = deliveryMethod === DELIVER;
      if (!isDelivery) return;
      if (!zip) {
        this.setState({ zipError: 'Please enter or select an address with correct zip code' })
        throw { zip: 'Required' }
      };
      const result = await getCheckZip(zip);
      if (result.err) {
        return;
      }
      if (!result.valid) {
        this.setState({ zipError: 'Invalid Zip Code' })
        throw { zip: 'Invalid Zip Code' };
      }
    };
    const link = isClubJoin ?
      `${twomeyWebsiteLink}/shop/login?clubType=${clubTypeId}`
      :
      `${twomeyWebsiteLink}/shop/login`

    const onSelectLocation = (selected) => {
      this.setState({ selectedFedexLocation: selected });
      this.setState({ FedexLocationsModalAcitve: false });
      this.setState({ pickupInfoModalActive: true });
    };
    const onSearchZip = (zip) => {
      this.setState({ searchZip: zip });
    };
    const onChangeDistance = (value) => {
      this.setState({ searchDistance: value });
    };

    const onHandleGoBackToLoactionMap = () => {
      this.setState({ FedexLocationsModalAcitve: true });
      this.setState({ selectedFedexLocation: null });
      this.setState({ pickupInfoModalActive: false });
    };

    const onSavePickUpInfo = (payload) => {
      this.setState({ pickupInfoModalActive: false });
      this.setState({ FedexLocationsModalAcitve: false });
      this.setState({ selectedFedexLocation: null });
      this.setState({ changeLocation: false });
      this.setState({ finalPickUpInfo: payload });
      clearFindFedexLocation();
    };

    const onChangeSelectedFDXLocation = () => {
      this.setState({ finalPickUpInfo: null, changeLocation: true });
      this.setState({ FedexLocationsModalAcitve: true });
    };
    return (
      <>
        <SEO
          title='Shipping'
          meta={null}
        />
        <CheckoutLayout id='shipping' inProgress={inProgress} isClubJoin={isClubJoin}>
          <Form
            form={FORM_NAME}
            onSubmit={handlePostCartShipping}
            initialValues={initialValues}
            asyncBlurFields={hasAccount ? ['zip'] : ['zip', 'email']}
            asyncValidate={validateZip}
            enableReinitialize
          // destroyOnUnmount={false}
          >
            <div className='shipping'>
              <div className={classes.container}>
                <h2 className={joinClasses('h4', classes.headline)}>
                  <Link to={`/confirmation?clubJoin=${isClubJoin}`}>Delivery or Pick Up</Link>
                </h2>
                {!hasAccount && (
                  <>
                    <h6 className={joinClasses('h6', classes.subHeadline)}>Email Address</h6>
                    <Field name='email'
                      validate={[validate.required, validate.validEmail, validate100]}
                    >
                      <TextField
                        label='Email Address'
                        className={joinClasses(classes.evenChild, classes.textField)}
                        required
                        sdwinput
                      />
                      <FieldError sdwerror />
                    </Field>
                    {this.state.existingAccount &&
                      <p
                        style={{ color: 'red' }}
                      >
                        Our records indicate that the provided email is
                        already associated with an account,
                        please <a href={link} style={{ textDecoration: 'underline' }}>login</a> to continue.
                      </p>
                    }
                  </>
                )}
                <div className={classes.mobileSpacer} />
                <h6 className={joinClasses('h6', classes.subHeadline)}>Choose an option</h6>
                <FormControl className={classes.selectDelivery}>
                  <Field name='deliveryMethod' validate={[validate.required]} onChange={handleDeliveryMethodChange}>
                    <InputLabel
                      htmlFor='shipping-method'
                      className='eyebrow color--gray'
                      sdwlabel
                      style={{ width: '100%' }}
                    >
                      {/* Options */}
                      Ship, Pick Up At The Winery or Local FedEx Retail Location
                    </InputLabel>
                    <Select sdwinput value={DELIVER} required className={classes.select}>
                      <MenuItem key={DELIVER} value={DELIVER}>
                        Deliver to Address
                      </MenuItem>
                      <MenuItem key={PICKUP_FEDEX} value={PICKUP_FEDEX}>
                        Pick Up at FedEx Retail Location
                      </MenuItem>
                      {/* TODO temp comment out pickup. Test requiresShipDate validation */}
                      <MenuItem key={PICKUP_OAKVILLE} value={PICKUP_OAKVILLE}>
                        Pick Up at Silver Oak, Oakville
                      </MenuItem>
                      <MenuItem key={PICKUP_HEALDSBURG} value={PICKUP_HEALDSBURG}>
                        Pick Up at Silver Oak, Healdsburg
                      </MenuItem>
                      <MenuItem key={PICKUP_TW_CALISTOGA} value={PICKUP_TW_CALISTOGA}>
                        Pick Up at Twomey, Calistoga
                      </MenuItem>
                      <MenuItem key={PICKUP_TW_HEALDSBURG} value={PICKUP_TW_HEALDSBURG}>
                        Pick Up at Twomey, Healdsburg
                      </MenuItem>
                      {/* options below have been removed by client request */}
                      {/* <MenuItem key={PICKUP_TW_PHILO} value={PICKUP_TW_PHILO}>
                        Pick Up at Twomey, Philo
                      </MenuItem>
                      <MenuItem key={PICKUP_TW_DUNDEE} value={PICKUP_TW_DUNDEE}>
                        Pick Up at Twomey, Dundee (OR)
                      </MenuItem> */}
                    </Select>
                    <FieldError sdwerror />
                  </Field>
                </FormControl>

                <Delivery
                  user={user}
                  change={change}
                  hasAccount={hasAccount}
                  method={method}
                  classes={classes}
                  lookups={lookups}
                  company={company}
                  address2={address2}
                  addressBookId={addressBookId}
                  setNewAddress={setNewAddress}
                  clearAddress={clearAddress}
                  handleAddressBookChange={handleAddressBookChange}
                  formValues={formValues}
                  daysToShip={daysToShip}
                  defaultShipDate={defaultShipDateValue}
                  saveAsDefaultAllowed
                  openWhereWeShip={openWhereWeShip}
                  validState={validState}
                  addressInvalid={addressInvalid}
                  brandingId={brandingId}
                  requiresShipDate={requiresShipDate}
                  onMakeDefaultAddress={onMakeDefaultAddress}
                  isMarkedSaveDefault={isMarkedSaveDefault}
                  isClubJoin={isClubJoin}
                  findFedexLocation={findFedexLocation}
                  finalPickUpInfo={finalPickUpInfo}
                  findFedexLocationError={findFedexLocationError}
                  findFedexLocationResult={findFedexLocationResult}
                  findFedexLocationInProgress={findFedexLocationInProgress}
                  onChangeSelectedFDXLocation={onChangeSelectedFDXLocation}
                  initialValues={initialValues}
                  changeLocation={changeLocation}
                  hasAlcohol={hasAlcohol}
                  onSearchZip={onSearchZip}
                  onChangeDistance={onChangeDistance}
                />
                <PickupOakville
                  brandingId={brandingId}
                  lookups={lookups}
                  formValues={formValues}
                  requiresShipDate={requiresShipDate}
                  minPickupDate={minPickupDate}
                  isActive={method === PICKUP_OAKVILLE}
                  isClubJoin={isClubJoin}
                />
                <PickupHealdsburg
                  brandingId={brandingId}
                  lookups={lookups}
                  formValues={formValues}
                  requiresShipDate={requiresShipDate}
                  minPickupDate={minPickupDate}
                  isActive={method === PICKUP_HEALDSBURG}
                  isClubJoin={isClubJoin}
                />
                {/* todo */}
                <PickupTWCalistoga
                  brandingId={brandingId}
                  lookups={lookups}
                  formValues={formValues}
                  requiresShipDate={requiresShipDate}
                  minPickupDate={minPickupDate}
                  isActive={method === PICKUP_TW_CALISTOGA}
                  isClubJoin={isClubJoin}
                />
                <PickupTWHealdsburg
                  brandingId={brandingId}
                  lookups={lookups}
                  formValues={formValues}
                  requiresShipDate={requiresShipDate}
                  minPickupDate={minPickupDate}
                  isActive={method === PICKUP_TW_HEALDSBURG}
                  isClubJoin={isClubJoin}
                />
                {/* options below have been removed by client request */}
                {/* <PickupTWPhilo
                  brandingId={brandingId}
                  lookups={lookups}
                  formValues={formValues}
                  requiresShipDate={requiresShipDate}
                  minPickupDate={minPickupDate}
                  isActive={method === PICKUP_TW_PHILO}
                  isClubJoin={isClubJoin}
                />
                <PickupTWDundee
                  brandingId={brandingId}
                  lookups={lookups}
                  formValues={formValues}
                  requiresShipDate={requiresShipDate}
                  minPickupDate={minPickupDate}
                  isActive={method === PICKUP_TW_DUNDEE}
                  isClubJoin={isClubJoin}
                /> */}
              </div>
              {
                !isClubJoin &&
                <Gift
                  method={method}
                  classes={classes}
                  isGift={isGift}
                />
              }

              <div className={classes.buttonContainer}>
                <Button
                  id={(method === DELIVER || method === PICKUP_FEDEX) ? 'delivery-submit' : 'pickup-submit'} // DO NOT DELETE - GTM tracking
                  type='submit'
                  title='Next: Payment'
                  disabled={!isAbleToShip}
                />
                {noAddress && (
                  <p style={{ color: 'red' }}>
                    {user ? 'Please select or enter a new address before continuing.' : 'Please enter an address before continuing.'}
                  </p>
                )}
                {clubMembershipRemoved && (
                  <p style={{ color: 'red' }}>
                    Our records indicate you were previously a member of Club Twomey. If you wish to re-join, please contact us at (888) 202-2006.
                  </p>
                )}
                {postCartShippingError && (
                  <p style={{ color: 'red' }}>
                    An error occurred submitting shipping. If the issue persists, please contact us at (888) 202-2006.
                  </p>
                )}
                {
                  this.state.existingAccount &&
                  <p
                    style={{ color: 'red' }}
                  >
                    Our records indicate that the provided email is
                    already associated with an account,
                    please <a href={link} style={{ textDecoration: 'underline' }}>login</a> to continue.
                  </p>
                }
                {isFormInvalid && hasSubmitFailedValue &&
                  !this.state.existingAccount && (
                    <p style={{ color: 'red' }}>
                      Missing required information. Please check form and resubmit.
                    </p>
                  )
                }
                {!isAbleToShip && (
                  <p style={{ color: 'red' }}>
                    We are unable to ship to the selected state.
                  </p>
                )}
                {zipError && (
                  <p style={{ color: 'red' }}>
                    {zipError}
                  </p>
                )}
                <p className='copy--large'>
                  <strong>Important Notice</strong>
                </p>
                <p className='copy--small'>
                  Alcohol can only be shipped to an address where an individual at least
                  21 years old can sign for the package during regular business hours.
                  The carrier will make 3 delivery attempts, at which point the package
                  will be returned. We are happy to re-ship returned packages, however
                  an additional shipping fee will incur.
                </p>
                <p className='copy--small'>
                  We ship all year around, however during extreme hot or cold weather,
                  shipments may be delayed to protect the integrity of your package.
                  Please contact us at (888) 202-2006, Monday - Friday, 8am - 5pm (PT)
                  for alternate shipping options.
                </p>
              </div>
            </div>
            <ValidateAddressModal
              isOpen={validateModalActive}
              onClose={closeModalValidate}
              context='shipping'
              formValues={formValues}
              validateResult={postValidateAddressResult}
              clearPostValidateAddress={clearPostValidateAddress}
              inProgress={postValidateAddressInProgress}
              handleValidateSubmit={handleValidateSubmit}
              states={states}
              hasAlcohol={hasAlcohol}
            />
            <WhereWeShipModal
              isOpen={WhereWeShipActive}
              onClose={closeModalWhereWeShip}
              inProgress={inProgress}
              states={states}
            />
            {findFedexLocationInProgress &&
              <LocationLoading
                classes={classes}
                findFedexLocationError={findFedexLocationError}
                findFedexLocationInProgress={findFedexLocationInProgress}
              />
            }
            {(findFedexLocationResult || []).length > 0 &&
              <FedexLocationsModal
                isOpen={FedexLocationsModalAcitve}
                onClose={closeModalFedexLocations}
                findFedexLocationResult={findFedexLocationResult}
                onSelectLocation={onSelectLocation}
                searchZip={searchZip}
                onSearchZip={onSearchZip}
                findFedexLocation={findFedexLocation}
                searchDistance={searchDistance}
                onChangeDistance={onChangeDistance}
                hasAlcohol={hasAlcohol}
                states={states}
              />
            }

          </Form>
          <FedexPickUpInfoModal
            isOpen={pickupInfoModalActive}
            onHandleGoBackToLoactionMap={onHandleGoBackToLoactionMap}
            selectedFedexLocation={selectedFedexLocation}
            onClose={closeFedexPickUpInfoModal}
            onSavePickUpInfo={onSavePickUpInfo}
          />
        </CheckoutLayout>
      </>
    )
  }
}

const selector = formValueSelector(FORM_NAME);
const isInvalidSelector = isInvalid(FORM_NAME);
const hasSubmitFailedSelector = hasSubmitFailed(FORM_NAME);
const getFormSyncErrorsSelector = getFormSyncErrors(FORM_NAME);
const getFormAsyncSyncErrorsSelector = getFormAsyncErrors(FORM_NAME);

const mapState = state => {
  const method = selector(state, 'deliveryMethod');
  const isGift = selector(state, 'isGift');
  const company = selector(state, 'company');
  const address2 = selector(state, 'address2');
  const address1 = selector(state, 'address1');
  const name_first = selector(state, 'name_first');
  const name_last = selector(state, 'name_last');
  const city = selector(state, 'city');
  const zip = selector(state, 'zip');
  const stateId = selector(state, 'stateId');
  const phone = selector(state, 'phone');
  const email = selector(state, 'email');
  const shipViaId = selector(state, 'shipViaId');
  const requestedShipDate = selector(state, 'requestedShipDate');
  const specialInstructions = selector(state, 'specialInstructions');
  const giftSenderName = selector(state, 'giftSenderName');
  const giftName = selector(state, 'giftName');
  const giftRecipientEmail = selector(state, 'giftRecipientEmail');
  const giftNote = selector(state, 'giftNote');
  const makeDefaultShipping = selector(state, 'makeDefaultShipping');
  const saveAddress = selector(state, 'saveAddress');
  const addressBookId = selector(state, 'addressBookId');

  const formValues = {
    addressBookId,
    address1,
    address2,
    name_first,
    name_last,
    city,
    zip,
    stateId,
    phone,
    email,
    shipViaId,
    requestedShipDate,
    specialInstructions,
    isGift,
    giftSenderName,
    giftName,
    giftRecipientEmail,
    giftNote,
    makeDefaultShipping,
    saveAddress,
    company,
    deliveryMethod: method,
    method
  };


  const {
    result: lookups,
    inProgress: lookupsInProgress
  } = state.fetchReducer.getLookups.toJS();

  const {
    error: getCartError,
    inProgress: cartInProgress,
    result: cart
  } = state.fetchReducer.getCart.toJS();

  const {
    inProgress: shippingInProgress,
    result: shipping
  } = state.fetchReducer.getCartShipping.toJS();

  const {
    result: user,
    inProgress: userInProgress
  } = state.fetchReducer.postLogin.toJS();

  const {
    error: postCartShippingError,
    inProgress: postCartShippingInProgress
  } = state.fetchReducer.postCartShipping.toJS();

  const {
    result: postValidateAddressResult,
    inProgress: postValidateAddressInProgress
  } = state.fetchReducer.postValidateAddress.toJS();
  const {
    result: findFedexLocationResult,
    inProgress: findFedexLocationInProgress,
    error: findFedexLocationError
  } = state.fetchReducer.findFedexLocation.toJS();

  const inProgress = lookupsInProgress || cartInProgress || shippingInProgress || userInProgress || postCartShippingInProgress;

  const isFormInvalid = isInvalidSelector(state);
  const hasSubmitFailedValue = hasSubmitFailedSelector(state);
  const formErrors = getFormSyncErrorsSelector(state);
  const formAsyncErrors = getFormAsyncSyncErrorsSelector(state);

  return {
    method,
    isGift,
    inProgress,
    company: !!company,
    address2: !!address2,
    isFormInvalid,
    hasSubmitFailedValue,
    formErrors,
    formAsyncErrors,
    results: JSON.stringify({
      lookups,
      shipping,
      cart,
      user,
      getCartError,
      addressBookId,
      formValues,
      postValidateAddressResult,
      postValidateAddressInProgress,
      postCartShippingError,
      findFedexLocationError,
      findFedexLocationResult,
      findFedexLocationInProgress

    })
  }
}

const mapDispatch = dispatch => bindActionCreators({
  changeFormValue: change,
  getCart: fetchDux.getCart.createAction,
  postCartShipping: fetchDux.postCartShipping.createAction,
  getCartShipping: fetchDux.getCartShipping.createAction,
  getLookups: fetchDux.getLookups.createAction,
  postValidateAddress: fetchDux.postValidateAddress.createAction,
  clearPostValidateAddress: fetchDux.postValidateAddress.clearAction,
  clearGetCart: fetchDux.getCart.clearAction,
  clearGetCartBilling: fetchDux.getCartBilling.clearAction,
  clearGetCartShipping: fetchDux.getCartShipping.clearAction,
  validateLogin: fetchDux.validateLogin.createAction,
  findFedexLocation: fetchDux.findFedexLocation.createAction,
  clearFindFedexLocation: fetchDux.findFedexLocation.clearAction,


}, dispatch)


const StyledShipping = withStyles(styles)(Shipping);

export default connect(mapState, mapDispatch)(StyledShipping);

const getShippingTier = (shipViaId) => {
  const tierOptions = [
    { id: PICKUP_FEDEX, name: 'Pick Up at FedEx Retail Location' },
    { id: DELIVER, name: 'Deliver to Address' },
    { id: PICKUP_OAKVILLE, name: 'Pick Up at Silver Oak, Oakville' },
    { id: PICKUP_HEALDSBURG, name: 'Pick Up at Silver Oak, Healdsburg' },
    { id: PICKUP_TW_CALISTOGA, name: 'Pick Up at Twomey, Calistoga' },
    { id: PICKUP_TW_HEALDSBURG, name: 'Pick Up at Twomey, Healdsburg' },
    // { id: PICKUP_TW_PHILO, name: 'Pick Up at Twomey, Philo' },
    // { id: PICKUP_TW_DUNDEE, name: 'Pick Up at Twomey, Dundee (OR)' },
  ]
  if ([1, 9].includes(shipViaId)) {
    return `Fedex ${shipViaId}`;
  } else {
    return tierOptions.find(({ id }) => id === shipViaId)?.name;
  }
}

const addTracking = (cart, type) => {

  const { brandingId, totals, shoppingCartItems, shipViaId } = cart || {};
  const getAffiliation = (id) => {
    if (id === 1) {
      return "Silver Oak";
    } else if (id === 2) {
      return "Twomey";
    }
  }

  if (window && window.dataLayer) {
    const transactionProducts = (shoppingCartItems || []).map(({
      qty,
      kitId,
      descriptionShort,
      unitPrice,
      brandingId,
      brandingName,
      categories,
      discount,
    }) => {
      const itemCategories = (categories || []).map(({ productCategoryName }, index) => ({
        [`item_category${index + 1}`]: productCategoryName
      }));

      return ({
        item_id: kitId,
        item_name: descriptionShort,
        affiliation: getAffiliation(brandingId),
        currency: "USD",
        item_brand: brandingName,
        ...(itemCategories.length > 0 && itemCategories.reduce((acc, item) => ({ ...acc, ...item }))),
        discount: discount ?? 0,
        price: unitPrice,
        quantity: qty
      });
    })

    const gaEcomm = {
      event: type,
      ecommerce: {
        affiliation: getAffiliation(brandingId),
        currency: 'USD',
        ...(type === 'add_shipping_info' && { shipping_tier: getShippingTier(shipViaId) }),
        value: totals?.subTotal,
        items: transactionProducts
      }
    }
    window.dataLayer.push(gaEcomm);
  }
}
