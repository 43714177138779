import moment from 'moment';

export const DELIVER = 'DELIVER';
export const PICKUP_OAKVILLE = 17;
export const PICKUP_HEALDSBURG = 7;
export const PICKUP_TW_CALISTOGA = 8;
export const PICKUP_TW_HEALDSBURG = 12;
export const PICKUP_TW_PHILO = 33;
export const PICKUP_TW_DUNDEE = 34;
export const PICKUP_FEDEX = 'PICKUP_FEDEX';

const isValidShipDate = ({
  requestedShipDate,
  requiresShipDate,
  shipDates,
  defaultValue,
  IsWillCall,
  shipViaId
}) => {
  if (IsWillCall) return moment(requestedShipDate).isAfter(moment().startOf('day'))
  if (requiresShipDate) return (shipDates || [])
    .some(
      ({ requestedShipDate: d, shippingServiceId }) =>
        moment(d).format('MM/DD/YYYY') ===
        moment(requestedShipDate).format('MM/DD/YYYY') &&
        shippingServiceId === shipViaId
    );

  return moment(requestedShipDate).isAfter(moment().startOf('day')) && moment(requestedShipDate).isAfter(moment(defaultValue || new Date()).startOf('day'))
}

const getRequestedShipDate = (
  {
    requestedShipDate,
    defaultShipDate,
    requiresShipDate,
    shipDates,
    shipViaId,
    IsWillCall,
    brandingId,
    blackoutDates,
  }
) => {
  const { defaultValue } = defaultShipDate || {};
  const daysToShip = IsWillCall ? 0 : shipViaId === 9 ? 1 : 2;

  if (requestedShipDate && moment(requestedShipDate).isValid()) {
    const valid = isValidShipDate({
      requestedShipDate,
      requiresShipDate,
      shipDates,
      defaultValue,
      IsWillCall,
      shipViaId
    })
    if (valid) return moment(requestedShipDate).add(daysToShip, 'days').format('MM/DD/YYYY');
  }

  if (requiresShipDate) {
    const d = (shipDates || [])
      .find(({ shippingServiceId, brandingId: b }) => shippingServiceId === shipViaId && brandingId === b) || {};
    const { requestedShipDate: rsd } = d || {};
    if (rsd && moment(rsd).isValid()) return moment(rsd).format('MM/DD/YYYY');
  }

  if (!defaultValue || moment(defaultValue).isBefore(moment().startOf('day'))) return moment().add(daysToShip, 'days').format('MM/DD/YYYY')

  let firstAvailableDate = moment(defaultValue).add(daysToShip, 'days').format('MM/DD/YYYY');

  const isWeekend = (date) => {
    const momentDate = moment(date); // Convert to Moment.js object if not already
    const dayOfWeek = momentDate.day();
    return dayOfWeek === 6 || dayOfWeek === 0; // Saturday or Sunday
  };
  while (true) {
    const isInBlackoutDates = (blackoutDates || []).some(
      blackoutDate =>
        moment(blackoutDate.blackoutDate).startOf('day').isSame(firstAvailableDate, 'day')
    );

    const isWeekendDate = isWeekend(firstAvailableDate);

    // If the date is in blackout dates or a weekend, add one day
    if (isInBlackoutDates || isWeekendDate) {
      firstAvailableDate = moment(firstAvailableDate).add(1, 'day').toDate();
    } else {
      if (daysToShip === 2 && (moment(firstAvailableDate).day() === 1 || moment(firstAvailableDate).day() === 2)) {
        firstAvailableDate = moment(firstAvailableDate).add(1, 'day').toDate();
      } else {
        break;
      }
    }
  }
  return firstAvailableDate;
};

const getDOB = dob => {
  if (!dob || !moment(dob).isValid()) return null;
  const m = moment(dob);

  return {
    dob_month: m.month(),
    dob_day: m.date(),
    dob_year: m.year()
  };
};


const getInitialAddressValues = ({ shipping, loggedIn, AddressBook }) => {
  if (!loggedIn || !!shipping) {
    const {
      name_first,
      name_last,
      company,
      address1,
      address2,
      city,
      zip,
      phone,
      stateId,
      dob,
      email
    } = shipping || {};
    const addressBookId = getInitAddressId({ loggedIn, AddressBook, address: shipping })
    return {
      addressBookId,
      name_first,
      name_last,
      company,
      address1,
      address2,
      city,
      zip,
      phone,
      stateId,
      dob,
      email
    };
  }

  const {
    AddressBookID,
    FirstName: name_first,
    LastName: name_last,
    Company: company,
    Address1: address1,
    Address2: address2,
    City: city,
    Zip: zip,
    Phone: phone,
    StateID: stateId,
    DOB: dob
  } = (AddressBook || [])
    .find(({ IsDefaultShipping }) => IsDefaultShipping) || {};

  return {
    addressBookId: AddressBookID || '',
    name_first,
    name_last,
    company,
    address1,
    address2,
    city,
    zip,
    phone,
    stateId,
    dob
  };
};

export const getInitialValues = (
  {
    loggedIn,
    AddressBook,
    shipping,
    cart,
    defaultShipDate,
    shipper,
    requiresShipDate,
    shipDates,
    blackoutDates,
  }
) => {
  const {
    shipViaId,
    requestedShipDate,
    giftNote,
    giftName,
    giftSenderName,
    giftRecipientEmail,
    specialInstructions,
    brandingId,
    shippingAddress
  } = cart || {};
  const { isHoldAtFedex } = shipping || {};
  const { fedexLocationId } = shippingAddress || {};
  const { IsWillCall } = shipper || {};
  const {
    name_first,
    name_last,
    company,
    address1,
    address2,
    city,
    zip,
    phone,
    stateId,
    dob,
    email,
    addressBookId
  } = getInitialAddressValues({ loggedIn, shipping, AddressBook });

  const shipViaIdValue = shipViaId === 22 ? 1 : shipViaId || 1; // Special logic for auto-assign fedex ground

  const requestedShipDateValue = getRequestedShipDate({
    requestedShipDate,
    defaultShipDate,
    requiresShipDate,
    shipDates,
    shipViaId: shipViaIdValue,
    IsWillCall,
    brandingId,
    blackoutDates
  });

  const {
    dob_month,
    dob_day,
    dob_year
  } = getDOB(dob) || {};

  return {
    deliveryMethod: IsWillCall ? shipViaIdValue : isHoldAtFedex ? PICKUP_FEDEX : DELIVER,
    addressBookId,
    email,
    name_first,
    name_last,
    company,
    address1,
    address2,
    city,
    zip,
    phone,
    stateId,
    dob,
    shipViaId: shipViaIdValue,
    requestedShipDate: requestedShipDateValue,
    dob_month: dob_month || null,
    dob_day: dob_day || null,
    dob_year: dob_year || null,
    giftNote,
    giftName,
    giftSenderName,
    giftRecipientEmail,
    specialInstructions,
    isGift: !!(giftNote || giftName || giftSenderName || giftRecipientEmail),
    makeDefaultShipping: false,
    isHoldAtFedex,
    fedexLocationId
  };
};

export const getInitAddressId = ({ loggedIn, AddressBook, address }) => {

  if (!loggedIn) return 'new';

  const {
    address1,
    address2,
    city,
    company,
    name_first,
    name_last,
    phone,
    zip,
    stateId
  } = address || {}

  const { AddressBookID: addressBookIdMatch } = (AddressBook || [])
    .find(({
      Address1,
      Address2,
      City,
      Company,
      FirstName,
      LastName,
      Phone,
      StateID,
      Zip
    }) => {
      return (
        Address1 === address1 &&
        Address2 === address2 &&
        City === city &&
        Company === company &&
        FirstName === name_first &&
        LastName === name_last &&
        StateID === stateId &&
        Zip === zip &&
        Phone === phone
      )
    }) || {};

  return addressBookIdMatch || 'new';

}