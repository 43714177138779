import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../store/reducers/fetch-dux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

const isLocal = process.env.REACT_APP_ENV === 'local'

class SDWLoader extends Component {
  componentDidMount() {
    const {
      validateLogin,
      getCart,
      history
    } = this.props;

    const { location } = history || {};
    const { pathname, search } = location || {};
    const { auth, cart, clubMembershipCart, ...rest } = queryString.parse(search || '');
    const { clubJoin } = rest;
    const isClubJoin = clubJoin === 'true';

    validateLogin();
    getCart({ clubJoin: isClubJoin });

    if (auth || cart || clubMembershipCart) {
      const qs = Object.keys(rest || {}).map(key => `${key}=${rest[key]}`).join('&');
      history.replace({ pathname, search: qs })
    }
  }
  componentWillReceiveProps(nextProps) {
    const { result } = nextProps;
    const { error } = result || {};
    if (error && !isLocal) window.location = window.REDIRECT_URL;
  }
  render() {
    return false;
  }
}

const mapState = state => ({
  result: state.fetchReducer.getCart.toJS()
});

const mapDispatch = dispatch => bindActionCreators({
  validateLogin: fetchDux.validateLogin.createAction,
  getCart: fetchDux.getCart.createAction,
  getCampaignCart: fetchDux.getCampaignCart.createAction,
  getCampaignUser: fetchDux.getCampaignUser.createAction
}, dispatch)

export default connect(mapState, mapDispatch)(withRouter(SDWLoader));