import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDom from 'react-dom';
import mapboxgl from 'mapbox-gl';
import smoothscroll from 'smoothscroll-polyfill';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/function/name';
import 'core-js/features/number/is-integer';
import 'core-js/features/object/entries';
import 'core-js/features/string/includes';
import 'core-js/features/url-search-params';
import 'picturefill';
import 'picturefill/dist/plugins/mutation/pf.mutation.min';
import 'regenerator-runtime/runtime'; // eslint-disable-line import/no-extraneous-dependencies
import App from './App';

require('intersection-observer');

mapboxgl.accessToken = 'pk.eyJ1Ijoic2lsdmVyb2FrIiwiYSI6ImNqemJnNjFkODAwYjEzbnFrMzVzdnoxZ3YifQ.AANgMZQKyG844bPIS6-D_Q';

smoothscroll.polyfill();

console.log('Environment:', process.env.NODE_ENV);

ReactDom.render(<App />, document.getElementById('root'));
