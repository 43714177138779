import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  button: {
    display: 'block',
    width: '100%',
    border: 'none',
    background: theme.colors.primary,
    color: theme.colors.white,
    padding: '2.1rem',
    fontWeight: 500,
    letterSpacing: '0.35px',
    textAlign: 'center',
    '&:hover': {
      '& .btn-inner': {
        transform: 'translateX(-1.7rem)',
        '& figure': {
          opacity: 1
        }
      },
    },
    '&:focus': {
      outline: 0,
    },
    '& .btn-inner': {
      position: 'relative',
      display: 'inline-block',
      transition: 'transform 0.6s var(--ease-out-jim)',
      '& figure': {
        position: 'absolute',
        top: 0,
        right: '-3rem',
        width: '1.7rem',
        height: '0.7rem',
        opacity: 0,
        transition: 'opacity 0.6s var(--ease-out-jim)',
      },
      '& svg': {
        stroke: theme.colors.white
      }
    },
  },
  btnInverse: {
    background: theme.colors.transparent,
    color: theme.colors.primary,
    width: '100%',
    border: 'none',
    padding: '2.1rem',
    fontWeight: 500,
    letterSpacing: '0.35px',
    '& figure': {
      display: 'none'
    },
    '& .btn-inner': {
      
      '& svg': {
        stroke: theme.colors.primary
      }
    },
  },
  underlined: {
    textDecoration: 'underline',
  }
});
