import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import Select from '../../../bits/Shared/Select';
import { joinClasses } from '../../../../utils';
import { getCheckZip } from '../../../../store/reducers/fetchDux/api';

import styles from './styles';


const SearchFormOnMap = ({
  classes,
  findFedexLocation,
  errors,
  states,
  hasAlcohol,
  searchZip,
  onSearchZip,
  onChangeDistance,
  searchDistance
}) => {

  const [zipError, setZipError] = useState("");
  const validateZip = async (zip) => {
    if (!zip) {
      setZipError('required');
      return
    };
    const result = await getCheckZip(zip);
    const { valid, err, places } = result || {};
    if (err) {
      return;
    };
    if (!valid) {
      setZipError('Invalid Zip Code.')
      return
    };
    const { offSiteShipping } = (states || [])
      .find(({ stateName }) => (stateName === (places[0] || {}).state)) || {};

    if (hasAlcohol && !offSiteShipping) {
      setZipError('We are unable to ship to the selected state.')
      return
    }
    return valid
  };
  const handleSearchFedexLocation = async (searchZip, searchDistance) => {
    setZipError("")
    const validZip = await validateZip(searchZip)
    const payload = Object.assign({}, { zip: searchZip, searchDistance })
    if (validZip) {
      findFedexLocation(payload)
    }
  }

  return (
    <div className={classes.searchForm}
    >
      <h5 >
        Find a pick up location near:
      </h5>
      <TextField
        label="ZIP Code"
        className={joinClasses(classes.textField, classes.mapInput)}
        required
        sdwinput
        value={searchZip}
        onChange={(e) => {
          const { value } = e.target || {};
          onSearchZip(value)
        }}
      />
      <Select
        sdwinput
        value={searchDistance}
        required
        className={classes.select}
        onChange={(e) => {
          const { value } = e.target || {};
          onChangeDistance(value)
        }}
      >
        <MenuItem key={'10mi'} value={10} selected>
          Within 10 miles
        </MenuItem>
        <MenuItem key={'25mi'} value={25}>
          Within 25 miles
        </MenuItem>
        <MenuItem key={'50mi'} value={50}>
          Within 50 miles
        </MenuItem>
      </Select>

      <button
        onClick={() => { handleSearchFedexLocation(searchZip, searchDistance) }}
        className={classes.searchButton}
      >
        Search
      </button>
      {zipError && (
        <p style={{ color: 'red' }}>{zipError}</p>
      )}
      {(errors || []).length > 0 && (
        <p style={{ color: 'red' }}>Failed to find Fedex locations for the entered zip.</p>
      )}
    </div>
  );
};

SearchFormOnMap.propTypes = {
  classes: PropTypes.shape({
    textField: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }).isRequired
};


const StyledApp = withStyles(styles)(SearchFormOnMap);
export default StyledApp;
