import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/styles";

import CheckedIcon from '../../../bits/Shared/Icons/Checked';
import UncheckedIcon from '../../../bits/Shared/Icons/Unchecked';
import Select from "../../../bits/Shared/Select";

import { joinClasses, months } from "../../../../utils";
import ccIcons from "../../../../assets/images/cc-icons-full2.png";
import { Field, FieldError, validate } from '../../../../utils/forms';
import styles from "./styles";

const formatCard = value => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  return onlyNums.slice(0, 19).split('').reduce((prev, next, idx) => {
    if (idx === 0) return next;
    if (idx % 4 === 0) return `${prev} ${next}`;
    return `${prev}${next}`
  }, '')
}

const normalizeCard = value => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  return onlyNums.slice(0, 19);
}

const PaymentMethodForm = ({ classes, savePaymentAllowed, className = '', cardInvalid, submitted, isBulk }) => {

  const years = Array.from(Array(21), (_, i) => new Date().getFullYear() + i);

  return (
    <div className={joinClasses(classes.container, className)}>
      <Field
        name="cardNumber"
        validate={[validate.required]}
        normalize={normalizeCard}
        format={formatCard}
      >
        <TextField
          label="Card Number"
          className={joinClasses(classes.textField, classes.cardNumber)}
          required
          sdwinput
        />
        <FieldError sdwerror />
      </Field>
      <div className={classes.rowContainer}>
        <FormControl
          className={joinClasses(
            classes.colContainer,
            classes.triple,
            classes.state, classes.month
          )}
        >
          <Field name="expirationMonth" validate={[validate.requiredNumber]}>
            <InputLabel sdwlabel required>Month</InputLabel>
            <Select sdwinput autoposition>
              {months.map((item, idx) => (
                <MenuItem key={item} value={idx + 1}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <FieldError sdwerror />
          </Field>
        </FormControl>
        <FormControl className={joinClasses(classes.colContainer, classes.triple, classes.year)}>
          <Field name="expirationYear" validate={[validate.required]}>
            <InputLabel sdwlabel required>Year</InputLabel>
            <Select id="age-year" sdwinput autoposition>
              {
                (years || []).map(iem => (
                  <MenuItem key={iem} value={iem}>
                    {iem}
                  </MenuItem>
                ))
              }
            </Select>
            <FieldError sdwerror />
          </Field>
        </FormControl>
        <Field name="cvv" validate={[validate.required]}>
          <TextField
            label="Security Code"
            className={joinClasses(classes.cvv, classes.triple)}
            required
            sdwinput
          />
        </Field>
      </div>
      <div className={classes.icons}>
        <img
          src={ccIcons}
          alt="Accepted card companies"
          className={classes.ccIcons}
        />
      </div>
      {cardInvalid && submitted && (
        <p style={{ color: '#dc3545' }}>Missing required information. Please check form and resubmit.</p>
      )}
      {savePaymentAllowed && !isBulk && (
        <FormControl
          className={joinClasses(classes.textField, classes.saveAddress)}
        >
          <FormControlLabel
            control={(
              <Field name="saveCard" checkbox>
                <Checkbox
                  color="secondary"
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                />
              </Field>
            )}
            label="Save payment method"
            labelPlacement="end"
          />
        </FormControl>
      )}
    </div>
  );
};

PaymentMethodForm.propTypes = {
  classes: PropTypes.shape({
    heading: PropTypes.string.isRequired
  }).isRequired
};

const StyledApp = withStyles(styles)(PaymentMethodForm);

export default StyledApp;
