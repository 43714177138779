import { createStyles } from '@material-ui/core';

import { grid, vw } from '../../../../utils/mixins';

export default theme =>
  createStyles({
    showMobile: {
      display: 'none!important',
      [theme.breakpoints.down('sm')]: {
        display: 'block!important'
      }
    },
    hideMobile: {
      display: 'block!important',
      [theme.breakpoints.down('sm')]: {
        display: 'none!important',
      }
    },
    heading: {
      marginBottom: vw(30)
    },
    firstName: {
      width: grid(2, true),
      paddingRight: '2rem',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        width: '100%',
      }
    },
    lastNameContainer: {
      display: 'inline-block',
      width: grid(2),
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },
    lastName: {
      width: '100%'
    },
    companyName: {
      width: grid(4),
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },
    addressContainer: {
      width: grid(4),
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },
    addressLine1: {
      width: '100%'
    },
    addressLine2: {
      width: grid(4),
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },
    city: {
      paddingRight: '2rem',
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        width: '100%',
      }
    },
    zip: {
      paddingRight: '2rem',
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        width: '100%',
      }
    },
    phoneNumber: {
      width: '50%',
      [theme.breakpoints.up('sm')]: {
        // marginLeft: '2rem' //todo check checkout/shipping form
      }
    },
    state: {
      width: '50%',
      paddingRight: '2rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },
    makeDefault: {},
    toggle: {
      width: grid(1),
      display: 'flex',
      position: 'absolute',
      overflow: 'visible',
      top: '50%',
      left: '100%',
      whiteSpace: 'nowrap',
      marginTop: '-0.5em',
      marginRight: 0,
      marginLeft: '2rem',
      fontSize: '1rem',
      paddingLeft: '2.1rem',
      lineHeight: 2,
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
        marginBottom: '2rem',
        position: 'relative',
        margin: `0 ${grid(1)}`,
        height: 'initial',
        width: '100%',
        top: 0,
        left: 0,
      },
      '& .MuiButtonBase-root': {
        position: 'absolute',
        top: '100%',
        right: '100%',
        width: 0,
        height: 0,
        padding: 0,
        overflow: 'hidden'
      },

      '& .MuiTypography-body1': {
        lineHeight: 2,

        '&:before, &:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: '50%',
          left: '0.8rem',
          width: '0.8rem',
          height: '0.1rem',
          marginLeft: '-0.4rem',
          marginTop: '-0.05rem',
          backgroundColor: '#393834'
        },

        '&:after': {
          transform: 'rotate(-90deg)'
        }
      },

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: '0',
        width: '1.6rem',
        height: '1.6rem',
        borderRadius: '50%',
        border: '0.1rem solid #393834',
        marginTop: '-0.8rem'
      }
    },
    toggleActive: {
      width: grid(1),
      display: 'flex',
      position: 'absolute',
      overflow: 'visible',
      top: '50%',
      left: '100%',
      whiteSpace: 'nowrap',
      marginTop: '-0.5em',
      marginRight: 0,
      marginLeft: '2rem',
      fontSize: '1rem',
      paddingLeft: '2.1rem',
      lineHeight: 2,
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
        marginBottom: '2rem',
        position: 'relative',
        margin: `0 ${grid(1)}`,
        height: 'initial',
        width: '100%',
        top: 0,
        left: 0,
      },
      '& .MuiButtonBase-root': {
        position: 'absolute',
        top: '100%',
        right: '100%',
        width: 0,
        height: 0,
        padding: 0,
        overflow: 'hidden'
      },

      '& .MuiTypography-body1': {
        lineHeight: 2,

        '&:before, &:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: '50%',
          left: '0.8rem',
          width: '0.8rem',
          height: '0.1rem',
          marginLeft: '-0.4rem',
          marginTop: '-0.05rem',
          backgroundColor: '#393834'
        },

        '&:after': {
          transform: 'rotate(0deg)'
        },

      },

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: '0',
        width: '1.6rem',
        height: '1.6rem',
        borderRadius: '50%',
        border: '0.1rem solid #393834',
        marginTop: '-0.8rem'
      }
    },
    isActive: {
      display: 'inline-flex'
    },
    textField: {
      marginBottom: '4rem',
    },
    mobilePlusContainer: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',

    },
    button: {
      width: '30%',
      margin: '2rem',
      padding: '1rem 2rem'
    }
  });
