import { all, fork } from 'redux-saga/effects';

import sampleSagas from './watchers/sampleSagas';
import { watchers } from '../reducers/fetchDux/helpers';

export default function* root() {
  yield all([
    fork(sampleSagas),
    ...watchers
  ]);
}
