import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import Button from '../../../bits/Shared/Button';
import Select from '../../../bits/Shared/Select';
import { joinClasses } from '../../../../utils';
import { getCheckZip } from '../../../../store/reducers/fetchDux/api';

import styles from './styles';


const LocationSearchForm = ({
  classes,
  className,
  findFedexLocation,
  findFedexLocationResult,
  errors,
  states,
  hasAlcohol,
  onSearchZip,
  onChangeDistance
}) => {

  const [zip, setZip] = useState(null);
  const [searchDistance, setSearchDistance] = useState(10);
  const [zipError, setZipError] = useState("");
  const validateZip = async (zip) => {
    if (!zip) {
      setZipError('required');
      return
    };
    const result = await getCheckZip(zip);

    const { valid, err, places } = result || {};
    if (err) {
      return;
    };
    if (!valid) {
      setZipError('Invalid Zip Code.')
      return
    };
    const { offSiteShipping } = (states || [])
      .find(({ stateName }) => (stateName === (places[0] || {}).state)) || {};
    if (hasAlcohol && !offSiteShipping) {
      setZipError('We are unable to ship to the selected state.')
      return
    }
    onSearchZip(zip)
    return valid
  };
  const handleSearchFedexLocation = async (zip, searchDistance) => {
    setZipError("")
    const validZip = await validateZip(zip)
    const payload = Object.assign({}, { zip, searchDistance })
    if (validZip) {
      findFedexLocation(payload)

    }
  }

  return (
    <div className={classes.fedexSearchForm}>
      <p className={classes.disclaimer}>
        Don' worry about being home to sign! Many locations are open nights and weekends.
      </p>
      <p >
        Find Local Pickup Locations near:
      </p>
      <TextField
        label="ZIP Code"
        className={joinClasses(classes.textField, classes.zip)}
        required
        sdwinput
        value={zip}
        onChange={(e) => {
          const { value } = e.target || {};
          setZip(value)
        }}
      />
      <Select
        sdwinput
        value={searchDistance}
        required
        className={classes.select}
        onChange={(e) => {
          const { value } = e.target || {};
          setSearchDistance(value)
          onChangeDistance(value)
        }}
      >
        <MenuItem key={'10mi'} value={10} selected>
          Within 10 miles
        </MenuItem>
        <MenuItem key={'25mi'} value={25}>
          Within 25 miles
        </MenuItem>
        <MenuItem key={'50mi'} value={50}>
          Within 50 miles
        </MenuItem>
      </Select>
      {zipError && (
        <p style={{ color: 'red' }}>{zipError}</p>
      )}
      {(errors || []).length > 0 && (
        <p style={{ color: 'red' }}>Failed to find a location, please try again or try with a different zip code.</p>
      )}
      <Button
        className={classes.button2}
        title='Search'
        onClick={() => { handleSearchFedexLocation(zip, searchDistance) }}
      />
    </div>
  );
};

LocationSearchForm.propTypes = {
  classes: PropTypes.shape({
    textField: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }).isRequired
};


const StyledApp = withStyles(styles)(LocationSearchForm);
export default StyledApp;
