import { createStyles } from '@material-ui/core';

import { grid, vw } from '../../../../utils/mixins';

export default (theme) => createStyles({
	container: {
		position: 'relative',
		padding: `${vw(80)} ${vw(100)}`,
		backgroundColor: theme.colors.background.zero,
		width: grid(8, false),
		margin: 'auto'
	},
	heading: {
		marginBottom: vw(50)
	},
	close: {
		display: 'block',
		position: 'absolute',
		top: vw(80),
		right: vw(100),
		width: '3.8rem',
		height: '3.8rem',
		marginTop: '-0.6rem',
	},
	advise: {
		color: theme.colors.tertiary,
		marginBottom: vw(40),
	},
	address: {
		margin: `${vw(5)} 0 ${vw(10)} ${vw(10)}`,
		'& span': {
			width: '100%',
			display: 'inline-block',
			"&:first-child": {
				fontWeight: 'bold',
				marginTop: 0,
				paddingTop: 0,
			}
		},
	}
});