import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  button: {
    display: 'block',
    width: '100%',
    border: 'none',
    background: theme.colors.primary,
    color: theme.colors.white,
    padding: '2.1rem',
    fontWeight: 500,
    letterSpacing: '0.35px',
    textAlign: 'center',
  },
  btnInverse: {
    background: theme.colors.transparent,
    color: theme.colors.primary,
    width: '100%',
    border: 'none',
    padding: '2.1rem',
    fontWeight: 500,
    letterSpacing: '0.35px'
  }
});
