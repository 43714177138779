import { createStyles } from '@material-ui/core';
import { vw, grid } from '../../../../utils/mixins';

export default theme =>
  createStyles({
    container: {
      marginBottom: '3rem',
		color: '#737373',

		[theme.breakpoints.up('md')]: {
			marginBottom: vw(70),
		},

		'& h2, & h3, & h4, & h5, & h6': {
			color: '#000',
			marginBottom: '3rem'
		},

		'& ol': {
			listStyleType: 'decimal',
			paddingLeft: '2rem'
		},

		'& ul': {
			listStyle: 'disc',
			paddingLeft: '1em'
		},

		'& p': {
			marginBottom: '1.3888888889em'
		},

		'& ol, & ul': {
			marginBottom: '2.5625em'
		},

		'& li': {
			// marginBottom: '2.5em',
			// paddingLeft: '2rem'
			lineHeight: '2.125em'
		},

		'& blockquote': {
			borderTop: '0.1rem solid #000',
			borderBottom: '0.1rem solid #000',
			paddingTop: '5.5rem',
			paddingBottom: '4.5rem',
			textAlign: 'center',
			marginTop: vw(70),
			marginBottom: vw(70),

			[theme.breakpoints.up('sm')]: {
				paddingLeft: grid(1, true),
				paddingRight: grid(1, true),
			},
			[theme.breakpoints.up('md')]: {
				paddingTop: vw(70),
				paddingBottom: vw(60),
			},

			'& p:first-child': {
				marginBottom: '1.5rem',
				color: '#000',

				[theme.breakpoints.down('sm')]: {
					fontSize: '2rem',
					lineHeight: 1.4,
				},
				[theme.breakpoints.up('md')]: {
					marginBottom: '2.5rem',
				}
			},

			'& > cite, & p:last-child': {
				fontSize: '0.8em'
			}
		},

		'& strong, & a': {
			color: '#202124'
		},

		'& a': {
			textDecoration: 'underline'
		},

		'& img': {
			height: 'auto'
		},

		'& > :last-child': {
			marginBottom: 0
		},

		'& > :first-child': {
			marginTop: 0
		}
    },
  });
