import React, { Component } from 'react';
import { joinClasses } from "../../../../utils";

import SilveroakLink from '../../../../utils/SilveroakLink';
import TwomeyLink from '../../../../utils/TwomeyLink';


const safeLoad = () => {
  try {
    const stor = sessionStorage.getItem('soldOutNotified')
    return JSON.parse(stor) || [];
  } catch (err) {
    return [];
  }
}

class SoldOutItem extends Component {
  constructor(props) {
    super(props);
    this.state = { soldOutNotified: [] }
  }
  componentDidMount() {
    try {
      const payload = sessionStorage.getItem('soldOutNotified')
      const soldOutNotified = JSON.parse(payload) || [];
      this.setState({ soldOutNotified });
    } catch (err) {
      //noop
    }
  }
  componentWillUnmount() {
    try {
      const stor = safeLoad();
      const { soldOutItems } = this.props;
      const { soldOutNotified } = this.state;
      
      const ids = (soldOutItems || []).map(({ shoppingCartItemId }) => shoppingCartItemId);

      const payload = [...(soldOutNotified || []), ...(ids || []), ...(stor || [])].reduce((prev, next) => {
        if (prev.some(p => p === next)) return prev;
        return [...prev, next];
      }, [])
      sessionStorage.setItem('soldOutNotified', JSON.stringify(payload));
    } catch (err) {
      //noop
    }
  }
  render() {
    const {
      classes,
      soldOutItems
    } = this.props;

    const { soldOutNotified } = this.state;

    const items = (soldOutItems || []).filter(({ shoppingCartItemId }) => !soldOutNotified.some(s => s === shoppingCartItemId));

    if (!items.length) return false;
    return (items || []).map((item, index) => {

      const {
        brandingId,
        skuDescription,
        productHref
      } = item || {}
  
      return (
        <tr className={joinClasses(classes.itemContainer, classes.withBorder)} key={index}>
          <td className={classes.productTitle}>
            <p style={{ textAlign:'left' }}>
              {brandingId === 1 && (
                <SilveroakLink href={productHref}>
                  <a>{skuDescription}</a>
                </SilveroakLink>
              )}
              {brandingId === 2 && (
                <TwomeyLink href={productHref}>
                  <a>{skuDescription}</a>
                </TwomeyLink>
              )}
            </p>
            <div className={classes.productPrice} colSpan="2" style={{ fontWeight: '500', textAlign:'left' }}> UNAVAILABLE </div>
          </td>
        </tr>
      )
    });
  }
}

export default SoldOutItem;