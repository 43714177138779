import { createStyles } from "@material-ui/core";
import { grid, vw } from "../../../../utils/mixins";

export default theme =>
  createStyles({
    subContainer: {
      padding: '0 var(--grid-padding)',
      display: 'flex'
    },
    dark: {
      backgroundColor: theme.colors.background.primary,
    },
    details: {
      marginBottom: '5rem',
      marginTop: '60px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: vw(70)
      }
    },
    detailsHeading: {
      marginBottom: '3rem',
      [theme.breakpoints.up('sm')]: {
        marginBottom: vw(50)
      }
    },
    detailsBodyText: {
      maxWidth: '32.3em'
    },
    detailsButton: {
      display: 'inline',
      width: '200px',
      marginTop: '20px',
      marginRight: '20px',
    },
    subHeading: {
      marginBottom: '2.5rem',
      [theme.breakpoints.up('sm')]: {
        marginBottom: vw(35)
      }
    },
    summary: {

    },
    summaryHeader: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      paddingBottom: '2.5rem',
      borderBottom: '0.1rem solid #D7D7D7',

      [theme.breakpoints.up('sm')]: {
        paddingBottom: '4rem',
      }
    },
    summaryHeaderOrderNumber: {
      '& .eyebrow': {
        color: '#8A8A8A'
      }
    },
    summaryHeaderDownloadCTA: {
      '& button': {
        textDecoration: 'underline'
      }
    },
    item: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: '1.5rem 0',
      borderBottom: '0.1rem solid #D7D7D7',

      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        padding: '2.5rem 0',
        minHeight: '13.5rem'
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: grid(1, true),
      }
    },
    itemImageContainer: {
      marginRight: '4.5rem',
      marginLeft: '2rem',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '11.5rem',
        marginLeft: 0
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },

      '& img': {
        display: 'block',
      }
    },
    itemDescription: {
      paddingRight: '2rem',

      [theme.breakpoints.up('sm')]: {
        maxWidth: '38.79310345%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },

      '& p:first-child': {
        marginBottom: '1.5rem',
        maxWidth: '15em'
      }
    },
    itemPrice:{
      marginLeft: 'auto',
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: '2.5rem',
        right: 0,
        fontWeight: 500
      }
    },
    itemPriceDiscount: {
      color: 'red'
    },
    total: {
      '& dl': {
        padding: '0.5rem 0',
        [theme.breakpoints.up('sm')]: {
          padding: '1.5rem 0'
        }
      },

      '& dl > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1.5rem 0',

        [theme.breakpoints.up('md')]: {
          maxWidth: grid(4),
          marginLeft: 'auto',
          marginRight: grid(1, true)
        }
      },

      '& dl:last-child': {
        fontWeight: 700,
        borderTop: '0.1rem solid #646464',
      }
    },
    helpText: {
      backgroundColor: theme.colors.background.secondary,
      padding: '4rem 0',
      marginTop: 'var(--spacer-module-small)',
      [theme.breakpoints.up('md')]: {
        padding: '5.5rem 0'
      }
    },
    helpTextBody: {
      marginBottom: 0,

      '& a': {
        textDecoration: 'underline'
      }
    }
  });
