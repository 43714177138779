import { createStyles } from '@material-ui/core';
import { vw, grid } from '../../../../utils/mixins';

export default theme =>
  createStyles({
    container: {
      marginBottom: '3rem',
      [theme.breakpoints.up("sm")]: {
        marginBottom: '5rem',
      }
		},
		descriptionList: {
      marginBottom: vw(30),
    },
    listTitle: {
      color: theme.colors.tertiary,
      fontSize: '1.2rem',
      fontWeight: 500,
      letterSpacing: '0.125em',
      lineHeight: '1.166666666666667',
      marginBottom: vw(25),
      textTransform: 'uppercase',
      [theme.breakpoints.down("sm")]: {
        marginBottom: '2rem',
      },
    },
    dlItem: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    dl: {
      marginTop: vw(25),
      '& dt, & dd': {
        flex: '0 1 auto',
        fontSize: '1.6rem',
        lineHeight: '1.75',
        // whiteSpace: 'nowrap',
        '& a': {
          textDecoration: 'underline',
        },
      },
      '& dt': {
        color: theme.colors.dark,
      },
      '& dd': {
        color: theme.colors.gray64,
        textAlign: 'right',
      },
      [theme.breakpoints.down("md")]: {
        '& dt, & dd': {
          flex: '1 0 100%',
          fontSize: '1.4rem',
          lineHeight: '1.714',
        },
        '& dt': {
        },
        '& dd': {
          marginBottom: '1.5rem',
          textAlign: 'left',
        },
      },
    },
    lastChild: {
      marginBottom: '0',
    },
  });
