import React, { useState } from "react";
import ReactModal from "react-modal";
import { withStyles } from "@material-ui/styles";
import close from "../../../../assets/vectors/Close.svg";
import { joinClasses } from "../../../../utils";
import styles from "./styles";
import LocationMap from "./LocationMap";
import Button from '../../../bits/Shared/Button';
import SearchFormOnMap from "./SearchFormOnMap"
ReactModal.setAppElement(document.getElementById("root"));

const FedexLocationsModal = ({
  classes,
  isOpen,
  onClose,
  inProgress,
  findFedexLocationResult,
  onSelectLocation,
  searchZip,
  onSearchZip,
  findFedexLocation,
  searchDistance,
  onChangeDistance,
  hasAlcohol,
  states
}) => {
const filteredLocation = (findFedexLocationResult || []).filter(store => {
  const stateCode = store.contactAndAddress.address.stateOrProvinceCode;
  return (states || []).some(state => state.stateShort === stateCode && state.offSiteShipping);
});

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [longLat, setLongLat] = useState([]);
  const [clickMarkerShowPopup, setClickMarkerShowPopup] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [clickStoreShowPopup, setClickStoreShowPopup] = useState(false);

  const closeModal = e => {
    onClose();
  };

  const selectedStore = document.getElementsByClassName(classes.active);
  const onClickMarker = (locationId) => {
    setClickStoreShowPopup(false)
    setClickMarkerShowPopup(true);
    setSelectedLocationId(locationId);
    (selectedStore[0]).scrollIntoViewIfNeeded()
  };

  const calculateStoreHours = (storeHours) => {
    const listHours = storeHours.reduce((r, { dayOfWeek, operationalHours }) => {
      const { begins, ends } = operationalHours || {};
      const days = (dayOfWeek || '').slice(0, 3);
      const open = (begins || '').slice(0, -3);
      const close = (ends || '').slice(0, -3);

      if (!r.length || r[r.length - 1].open !== open || r[r.length - 1].close !== close)
        r.push({ days, open, close });
      else
        r[r.length - 1].days = r[r.length - 1].days.split('-').slice(0, 1).concat(days).join('-');
      return r;
    }, []);
    return listHours
  }


  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={classes.container}
      overlayClassName="react-modal"
    >

      <SearchFormOnMap
        searchZip={searchZip}
        onSearchZip={onSearchZip}
        findFedexLocation={findFedexLocation}
        searchDistance={searchDistance}
        onChangeDistance={onChangeDistance}
        hasAlcohol={hasAlcohol}
        states={states}
      />
      <div className={classes.locationContainer}>
        <div className={classes.sidebar}>
          <div className={classes.listings}>
            {(filteredLocation || []).map((location) => {
              const {
                contactAndAddress,
                storeHours,
                locationId,
                geoPositionalCoordinates
              } = location || {};
              const { address, contact } = contactAndAddress || {};
              const { city, streetLines, postalCode, stateOrProvinceCode } = address || {};
              const { companyName } = contact || {};
              const { longitude, latitude } = geoPositionalCoordinates || {};
              const listHours = calculateStoreHours(storeHours);
              return (
                <div className={joinClasses(
                  classes.item,
                  selectedMarker === locationId ? classes.active : ''
                )}
                  onClick={() => {
                    setClickMarkerShowPopup(false)
                    setClickStoreShowPopup(true)
                    setSelectedMarker(locationId)
                    setLongLat([longitude, latitude])
                  }}
                >
                  <div><strong>{companyName}</strong></div>
                  <div className={classes.itemAddress}>{streetLines.join(', ')}</div>
                  <div className={classes.itemAddress}>{`${city}, ${stateOrProvinceCode} ${postalCode}`}</div>
                  {(listHours || []).length &&
                    <div className={classes.storeOpeningHours}>
                      {(listHours || []).map((i) => {
                        const { days, open, close } = i || {};
                        return (
                          open ?
                            <div className={classes.storeHours}>
                              <strong>{days}:</strong>  <span>{open} - {close}</span>
                            </div>
                            :
                            <div className={classes.storeHours}>
                              <strong>{days}:</strong> <span>closed</span>
                            </div>
                        )
                      })}
                    </div>
                  }
                  <Button
                    className={classes.button}
                    title='Ship to this location'
                    onClick={() => {
                      const payload = Object.assign({}, { address }, { contact }, { storeHours }, {locationId});
                      onSelectLocation(payload)
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <div className={classes.map}>
          <LocationMap
            findFedexLocationResult={filteredLocation}
            onClickMarker={onClickMarker}
            classes={classes}
            longLat={longLat}
            selectedMarker={selectedMarker}
            setSelectedMarker={setSelectedMarker}
            clickMarkerShowPopup={clickMarkerShowPopup}
            clickStoreShowPopup={clickStoreShowPopup}
            setClickMarkerShowPopup={setClickMarkerShowPopup}
            setLongLat={setLongLat}
            selectedLocationId={selectedLocationId}
            setSelectedLocationId={setSelectedLocationId}
          />
        </div>
      </div>
      <button type="button" onClick={closeModal} className={classes.close} disabled={inProgress}>
        <img src={close} alt="Close overlay" />
      </button>
    </ReactModal>
  );
};

export default withStyles(styles)(FedexLocationsModal);
