import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import numeral from 'numeral';
import Button from "../../../bits/Shared/Button";
import SilveroakLink from '../../../../utils/SilveroakLink'
import TwomeyLink from '../../../../utils/TwomeyLink'
import Hero from '../../Heroes/OrderConfirmation';
import { joinClasses } from '../../../../utils';
import fetchDux from '../../../../store/reducers/fetch-dux';
import { Wysiwyg, SEO } from '../../../bits';
import DataController from "../../../../utils/data-controller";
import styles from './styles';


// const formatMoney = p => numeral(p || 0).format('$0,0.00');

const url = '/wp-json/acf/v2/options/';

class BulkConfirmation extends Component {
    constructor() {
        super();

        this.state = {
            data: {}
        };
    }
    componentDidMount() {
        const fetchData = async () => {
            const res = await DataController.load(url);
            this.setState({
                data: res
            });
        };
        fetchData();
    }
    render() {
        const { bulkPaymentResult, classes, email } = this.props;
        // const totalForAllOrders = (bulkPaymentResult || [])
        //     .reduce((prev, { total }) => prev + total, 0);
        return (
            <>
                <SEO
                    title='Order Confirmation'
                    meta={null}
                />

                <div className={classes.dark}>
                    <div className={classes.subContainer}>
                        <Hero
                            image={this.state.data.acf?.order_confirm_hero_image}
                            title={this.state.data.acf?.order_confirm_heading}
                            email={email}
                            customerPayLink
                        />
                    </div>
                </div>


                <div className={classes.details}>
                    <div className="container">
                        <p className={joinClasses('h4', classes.detailsHeading)}>What's Next?</p>

                        <div className={joinClasses('copy--large', classes.detailsBodyText)}>
                            <p>Orders are processed within 24-48 hours of receipt. To ensure your wine’s integrity during extreme temperature, we may hold your shipment.</p>
                        </div>
                        <SilveroakLink>
                            <Button className={classes.detailsButton} title="Shop Silver Oak" />
                        </SilveroakLink>
                        <TwomeyLink>
                            <Button className={classes.detailsButton} title="Shop Twomey" />
                        </TwomeyLink>
                    </div>
                </div>

                {this.state.data.acf?.order_confirm_help_text && (
                    <div className={classes.helpText} style={{ width: '100%', margin: 0 }}>
                        <div className="container">
                            <Wysiwyg
                                className={joinClasses(classes.helpTextBody, 'copy--small')}
                                content={this.state.data.acf?.order_confirm_help_text}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapState = state => {
    const {
        result: order
    } = state.fetchReducer.postCompleteOrder.toJS();
    return {
        order
    }
}

const mapDispatch = dispatch => bindActionCreators({
    clearPostCompleteOrder: fetchDux.postCompleteOrder.clearAction,
    clearGetCart: fetchDux.getCart.clearAction
}, dispatch);

const StyledApp = withStyles(styles)(BulkConfirmation);

export default connect(mapState, mapDispatch)(StyledApp);


