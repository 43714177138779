import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ConnectedRouter } from 'connected-react-router';
import store from './store';
import { Provider } from 'react-redux';
import { throttle } from 'lodash';
import { detect } from 'detect-browser';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import CookieNotificationModal from './components/modules/Modals/CookieNotification';
import AnimationController from './utils/animation-controller';
import { history } from './utils/history';
import theme from './utils/theme';
import 'mapbox-gl/dist/mapbox-gl.css';

// Screens
import Checkout from './screens/Checkout';
import BulkPayment from './components/modules/Checkout/BulkPayment';

import './assets/scss/main.scss';

const App = () => {

  // useBeforeFirstRender(() => {
  //   useDataLayer({ 'prerenderReady': false })
  // })

  const browser = detect();
  let isIe = false;

  if (browser.name === 'ie') {
    isIe = true;
    document.documentElement.classList.add('is-ie');
  }

  const onFirstTouch = () => {
    const touchEvent = new Event('usertouch');
    document.body.dispatchEvent(touchEvent);
    document.body.classList.add('is-user-touch');
    window.removeEventListener('touchstart', onFirstTouch, false);
  }

  let scrollPosition = !isIe ? window.scrollY : window.pageYOffset;

  const handleScroll = () => {
    const { body } = document;
    const currentScroll = !isIe ? window.scrollY : window.pageYOffset;
    const previousScroll = scrollPosition;

    if (currentScroll <= 1) {
      body.classList.remove('is-scrolling-up');
      body.classList.remove('is-scrolling-down');
      body.classList.remove('is-scrolled');
      body.classList.remove('is-scrolled-more');
    } else {
      body.classList.add('is-scrolled');

      if (currentScroll > 150) {
        body.classList.add('is-scrolled-more');
      } else {
        body.classList.remove('is-scrolled-more');
      }

      if (currentScroll > previousScroll) {
        body.classList.remove('is-scrolling-up');
        body.classList.add('is-scrolling-down');
      } else if (currentScroll < previousScroll) {
        body.classList.add('is-scrolling-up');
        body.classList.remove('is-scrolling-down');
      } else {
        body.classList.remove('is-scrolling-up');
        body.classList.remove('is-scrolling-down');
      }
    }

    scrollPosition = currentScroll;
  };

  const throttledScroll = throttle(handleScroll, 20);

  // componentDidMount equivalent
  useEffect(() => {
    AnimationController.start();

    // Detect actual touch from user device
    window.addEventListener('touchstart', onFirstTouch, false);

    // Scroll handler
    window.addEventListener('scroll', throttledScroll);

    // PreRender
    // useDataLayer({ 'prerenderReady': true });

    return (() => {
      window.removeEventListener('scroll', throttledScroll);
      window.removeEventListener('touchstart', onFirstTouch, false);
    });
  }, []);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={createMuiTheme(theme)}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <LastLocationProvider>
              <Switch>
                <Route exact path="/bulk-payment" component={BulkPayment} />
                <Route path="/" component={Checkout} />
                <Redirect to="/shipping/" />
              </Switch>
            </LastLocationProvider>
            <CookieNotificationModal />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  );

}

export default App;
