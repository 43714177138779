const gridWidth = 1440;

export const grid = (cols, trailing = false) => {
  let trail = "0px";

  if (!trailing) {
    trail = "var(--grid-gutter)";
  }

  return `calc((var(--grid-width) + var(--grid-gutter) - var(--grid-padding)*2)/var(--grid-column-count)*${cols} - ${trail})`;
};

export const vw = (amt, context = gridWidth) => `${(amt / context) * 100}vw`;

export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};